import React from 'react';
import Error_Icon from '../../../images/featured-icon.svg';
import './CancelModal.scss';

interface CancelModalProps {
  isCancelModal: boolean;
  onClose: () => void;
  onCancel: (cancelId: string | number) => void;
  cancelId?: string | number;
}

const CancelModal: React.FC<CancelModalProps> = ({ isCancelModal, onClose, onCancel, cancelId }) => {
  return (
    <>
      {isCancelModal && (
        <div className='cancel-modal__background'>
          <div className='cancel-modal__container'>
            <div className='cancel-modal__header'>
              <img src={Error_Icon} alt='' />
            </div>
            <div className='cancel-modal__body'>
              <p className='cancel-modal__title'>Cancel scheduled speed change</p>
              <p className='cancel-modal__content' style={{color: "#b8c5cc"}}>
              Are you sure you want to cancel this scheduled speed change? This action cannot be undone.
              </p>
            </div>
            <div className='cancel-modal__separator'></div>
            <div className='cancel-modal__footer'>
              <button onClick={onClose} className='btn btn-secondary'>
                No
              </button>
              <button className='btn btn-dark-orange' type='button' onClick={() => onCancel(cancelId || '')}>
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CancelModal;
