import React from 'react';
import './CardTypeFilter.scss';
import { Menu, Transition } from '@headlessui/react';

interface FilterOptionsProps {
  buttonContent: React.ReactNode;
  distinctCardTypeNames: string[];
}

const CardTypeFilter: React.FC<FilterOptionsProps> = ({ buttonContent, distinctCardTypeNames }) => {
  return (
    <Menu as="div" className="filter-dropdown-container">
      <div>
        <Menu.Button>{buttonContent}</Menu.Button>
      </div>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus-outline-none">
          <div className="option-label-container">
            <p className="option-label">Filter card type</p>
          </div>
          <div className="space-y-2">
            {distinctCardTypeNames.map((cardTypeName) => (
              <div className="filter-options" key={cardTypeName}>
                <label className="filter-checkbox">
                  <input type="checkbox" />
                </label>
                <span style={{ marginLeft: '8px' }}>{cardTypeName}</span>
              </div>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default CardTypeFilter;
