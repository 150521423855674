import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchInitialState } from './GroupStatusService';

interface State {
  loading: boolean;
  groupStatus: GroupStatusList | null | undefined;
  error: string | undefined;
}

interface GroupStatusList {
  Id: string;
  DateCreated: string;
  Values: GroupStatus;
}
interface GroupStatus {
  Columns: Array<GroupStatusColumns>;
  Rows: GroupStatusRow[];
}
interface GroupStatusColumns {
  Id: string;
  Header: string;
  accessor: string;
}
// Define the data row interface
interface GroupStatusRow {
  [key: string]: any;
}

// Async thunk to fetch the initial state data
export const fetchInitialGroupStatusStateAsync = createAsyncThunk('groupStatusSlice/fetchInitialState', async () => {
  const data = await fetchInitialState();
  return data;
});

const initialState: State = {
  loading: false,
  groupStatus: null,
  error: undefined,
};

export const GroupStatus = createSlice({
  name: 'groupStatus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitialGroupStatusStateAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInitialGroupStatusStateAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.groupStatus = action.payload;
      })
      .addCase(fetchInitialGroupStatusStateAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default GroupStatus.reducer;
