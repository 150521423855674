import { Fragment, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import closeIcon from '../../../images/x-close.svg'
import checkCircle from '../../../images/check-circle-green.svg'

import React from 'react';
import { useEffect } from 'react';
import './Toast.scss';
import { AssetState, updateAssetControlActionStatus } from '../../asset/AssetControlSlice';
import { useAppDispatch } from '../../../hooks/storeHooks';
// import { useAppDispatch } from '../../../hooks/storeHooks';
// import { updateAssetControlActionStatus } from '../../asset/AssetControlSlice';


interface ToastProps {
  message: string | undefined,
  isShowEnabled: boolean,
  property: string
}
const SuccessfullToast: React.FC<ToastProps> = ({ message, isShowEnabled, property }) => {
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const prevPropertyRef = useRef<string>('');

  useEffect(() => {
    if (isShowEnabled) {
      setShow(true);
      const timer = setTimeout(() => {
        setShow(false);
        if (prevPropertyRef.current) {
          dispatch(updateAssetControlActionStatus({ propertyName: prevPropertyRef.current as keyof AssetState, value: false }));
        }
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isShowEnabled]);

  useEffect(() => {
    if (property && property !== '') {
      prevPropertyRef.current = property;
    }
  }, [property]);

  const closeToaster = () => {
    setShow(false)
    if (prevPropertyRef.current) {
      dispatch(updateAssetControlActionStatus({ propertyName: prevPropertyRef.current as keyof AssetState, value: false }));
    }
  }
  return (
    <>
      <div
        aria-live='assertive'
        className='z-10 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6'
        style={{ zIndex: 1001 }}
      >
        <div className='flex w-full flex-col items-center space-y-4  toast-top-position'>
          <Transition
            show={show}
            as={Fragment}
            enter='transform ease-out duration-300 transition'
            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 toaster-wrapper '>
              <div className='p-4 h-full'>
                <div className='flex items-start items-center justify-center h-full'>
                  <div className='flex-shrink-0 check-circle'>
                    <img src={checkCircle} alt='' />
                  </div>
                  <div className='ml-3 w-0 flex-1 pt-0.5 text-left text'>
                    <p className='text-md font-medium text-green-900'>{message}</p>
                  </div>
                  <div className='flex-shrink-0' onClick={closeToaster}>
                    <img src={closeIcon} alt='' />
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}

export default SuccessfullToast;