// DeleteModal.tsx
import React from 'react';
import Featured from '../../../images/featured-icon.svg';
import './DeleteModal.scss';

interface DeleteModalProps {
  isDeleteModal: boolean;
  onClose: () => void;
  onDelete: (deletedId: string | number) => void;
  deletedId: string | number;
}

const DeleteModal: React.FC<DeleteModalProps> = ({ isDeleteModal, onClose, onDelete, deletedId }) => {

  return (
    <>
      {isDeleteModal && (
        <div className='delete-modal__background'>
          <div className='delete-modal__container'>
            <div className='delete-modal__header'>
              <img src={Featured} alt='' />
            </div>
            <div className='delete-modal__body'>
              <p className='delete-modal__title'>Delete schedule</p>
              <p className='delete-modal__content'>
                Are you sure you want to delete this schedule? This action cannot be undone.
              </p>
            </div>
            <div className='delete-modal__separator'></div>
            <div className='delete-modal__footer'>
              <button onClick={onClose} className='btn btn-secondary'>
                Cancel
              </button>
              <button className='btn btn-dark-orange' type='button' onClick={() => onDelete(deletedId)}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteModal;
