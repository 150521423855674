import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ControllerDetails } from './model/ControllerDetails';
import { GetControllerDetails } from './AssetDetailsHeaderService';


interface UserState {
  loading: boolean;
  controllerDetails: ControllerDetails | null;
  error: string | null;
  wellStatus: boolean | undefined | string;
}

const initialState: UserState = {
  loading: false,
  controllerDetails: null,
  error: null,
  wellStatus: undefined
};

export const fetchControllerDetails = createAsyncThunk('user/getControllerDetails', async ({ userId, nodeId, controllerType }: { userId: string, nodeId: string, controllerType: string }) => {
  const response = await GetControllerDetails(userId, nodeId, controllerType);
  return response;
})

const assetdetailsHeaderSlice = createSlice({
  name: 'assetdetailsHeader',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchControllerDetails.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(fetchControllerDetails.fulfilled, (state, action) => {
      state.controllerDetails = action.payload;
      state.loading = false;
    })
    builder.addCase(fetchControllerDetails.rejected, (state, action) => {
      state.error = action.payload as string;
      state.loading = false;
    })
  }
});

export default assetdetailsHeaderSlice.reducer;