import './NavBar.scss';
import React, { useEffect, useState } from 'react';
import Icon from '../../../images/Icon.png';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { setNotificationButtons, switchActiveButton } from '../UIButtonToolbarSlice';
import UIButtonToolbar from './UIButtonToolbar';
import { fetchInitialNotificationAsync } from '../NotificationsSlice';
import NotificationList from './notifications/NotificationList';
import { NotificationTypeValue, fetchNotificationTypesAsync } from '../NotificationTypesSlice';
import NotificationFilterDropdown from './notifications/notification-filter-dropdown/NotificationFilterDropdown';
import { setCurrentFilterId } from '../NotificationFilterSlice';

interface NotificationButton {
  name: string;
  active: boolean;
  id: number;
}

const notificationButtons: NotificationButton[] = [
  {
    name: 'All',
    active: true,
    id: 0,
  },
  {
    name: 'Host',
    active: false,
    id: 5,
  },
  {
    name: 'RTU',
    active: false,
    id: 4,
  },
  {
    name: 'Comments',
    active: false,
    id: 1,
  },
];

const NotificationDrawerItem = () => {
  const notificationButtonsFromRedux = useAppSelector((state) => state.notificationButtons.notificationButtons);
  const notificationTypeData = useAppSelector((state) => state.notificationType);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eventTypeIds, setEventTypeIds] = useState<number[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (notificationButtonsFromRedux.length === 0) {
      dispatch(setNotificationButtons(notificationButtons));
    }
    dispatch(fetchNotificationTypesAsync());
    dispatch(fetchInitialNotificationAsync());
  }, []);

  function buttonClicked(id: number) {
    dispatch(switchActiveButton({ id: id, buttonType: 'notification' }));
    dispatch(setCurrentFilterId(id === 0 ? null : id));
  }

  function filterClicked() {
    setIsModalOpen((prev) => !prev);
  }

  const handleEventTypeIdsChange = (updatedEventTypeIds: number[]) => {
    setEventTypeIds(updatedEventTypeIds);
  };

  return (
    <div className='navigation-bar-items'>
      <div className='navigation-bar-items-content'>
        <div className='navigation-content-headline'>Notifications</div>
        <div className='navigation-content-search-and-filter'>
          <div className='navigation-content-search'>
            <div className='notification-content-button-filter'>
              <UIButtonToolbar uiButtons={notificationButtonsFromRedux} buttonClicked={buttonClicked} />
            </div>
            <div className='notification-content-filter'>
              <div className='filter-icon-container'>
                <button
                  data-testid='notification-filter-icon-button'
                  className='navigation-content-search-btn'
                  onClick={filterClicked}
                >
                  <img className='filter-icon' src={Icon} />
                </button>
              </div>
              {isModalOpen && (
                <NotificationFilterDropdown
                  options={notificationTypeData.Values.map(
                    (notificationType: NotificationTypeValue) => ({
                      id: notificationType.Id,
                      name: notificationType.Name,
                    }),
                    [],
                  ).filter((option) => eventTypeIds.includes(option.id))}
                  onOptionChange={(selectedId) => buttonClicked(selectedId === null ? 0 : selectedId)}
                />
              )}
            </div>
          </div>
        </div>
        <NotificationList onEventTypeIdsChange={handleEventTypeIdsChange} />
      </div>
    </div>
  );
};

export default NotificationDrawerItem;
