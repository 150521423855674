import { useEffect } from 'react';
import Close from '../../../../../../images/x-close.svg';
import './alarmSubscriptionModal.scss';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/storeHooks';
import { fetchUserById } from '../../../../../user/UserSlice';
import { AlarmSubscriptionModalProps } from '../../AssetDetailProps';
import PageLoader from '../../../../../common/page-loader/PageLoader';
import { capitalizeFirstLetter } from '../../../../../common/DateRangeSelector/DefaultRanges';

const AlarmSubscriptionModal: React.FC<AlarmSubscriptionModalProps> = ({ onClose, isModalOpen, selectedId }) => {
  const dispatch = useAppDispatch();
  // const [selectedUserDetails, setSelectedUserDetails] = useState<UserDetailProps | null>(null);
  const selectedUserDetails = useAppSelector((state) => state.user.currentUser);

  const { loading } = useAppSelector((state) => state?.user);
  useEffect(() => {
    if (selectedId) {
      dispatch(fetchUserById(selectedId))
    }
  }, [selectedId, dispatch]);

  return (
    <>
      {loading && <PageLoader />}
      {isModalOpen && (
        <div className='modal-overlay'>
          <div className='modal' style={{ width: '900px' }}>
            <div className='flex flex-row flex-grow profile-block' style={{ marginTop: '0px' }}>
              <div className='notify-card'>
                <div className='header' style={{ padding: '20px 24px 20px 24px' }}>
                  <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <div className='icon-text'>
                      {`${selectedUserDetails ? `${selectedUserDetails?.firstName[0]?.toUpperCase()}${selectedUserDetails?.lastName[0]?.toUpperCase()}` : ''}`}
                    </div>
                    <div>
                      <h1 className='alarm-heading'>{`${selectedUserDetails ? `${capitalizeFirstLetter(selectedUserDetails?.firstName)} ${capitalizeFirstLetter(selectedUserDetails?.lastName)}` : ''}`}</h1>
                      <p className='alarm-des'>{`${selectedUserDetails ? `${selectedUserDetails?.jobTitle} @${selectedUserDetails?.company}` : ''}`}</p>
                    </div>
                  </div>

                  <div className='flex-none' style={{ position: 'absolute', top: '20px', right: '20px' }}>
                    <button onClick={onClose}>
                      <img src={Close} alt='Expand' />
                    </button>
                  </div>
                </div>
                <div className='body form-body'>
                  <div className='form-border'>
                    <div className='row-input'>
                      <div className='form-group'>
                        <label className='input-lable'>First name</label>
                        <input
                          type='input'
                          readOnly
                          value={selectedUserDetails?.firstName}
                          className='form-control'
                          placeholder='First name'
                        />
                      </div>

                      <div className='form-group'>
                        <label className='input-lable'>Last name</label>
                        <input
                          type='input'
                          readOnly
                          value={selectedUserDetails?.lastName}
                          className='form-control'
                          placeholder='Last name'
                        />
                      </div>
                    </div>

                    <div className='row-input'>
                      <div className='form-group'>
                        <label className='input-lable'>Company</label>
                        <input
                          type='input'
                          readOnly
                          value={selectedUserDetails?.company}
                          className='form-control'
                          placeholder='Company'
                        />
                      </div>

                      <div className='form-group'>
                        <label className='input-lable'>Job title</label>
                        <input
                          type='input'
                          readOnly
                          value={selectedUserDetails?.jobTitle}
                          className='form-control'
                          placeholder='Job title'
                        />
                      </div>
                    </div>

                    <div className='form-group'>
                      <label className='input-lable'>Email address</label>
                      <input
                        type='email'
                        readOnly
                        value={selectedUserDetails?.email}
                        className='form-control'
                        placeholder='Email address'
                      />
                    </div>

                    <div className='form-group'>
                      <label className='input-lable'>Mobile phone number</label>
                      <input
                        type='text'
                        readOnly
                        value={selectedUserDetails?.mobileNumber}
                        className='form-control'
                        placeholder='Mobile phone number'
                      />
                    </div>

                    <div className='row-input'>
                      <div className='form-group'>
                        <label className='input-lable'>Office phone number</label>
                        <input
                          type='input'
                          readOnly
                          value={selectedUserDetails?.officeNumber}
                          className='form-control'
                          placeholder='000-000-0000'
                        />
                      </div>

                      <div className='form-group'>
                        <label className='input-lable'>Extension</label>
                        <input
                          type='input'
                          readOnly
                          value={selectedUserDetails?.phoneExtension}
                          className='form-control'
                          placeholder='#000'
                        />
                      </div>
                    </div>

                    <div className='form-group'>
                      <label className='input-lable'>Pager</label>
                      <input
                        type='text'
                        readOnly
                        value={selectedUserDetails?.pager}
                        className='form-control'
                        placeholder='000-000-0000'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default AlarmSubscriptionModal;
