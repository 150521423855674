import { http } from '../../../../config/HttpCommon';

export const getAssetDetail = async (userId: string, wellName: string) => {
  const response = await http.get(`/GetAssetDetails/${userId}/${wellName}`);
  return response.data;
};

export const getUserDetailById = async (id: string) => {
    const response = await http.get(`/GetUserDetails/${id}`);
    return response.data;
  };