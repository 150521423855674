import React, { useEffect, useState, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import annotation_plus from '../../../../../images/expand-icon.svg';
import download from '../../../../../../src/images/download-icon.svg';
import calendarIcon from '../../../../../images/calendar-icon.svg';
import './WellProductionTrends.scss';
import DateRangeSelector from '../../../../common/DateRangeSelector/DateRangeSelector';
import { customStaticRanges, defineds, formateDate } from '../../../../common/DateRangeSelector/DefaultRanges';
import { useDetectClickOutside } from 'react-detect-click-outside';
import WellProductionTrendsModel from './WellProductionTrendsModel';
import { fetchGetWellProductionTrends } from '../../../DashboardSlice';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks';
import { AppUser } from '../../../../user/model/AppUser';
import { DataPoint, WellProductionTrend, WellData } from '../../../model/WellProductionTrend';
import NoData from '../../no-data/NoData';
import Loader from '../../../../common/page-loader/ComponentLoader';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import moment from 'moment-timezone';
import championx from '../../../../../../src/images/championxLogo.png';

interface WellProductionTrendsProps {
  groupName: string;
  showZindex: any;
}

const WellProductionTrends: React.FC<WellProductionTrendsProps> = ({ groupName, showZindex }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [data, setData] = useState<WellProductionTrend>({ gasData: [], oilData: [], waterData: [] });
  const [detailsData, setDetailsData] = useState<WellData[]>([]);
  const [categoryData, setCategoryData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.dashboard.wellByProductionDataLoading);
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const hElement = document.getElementById('main-root');

  const root = useRef<any>(null);
  const currentUser = useAppSelector((state) => state?.user?.currentUser);

  const getLast7DaysDate = () => {
    const today = new Date();
    const last7Days = new Date(today);
    last7Days.setDate(today.getDate() - 6);
    return last7Days;
  };

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: getLast7DaysDate(),
    endDate: new Date(),
    key: 'selection',
  });

  function setToStartOfDay(date: any) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
  }
  function setToEndOfDay(date: any) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
  }
  // Ensure selectedDateRange.startDate is at the start of the day
  const startDate = setToStartOfDay(selectedDateRange.startDate);
  // Ensure selectedDateRange.endDate is at the end of the day
  const endDate = setToEndOfDay(selectedDateRange.endDate);

  const fetchData = async () => {
    if (storedUser?.id && selectedDateRange?.startDate && selectedDateRange?.endDate) {
      const resultAction = await dispatch(
        fetchGetWellProductionTrends({
          userId: storedUser?.id,
          startDate: formateDate(selectedDateRange.startDate),
          endDate: formateDate(selectedDateRange.endDate),
          groupName: groupName,
        }),
      );
      if (fetchGetWellProductionTrends.fulfilled.match(resultAction)) {
        const wellProductionTrend = resultAction.payload.wellProductionTrend as WellProductionTrend;
        const wellProductionTrendDetails = resultAction.payload.wellProductionTrendDetails as WellData[];
        setDetailsData(wellProductionTrendDetails);

        const transformedData: WellProductionTrend = {
          gasData: wellProductionTrend.gasData.map((item) => ({
            date: new Date(item.date).getTime(),
            value: item.value,
          })),
          oilData: wellProductionTrend.oilData.map((item) => ({
            date: new Date(item.date).getTime(),
            value: item.value,
          })),
          waterData: wellProductionTrend.waterData.map((item) => ({
            date: new Date(item.date).getTime(),
            value: item.value,
          })),
        };
        setData(transformedData);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedDateRange.startDate, selectedDateRange.endDate, groupName]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.innerHTML = ''; // Clear the previous chart's HTML
    }

    if (!chartRef.current) return;
    if (chartRef.current) {

      // Create a container for the chart and legend
      root.current = am5.Root.new(chartRef.current);
      // const root = am5.Root.new(chartRef.current);
      root.current.setThemes([am5themes_Animated.new(root.current)]);

   // Create a main container to hold both the chart and the legend
   const mainContainer = root.current.container.children.push(
    am5.Container.new(root.current, {
      layout: root.current.verticalLayout,
      width: am5.percent(100),
      height: am5.percent(100),
    })
  );

  // Create the chart container
  const chartContainer = mainContainer.children.push(
    am5.Container.new(root.current, {
      width: am5.percent(100),
      height: am5.percent(85), // Adjust height to leave space for the legend
    })
  );

   
      const chart = chartContainer.children.push(
        am5xy.XYChart.new(root.current, {
          focusable: true,
          panX: false,
          panY: false,
          wheelY: 'none',
          wheelX: 'none',
          pinchZoomX: false,
          pinchZoomY: false,
          paddingBottom: 0,
        }),
      );

      // Additionally, you can also disable zoomOutButton
      chart.zoomOutButton.set("forceHidden", true);
      
      const xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root.current, {
          maxDeviation: 0.1,
          groupData: false,
          extraMax: 0,
          extraMin: 0,
          min: startDate.getTime(),
          max: endDate.getTime(),
          baseInterval: { timeUnit: 'day', count: 1 },
          renderer: am5xy.AxisRendererX.new(root.current, {
            minGridDistance: 50,
            minorGridEnabled: true,
            stroke: am5.color('#487aa6'),
          }),
          dateFormats: {
            day: 'MM/dd',
            week: 'MM/dd',
            month: 'MMM',
            year: 'yyyy',
          },
          periodChangeDateFormats: {
            day: 'MM/dd',
            week: 'MM/dd',
            month: 'MMM',
            year: 'yyyy',
          },
          start: 0,
          end: 1,
        }),
      );
      xAxis.get('renderer').labels.template.set('fill', am5.color('#ffffff'));
      xAxis.get('renderer').labels.template.set('paddingTop', 15);

      const yRenderer = am5xy.AxisRendererY.new(root.current, {
        opposite: false,
        stroke: am5.color('#487aa6'),
      });
      yRenderer.labels.template.set('fill', am5.color('#fff'));
      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root.current, {
          extraMax: 0,
          extraMin: 0,
          maxDeviation: 0,
          renderer: yRenderer,
        }),
      );
      const cursor = chart.set(
        'cursor',
        am5xy.XYCursor.new(root.current, {
          xAxis: xAxis,
          yAxis: yAxis,
          behavior: 'none',
          showTooltipOn: 'hover',
        }),
      );
      cursor.lineY.set('visible', false);
      cursor.lineX.setAll({
        visible: true,
        stroke: am5.color('#ADD8E6'),
        strokeWidth: 2,
      });

      cursor.lineY.setAll({
        strokeDasharray: [2, 2],
      });
      const createSeries = (data: DataPoint[], strokeColor: string, fillColor: string, name: string) => {
        const series = chart.series.push(
          am5xy.LineSeries.new(root.current, {
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'value',
            valueXField: 'date',
            tooltip: am5.Tooltip.new(root.current, {
              pointerOrientation: 'horizontal',
              labelText: `{name}: {valueY} BBL`,
            }),
          }),
        );

        series.set('stroke', am5.color(strokeColor));
        series.set('fill', am5.color(strokeColor));
        series.bullets.push(() => {
          const circle = am5.Circle.new(root.current, {
            radius: 0,
            fill: am5.color('#0000'),
            stroke: am5.color('#fff'),
            strokeWidth: 5,
            visible: true,
          });

          circle.states.create('hover', {
            radius: 7,
          });

          return am5.Bullet.new(root.current, {
            locationX: 0.5,
            sprite: circle,
          });
        });

        series.data.setAll(data);

        cursor.events.on('cursormoved', cursorMoved);

        let previousBulletSprites: any = [];
        function cursorMoved() {
          for (let i = 0; i < previousBulletSprites?.length; i++) {
            previousBulletSprites[i].unhover();
          }
          previousBulletSprites = [];
          chart.series.each(function (series: any) {
            const dataItem = series?.get('tooltip')?.dataItem;
            if (dataItem) {
              const bulletSprite = dataItem?.bullets?.length && dataItem?.bullets[0]?.get('sprite');
              bulletSprite && bulletSprite.hover();
              previousBulletSprites.push(bulletSprite);
            }
          });
        }
      }
      
      createSeries(data.gasData, '#F97066', '#001326', 'Gas');
      createSeries(data.oilData, '#32D583', '#001326', 'Oil');
      createSeries(data.waterData, '#4294FF', '#001326', 'Water');

      const resizeObserver = new ResizeObserver(() => {
        chart.appear(0, 0);
      });

      resizeObserver.observe(chartRef.current);


  // Create the legend container below the chart
  const legendContainer = mainContainer.children.push(
    am5.Container.new(root.current, {
      width: am5.percent(100),
      height: am5.percent(15), // Adjust height to match the space left by the chart
      layout: root.current.verticalLayout,
    })
  );

  const legend = legendContainer.children.push(
    am5.Legend.new(root.current, {
      layout: am5.GridLayout.new(root.current, {
        maxColumns: 3,
        fixedWidthGrid: true,
      }),
      centerX: am5.percent(50),
      x: am5.percent(50),
      setStateOnChildren: false,
      paddingTop: 5,
      useDefaultMarker: true,
    })
  );
      
      legend.markerRectangles.template.setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBL: 10,
        cornerRadiusBR: 10,
        width: 12,
        height: 12,
      });

      legend.labels.template.set('fill', am5.color(0xffffff));
      legend.data.setAll(chart.series.values);
      legend.itemContainers.template.states.create('hover', {});

      return () => {
        root.current.dispose();
        resizeObserver.disconnect();
      };
    }
  }, [data]);

  useEffect(() => {
    if (showCalendar) {
      showZindex();
    }
  }, [showCalendar]);

  useEffect(() => {
    const categoryData:any = detailsData
    .map((item: any) => item?.operator)
    .filter((value, index, self) => value !== null && value !== undefined && self.indexOf(value) === index);
    setCategoryData(categoryData);
    const regionData:any = detailsData
    .map((item: any) => item?.region)
    .filter((value, index, self) => value !== null && value !== undefined && self.indexOf(value) === index);
    setRegionData(regionData);
  }, [detailsData]);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const openModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsModalOpen(true);
    hElement && hElement?.classList.add('modal-open');
  };

  const closeModal = () => {
    setIsModalOpen(false);
    hElement && hElement?.classList.remove('modal-open');
  };

  const handleSlideOutClick = (e: any) => {
    if (e.target.id === 'range-calendar-input') return;
    if (showCalendar) {
      setShowCalendar(!showCalendar);
    }
  };

  const ref = useDetectClickOutside({ onTriggered: handleSlideOutClick });

  function formatDateRange(startDate: any, endDate: any) {
    const formatDate = (date: any) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${month}/${day}/${year}`;
    };

    const formattedStartDate = startDate ? formatDate(new Date(startDate)) : '';
    const formattedEndDate = endDate ? formatDate(new Date(endDate)) : '';

    return `${formattedStartDate} - ${formattedEndDate}`;
  }

  const formattedDateRange = formatDateRange(selectedDateRange.startDate, selectedDateRange.endDate);

  const printWidgetAsPDF = () => {
    if (root.current) {
      const exporting = am5plugins_exporting.Exporting.new(root.current, {
        menu: am5plugins_exporting.ExportingMenu.new(root.current, {}),
      });
      Promise.all([
        exporting.getPdfmake(),
        exporting.export('png'),

      ]).then((res) => {
        const pdfMake = res[0];
        const str = moment().format("MMM-DD-YYYY hh:mm:ss A");
        const doc = {
          pageSize: 'A4',
          pageOrientation: 'portrait',
          pageMargins: [30, 30, 30, 30],
          footer: {
            columns: [
              {
                text: `Generated By: ${currentUser?.firstName || ''} ${currentUser?.lastName || ''}`,
                alignment: 'left',
                "width": "50%",
                "bold": true,
                "margin": [10, 10],
                "fontSize": 9,
                "color": "black",
                "decoration": 'underline',

              },
              {
                text: "Generated on : " + str, alignment: 'right',
                "width": "50%",
                "bold": true,
                "margin": [10, 10],
                "fontSize": 9,
                "color": "black",
                "decoration": 'underline',

              }
            ]
          },
          content: [] as any[],
          styles: {
            tblheader: {
              fontSize: 12,
              color: 'white',
              fillColor: '#01485e',
              alignment: 'center',
            },
            tblheader1: {
              fontSize: 12,
              color: 'white',
              fillColor: '#01485e',
              alignment: 'left',
            },
            tbody1: {
              alignment: 'center',
            }
          }
        };

        const rect = {
          type: 'rect',
          x: -32,
          y: -20, //could be negative to account for padding
          w: 450,
          h: 25,
          linearGradient: ['#01485e', '#01485e']
        };
        const rectHead = {
          type: 'rect',
          x: -32,
          y: -20, //could be negative to account for padding
          w: 150,
          h: 25,
          linearGradient: ['#484848', '#484848']
        };
        doc.content.push({

          "columns": [{

            stack: [
              { canvas: [rect] },
              {
                columns: [
                  {
                    width: rect.w / 2,
                    noWrap: false, //clip at with by wrapping to next line and...
                    maxHeight: rect.h, //don't show the wrapped text
                    "image": championx,
                    "fit": [55, 55],
                    "margin": [5.5, 7.5, 3],
                    "background": 'white',
                  },
                  {
                    "text": "Landing Page",
                    width: rect.w / 2,
                    "alignment": "left",
                    "bold": true,
                    "margin": [35, 5],
                    "fontSize": 13,
                    "color": "white",
                  }],
                relativePosition: {
                  x: rect.x,
                  y: - rect.h
                }
              }
            ]
          },
          {
            stack: [
              { canvas: [rectHead] },
              {
                columns: [
                  {
                    width: rectHead.w,
                    noWrap: false, //clip at with by wrapping to next line and...
                    maxHeight: rect.h, //don't show the wrapped text
                    text: 'LOOKOUT',
                    color: 'white',
                    "bold": true,
                    "alignment": "right",
                    "font-size": 10,

                  }],
                relativePosition: {
                  x: -80,
                  y: - 20
                }
              }
            ]
          }
          ]
        });
        doc.content.push({
          "text": " ",
          "width": "100%",
        });

        doc.content.push(
          {
            text: 'Well Production Trends',
            width: "*",
            alignment: "center",
            fontSize: 18,
            margin: [0, 0, 0, 5],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 175, y1: 0,
                x2: 362, y2: 0,
                lineWidth: 1
              }
            ],
            margin: [0, 0, 0, 15]
          }
        );

        doc.content.push({
          "text": formattedDateRange,
          "width": "100%",
          alignment: "center",
          fontSize: 13,
          margin: [0, 0, 0, 15]
        });

        doc.content.push({
          image: res[1],
          alignment: "center",
          width: 450,
          height: 180,
          margin: [0, 0, 0, 20],
        });

        const tblBody = [[
          { text: 'WellName', bold: true, style: 'tblheader1' },
          { text: 'Oil', bold: true, style: 'tblheader' },
          { text: 'Water', bold: true, style: 'tblheader' },
          { text: 'Gas', bold: true, style: 'tblheader' }]
        ];

        if (detailsData) {
          const dynamicHeaders = ['wellName', 'oilData', 'waterData', 'gasData'];
          detailsData.forEach((item: any) => {
            const row: any = dynamicHeaders.map(key => {
              if (key === 'wellName') {
                return { text: item[key].toString(), bold: false };
              }
              return { text: item[key].toString(), bold: false, style: 'tbody1' };
            });

            tblBody.push(row);
          });

          // const layout = {
          //   vLineWidth: function () {
          //       return 0;
          //   }}

          doc.content.push({
            table: {
              headerRows: 1,
              widths: Array(dynamicHeaders.length).fill('*'), body: tblBody,
            },
            // layout: layout
          });
        }

        const fileName = "Lookout-Landing_Page_WellProductionTrends_" + str + ".pdf"

        pdfMake.createPdf(doc).download(fileName);

      });
    }
  };

  const renderDateRange = () => {
    return (
      <>
        <div className='well-production-trends__input-container'>
          <div className='well-production-trends__input-group'>
            <img src={calendarIcon} alt='Range Calendar' />
            <input
              id='range-calendar-input'
              className='input-date'
              value={`${formateDate(selectedDateRange.startDate)} - ${formateDate(selectedDateRange.endDate)}`}
              onClick={() => setShowCalendar(!showCalendar)}
            />
          </div>
        </div>
        <div ref={ref} className='well-production-trends-range'>
          {showCalendar && (
            <DateRangeSelector setShowCalendar={setShowCalendar} setSelectedDateRange={setSelectedDateRange} staticRanges={customStaticRanges} minDate={defineds?.last180Days} maxDate={defineds?.endOfToday} />
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div className='table-header-section pie-section'>
        <div className='title'>Well production trends: oil, water, gas</div>
        <div className='header-icon'>
          <button
            className='expand-btn cancelSelectorName'
            onClick={openModal}
            style={{ cursor: 'pointer', zIndex: 0 }}
          >
            <img src={annotation_plus} alt='Expand' className='img-border' />
          </button>

          <div className='dropdown-container' ref={dropdownRef}>
            <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              <span className='dot'></span>
              <span className='dot'></span>
              <span className='dot'></span>
            </button>
            {isDropdownOpen && (
              <div className='dropdown-options'>
                <button>Actions</button>
                <button className={`btn btn-default cancelSelectorName ${(data?.gasData?.length && data?.oilData?.length && data?.waterData?.length) === 0 ? 'btn-disabled' : ''}`}
                  onClick={printWidgetAsPDF}>
                  <img src={download} alt='icon' className='btn-icon' />
                  Download PDF
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <hr />
      {renderDateRange()}

      <div id="wellProductionTrend-container" className='wellProductionTrendsChartSection'>
        {loading ? (
          // <div className='well-production-loader'>
            <Loader />
          // </div> 
        ) : data?.gasData?.length > 0 || data?.oilData?.length > 0 || data?.waterData?.length > 0 ? (
         // <div ref={chartRef} id='wellProductionTrendsChartdiv' style={{ height: '100%', minHeight: '280px', maxHeight: '100%', width: '100%' }}></div>
           <div ref={chartRef} id='wellProductionTrendsChartdiv' style={{ height: '100%', width: '100%' }}></div>
        ) : (
          <NoData heading='No data found' />
        )}
      </div>

      <WellProductionTrendsModel
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        heading='Well production trends: oil, water, gas'
        data={data}
        detailsData={detailsData}
        selectedDateRange={selectedDateRange}
        setSelectedDateRange={setSelectedDateRange}
        selectCategoryOptions={categoryData}
        selectRegionOptions={regionData}
      />
    </>
  );
};

export default WellProductionTrends;
