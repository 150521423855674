import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

interface NavigationState {
    isDrawerOpen: boolean;
}

const initialState: NavigationState = {
  isDrawerOpen: false,
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setDrawerState(state, action: PayloadAction<boolean>) {
      state.isDrawerOpen = action.payload;
    },
  },
});

export const { setDrawerState } = navigationSlice.actions;

export const getDrawerState = (state: RootState) => state.navigation.isDrawerOpen;

export default navigationSlice.reducer;
