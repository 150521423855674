import React from 'react';
import './User.scss';
import TabWithUrlComponent from '../../../components/TabWithUrl';
import AlarmSubscription from '../components/AlarmSubscription';
import MeasurementDisplay from '../components/MeasurementDisplay';
import UserProfile from '../components/UserProfile';
import NotificationPreferences from '../components/notification-Preferences/NotificationPreferences';
import Security from '../components/security/Security';
import { AppUser } from '../model/AppUser';

const User: React.FC = () => {
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser | null = storedUserData ? JSON.parse(storedUserData) : null;

  const tabs = [
    { label: 'Profile', component: UserProfile },
    { label: 'Security', component: Security },
    { label: 'Alarm subscription', component: AlarmSubscription },
    { label: 'Notification preferences', component: NotificationPreferences },
    { label: 'Measurement display', component: MeasurementDisplay },
  ];

  return (
    <div className='group-status-container profile-container'>
      <div className='profile-status-header profile-status-header-user'>
        <div className='group-status-header group-status-header-user'>
          <div className='group-status-content'>
            <div className='group-status-frame'>
              <div className='text-and-badge'>
                <div className='text'>Settings</div>
              </div>
            </div>
          </div>
        </div>
        <div className='divider'></div>
        {/* Render TabWithUrlComponent and pass tabs array */}
        <TabWithUrlComponent initialTab={{ tab: 'Profile' }} tabs={tabs} value={storedUser?.id} />
      </div>
    </div>
  );
};

export default User;
