export const getAPIBaseURL = () => {
  return `${process.env.REACT_APP_WEB_API_BASE_URL}:${process.env.REACT_APP_WEB_API_PORT}/api`;
};

export const getFerrariAPIBaseURL = () => {
  return `${process.env.REACT_APP_WEB_API_BASE_URL}:${process.env.REACT_APP_WEB_API_PORT}/userapi`;
};

export const getWebSocketURL = () => {
  return `${process.env.REACT_APP_WELLCONTROL_API_BASE_URL}/wellcontrolapi/`;
}

type WebSocketKeys = 'ws' | 'wellcontrolws';

// Define a type for the webSocketMap object
interface WebSocketMap {
  ws?: string;
  wellcontrolws?: string;
}

// Define the webSocketMap object with the correct type
const webSocketMap: WebSocketMap = {
  ws: process.env.REACT_APP_WEB_SOCKET_URL,
  wellcontrolws: process.env.REACT_APP_WELLCONTROL_API_BASE_URL,
};

// Define the getWebSocketBaseURL function with the correct types
const getWebSocketBaseURL = (id = 'ws') => {
  const lowercasedId = id.toLowerCase() as WebSocketKeys;
  const baseUrl = webSocketMap[lowercasedId];
  if (baseUrl) {
    return `${baseUrl}/${lowercasedId}`;
  }

  return webSocketMap['ws'];
};
const convertWindowProtocolToWebSocket = (id: string) => {
  const host = window.location.host;
  return window.location.protocol === 'https:' ? `wss://${host}/${id}` : `ws://${host}/${id}`;
};


export const getWebSocketURI = (id: string) => {
  const wellControlWebSocketURI = getWebSocketBaseURL(id) ?? '';

  if (wellControlWebSocketURI !== '') {
    return wellControlWebSocketURI;
  } else {
    return convertWindowProtocolToWebSocket(id);
  }
};

//To detect mobile devices
export const isMobileDevice = (): boolean => {
  const userAgent: string = navigator.userAgent || navigator.vendor;

  const isOpera = typeof window !== 'undefined' && 'opera' in window;
  const isIE = typeof window !== 'undefined' && 'MSStream' in window;

  const isMobileUserAgent = /android|iPad|iPhone|iPod/i.test(userAgent) && ! (isIE || isOpera);
  const isSmallScreen = window.outerWidth <= 768; //break point for mobile screen.

  //check for android and ios devices
  return isMobileUserAgent && isSmallScreen;
};