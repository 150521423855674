import axios from 'axios';
import { getAPIBaseURL } from '../../utilities/BaseURLUtility';

export interface FetchNotificationTypeResponseValues {
  Id: number;
  Name: string;
}

export interface FetchNotificationTypeResponse {
  Id: string;
  DateCreated: string;
  Values: FetchNotificationTypeResponseValues[];
}

const fetchNotificationTypeNameAsync = async () => {
  try {
    const response = await axios.get(`${getAPIBaseURL()}/notificationTypes`);

    if (response.data && Array.isArray(response.data.Values)) {
      return response.data as FetchNotificationTypeResponse;
    } else {
      console.error('Unexpected response format:', response.data);
    }
  } catch (error) {
    console.error('Error fetching notification list:', error);
  }
};

export { fetchNotificationTypeNameAsync };
