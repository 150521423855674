import React, { useState, useEffect } from 'react';
import './GLAnalysis.scss';
import UIIconButtonToolbar from '../../common/UIIconButtonToolbar/UIIconButtonToolbar';
import ViewToggle1 from '../../../images/view toggle-1.png';
import ViewToggle2 from '../../../images/view toggle-2.png';
import BarChart from '../../../images/bar-chart-07.png';
import PipeValve from '../../../images/pipe-valve-regular 1.png';
import UIEyeButton from '../../common/UIButtons/UIEyeButton';
import UIFilterButton from '../../common/UIButtons/UIFilterButton';
import { HorizontalTabs } from '../../common/HorizontalTabs/HorizontalTabs';
import CardHeader from '../CardHeader';
import { HorizontalTabsV2 } from '../../common/HorizontalTabs/HorizontalTabsV2';
import AnalysisInputOutput from '../../common/AnalysisInputOutput/AnalysisInputOutput';
import { useAppSelector, useAppDispatch } from '../../../hooks/storeHooks';
import { fetchInitialStateAsync } from '../GLAnalysisSlice';
import XDAnalysis from '../../common/XDAnalysis/XDAnalysis';
import GlAnalysisWellTest from '../GLAnalyisisWellTest';
import PerformanceCurveGraph from '../../../images/Performance-curve-graph.png';

export const GLAnalysis = () => {
  const dispatch = useAppDispatch();
  const glAnalysisData = useAppSelector((state) => state.glAnalysisData?.glAnalysis);
  const [selectedTabId, setSelectedTabId] = useState(0);

  useEffect(() => {
    dispatch(fetchInitialStateAsync());
  }, [dispatch]);
  const uiIconButtons = [
    {
      key: 0,
      name: 'view-toggle-1',
      active: true,
      image: ViewToggle1,
      tooltip: 'Toggle View 1',
    },
    {
      key: 1,
      name: 'view-toggle-2',
      active: false,
      image: ViewToggle2,
      tooltip: 'Toggle View 2',
    },
  ];

  const tabs = [
    {
      key: 0,
      value: 'Well Tests',
      active: true,
    },
    {
      key: 1,
      value: 'Survey Dates',
      active: false,
    },
    {
      key: 2,
      value: 'Group Scaling',
      active: false,
    },
  ];

  const tabsv2 = [
    {
      key: 0,
      value: 'Production Analysis',
      active: true,
      imgSrc: BarChart,
    },
    {
      key: 1,
      value: 'Valve Analysis',
      active: false,
      imgSrc: PipeValve,
    },
  ];

  const inputData = glAnalysisData?.Values?.Inputs.map((item) => {
    return {
      id: item.Id,
      title: item.DisplayName,
      value: item.DisplayValue,
    };
  });

  const outputData = glAnalysisData?.Values?.Outputs.filter((item) => item.Id !== 'XDiagAnalysis').map((item) => {
    return {
      id: item.Id,
      title: item.DisplayName,
      value: item.DisplayValue,
    };
  });
  const xDiagAnalysisData: string = glAnalysisData?.Values?.Outputs.find((item) => item.Id === 'XDiagAnalysis')
    ?.DisplayValue as string;

  const [uiIconButtonState, setActive] = useState(uiIconButtons);
  const [tabState, setActiveTab] = useState(tabs);
  const [tabV2State, setV2ActiveTab] = useState(tabsv2);

  function buttonClicked(id: number) {
    alert(uiIconButtons[id].name);
    const newArray = [...uiIconButtonState];
    newArray.forEach((button) => {
      if (id == button.key) {
        button.active = true;
      } else {
        button.active = false;
      }
    });
    setActive(newArray);

    return;
  }

  function tabClicked(id: number) {
    const newArray = tabState.map((button) => ({
      ...button,
      active: id === button.key,
    }));

    setActiveTab(newArray);
    setSelectedTabId(id);
  }
  function tabV2Clicked(id: number) {
    const newArray = tabV2State.map((button) => ({
      ...button,
      active: id === button.key,
    }));

    setV2ActiveTab(newArray);
  }

  return (
    <div className="scrollable-container">
      <div className="performance-curve-frame">
        <CardHeader value="Performance curve" />
        <div className="divider" />
        <div className="performance-curve-content">
          <div className="performance-curve-graph">
            <div className="header">
              <div className="content-header-frame">
                <UIIconButtonToolbar uiIconButtons={uiIconButtonState} buttonClicked={buttonClicked} />
                <div className="toolbar-dropdown-container">
                  <UIEyeButton />
                  <UIFilterButton />
                </div>
              </div>
            </div>
            <div className="card gl-analysis-card">
              <img className="curve-graph" src={PerformanceCurveGraph} />
            </div>
          </div>
          <div className="vertical-divider"></div>
          <div className="data-frame">
            <div className="tabs">
              <HorizontalTabs data={tabState} tabClicked={tabClicked} />
            </div>
            <div className="tab-content">{<GlAnalysisWellTest tabId={selectedTabId} />}</div>
          </div>
        </div>
      </div>
      <div className="performance-data-frame">
        <CardHeader value="Performance data" />
        <div className="divider" />
        <HorizontalTabsV2 data={tabV2State} tabClicked={tabV2Clicked} />
        <div className="data-container">
          <div className="container1">
            <div className="input-container">
              <AnalysisInputOutput headerText="Input" values={inputData} />
            </div>
            <div className="output-container">
              <AnalysisInputOutput headerText="Output" values={outputData} />
            </div>
          </div>
          <div className="xd-analysis-container">
            <XDAnalysis headerText="X-Diag Analysis" value={xDiagAnalysisData} />
          </div>
        </div>
      </div>
    </div>
  );
};
