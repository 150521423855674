import React, { useState } from 'react';

interface Tab {
  label: string;
  component: React.ComponentType;
}

interface TabsProps {
  tabs: Tab[];
  value: string | undefined;
}

const TabWithoutUrl: React.FC<TabsProps> = ({ tabs, value }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const handleTabClick = (index: number) => {
    setActiveTabIndex(index);
  };

  return (
    <>
      <div className='ui-button-toolbar'>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`ui-button-toolbar-button ${
              index === activeTabIndex ? 'ui-button-toolbar-button-active semi-bold' : 'semi-bold'
            }`}
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className='tab-content'>
        {React.createElement(tabs[activeTabIndex]?.component, {
          value,
        } as React.Attributes)}
      </div>
    </>
  );
};

export default TabWithoutUrl;
