import '../../rod-lift-analysis/CardDateItem/CardDateItem.scss';

interface WellTestItemProps {
  date: string;
  isSelected: boolean;
  onClickAction: (id: string) => void;
}

export const WellTestItem = (props: WellTestItemProps) => {
  const styles = {
    background: '#005F7C',
  };

  return (
    <div className="card-date-item-container" onClick={() => props.onClickAction(props.date)}>
      <div style={props.isSelected ? styles : {}} className="card-date-item-chart-color" />
      <div
        className={
          props.isSelected
            ? 'card-date-item-item-container card-date-item-item-container-active'
            : 'card-date-item-item-container'
        }
      >
        <div className="card-date-item-text-container">
          <label className="card-date-item-text card-date-item-card-date-text"> {props.date ?? '-'} </label>
        </div>
      </div>
    </div>
  );
};

export default WellTestItem;
