import {
  addDays,
  endOfDay,
  startOfDay,
  isSameDay,
} from 'date-fns';

export const defineds = {
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  last3Days: startOfDay(addDays(new Date(), -2)),
  last7Days: startOfDay(addDays(new Date(), -6)),
  last9Days: startOfDay(addDays(new Date(), -8)),
  last30Days: startOfDay(addDays(new Date(), -29)),
  last90Days: startOfDay(addDays(new Date(), -89)),
};

const staticRangeHandler = {
  range: () => ({
    startDate: defineds.startOfToday,
    endDate: defineds.endOfToday,
  }),
  isSelected(range: { startDate: number | Date; endDate: number | Date; }) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges: any[]) {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([

  {
    label: 'Last 3 days',
    range: () => ({
      startDate: defineds.last3Days,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Last 7 days',
    range: () => ({
      startDate: defineds.last7Days,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Last 9 days',
    range: () => ({
      startDate: defineds.last9Days,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Last 30 days',
    range: () => ({
      startDate: defineds.last30Days,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Last 90 days',
    range: () => ({
      startDate: defineds.last90Days,
      endDate: defineds.endOfToday,
    }),
  },
]);

export const formateDate = (date: Date) => {
  const yyyy = date.getFullYear();
  let mm: string | number = date.getMonth() + 1;
  let dd: string | number = date.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  return mm + '-' + dd + '-' + yyyy;
}

export const formatStringDate = (dateString: string | undefined) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export const capitalizeFirstLetter = (s: string | undefined) => {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}