import React, { useState } from 'react';
import './PageLayout.css';
import NavigationBar from '../features/navigation/components/NavigationBar';
import { Outlet } from 'react-router-dom';
import '../styles/darktheme/style.scss';

export const Layout = () => {
  const [darkTheme] = useState(true);

  return (
    <>
      <div className={`outer-container ${darkTheme ? 'theme-dark' : ''}`}>
        {/* Add a toggle switch for changing the theme 
        <div className='toggle-switch theme-toggle'>
          <label className='switch'>
            <input type='checkbox' checked={darkTheme} onChange={toggleTheme} />
            <span className='slider'></span>
          </label>
        </div> */}
        <NavigationBar />

        <Outlet />
      </div>
    </>
  );
};
