import { FC } from "react";
import "./PropertyValueList.css";

interface IPropertyValue {
    id: number;
    description: string;
    value?: string | null;
    displayState?: number | null;
}

interface IPropertyValueList {
    description: string;
    properties: IPropertyValue[];
}



export const PropertyListCard: FC<IPropertyValueList> = ({ description, properties }) => {

    return (
        <div className="card property-list">
            <div className="header">
                <span className="title">{ description }</span>
            </div>
            <div className="body">

                {(properties.length === 0) &&
                    <div className="property-list-item">
                        <span className="description">None</span>
                    </div>
                }

                {(properties.length > 0) &&
                    properties.map((p:IPropertyValue) => { 
                        return (
                            <div key={p.id} className="property-list-item">
                                <span className="description">{ p.description }:</span>
                                <span className="value">{ p.value ?? "——" }</span>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    );
}

export default PropertyListCard;