import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getDeviceValue, getSetPointByWellId, updateSetpointValuesData } from './SetPointsService';
import { ApiResponse, Setpoint } from './model/ApiResponse';

interface DeviceValue {
    address: number,
    value: number
}
export interface ChangedBackupValue {
    [key: string]: number
}
interface SetPointState {
    loading: boolean;
    readDeviceLoading: boolean,
    readDeviceSuccessStatus: boolean,
    readDeviceErrorStatus: boolean,
    readDeviceReturnErrorStatus: boolean,
    deviceReadStatus: boolean,
    setPointItems: Setpoint[] | null;
    apiResponse: ApiResponse | null;
    message: string | null;
    success: boolean;
    deviceValue: DeviceValue[];
    deviceAction: string,
    changedBackupValues: ChangedBackupValue | null
}

const initialState: SetPointState = {
    loading: false,
    readDeviceLoading: false,
    readDeviceSuccessStatus: false,
    readDeviceErrorStatus: false,
    readDeviceReturnErrorStatus: false,
    deviceReadStatus: false,
    setPointItems: null,
    apiResponse: null,
    message: null,
    success: false,
    deviceValue: [],
    deviceAction: '',
    changedBackupValues: null
};



// Define your async thunk for fetching users
export const fetchSetPoint = createAsyncThunk('user/fetchSetPoint', async (wellId: string) => {
    const response = await getSetPointByWellId(wellId);
    return response;
});


// Define your async thunk for updating a user
export const updateSetpointValues = createAsyncThunk(
    'user/updateSetpointValues',
    async ({ wellId, setpointValues, socketId }: { wellId: string, setpointValues: any, socketId: string }) => {
        const response = await updateSetpointValuesData(wellId, setpointValues, socketId);
        return response.data; // Assuming your response has a 'data' property
    }
);

//to read device

export const fetchDeviceValue = createAsyncThunk('user/readDevice', async ({ wellId, setPointAddresses, socketId }: { wellId: string, setPointAddresses: string, socketId: string }) => {
    const response = await getDeviceValue(wellId, setPointAddresses, socketId);
    return response;
});

export const fetchCurrentFreqValue = createAsyncThunk('user/gtCurrentFreq', async ({ wellId, setPointAddresses, socketId }: { wellId: string, setPointAddresses: string, socketId: string }) => {
    const response = await getDeviceValue(wellId, setPointAddresses, socketId);
    return response;
});



const setPointSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetSetPointValues: (state) => {
            state.setPointItems = [];
        },

        resetReadDeviceStatus: (state, action) => {
            state.readDeviceLoading = false;
            state.readDeviceSuccessStatus = false
            state.readDeviceErrorStatus = false
            if (action.payload === 'All') {
                state.deviceReadStatus = false
                state.deviceValue = []
            }
        },
        setChangedBackupValues: (state, action) => {
            const passedValue: ChangedBackupValue = action.payload
            if (state.changedBackupValues)
                state.changedBackupValues[Object.keys(passedValue)[0]] = Object.values(passedValue)[0]
            else
                state.changedBackupValues = passedValue
        },
        handleReadDeviceReturnSuccess: (state, action) => {
            state.deviceValue = action.payload
            state.readDeviceLoading = false;
            state.readDeviceSuccessStatus = true
            state.deviceReadStatus = true

            const changedBackupValue: ChangedBackupValue = {};

            action.payload.forEach((deviceValue: DeviceValue) => {
                changedBackupValue[deviceValue.address.toString()] = deviceValue.value;
            });

            state.changedBackupValues = changedBackupValue
        },
        handleReadDeviceReturnError: (state) => {
            state.readDeviceLoading = false;
            state.readDeviceErrorStatus = true
            if (state.deviceAction === 'Read')
                state.deviceReadStatus = false

            console.log("state", state)
        }
    },
    extraReducers: (builder) => {
        // Handle the fetchUsers fulfilled action
        builder.addCase(fetchSetPoint.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchSetPoint.fulfilled, (state, action) => {
            state.apiResponse = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchSetPoint.rejected, (state, action) => {
            state.message = action.payload as string;
            state.loading = false;
        });

        // Handle the loginUser fulfilled action
        builder.addCase(updateSetpointValues.pending, (state) => {
            state.readDeviceLoading = true;
            state.readDeviceSuccessStatus = false
            state.readDeviceErrorStatus = false
            state.deviceAction = 'Update'

        });
        // builder.addCase(updateSetpointValues.fulfilled, (state) => {
        //     state.readDeviceLoading = false;
        //     state.loading = false;
        // });
        builder.addCase(updateSetpointValues.rejected, (state, action) => {
            state.message = action.payload as string;
            state.loading = false;
            state.readDeviceLoading = false;
            state.readDeviceErrorStatus = true
        });

        //Handle read device action
        builder.addCase(fetchDeviceValue.pending, (state) => {
            state.deviceValue = []
            state.readDeviceLoading = true;
            state.readDeviceSuccessStatus = false
            state.readDeviceErrorStatus = false
            state.readDeviceReturnErrorStatus = false
            state.deviceReadStatus = false
            state.deviceAction = 'Read'
        });
        // builder.addCase(fetchDeviceValue.fulfilled, (state) => {
        //     //state.setPointItems = action.payload;
        //     state.loading = false;
        // });
        builder.addCase(fetchDeviceValue.rejected, (state) => {
            state.readDeviceLoading = false;
            state.readDeviceErrorStatus = true;
            state.deviceReadStatus = false
        });

        //Handle read current frequency action
        builder.addCase(fetchCurrentFreqValue.pending, (state) => {
            state.deviceValue = []
            state.readDeviceLoading = true;
            state.readDeviceSuccessStatus = false
            state.readDeviceErrorStatus = false
            state.readDeviceReturnErrorStatus = false
        });
        builder.addCase(fetchCurrentFreqValue.rejected, (state) => {
            state.readDeviceLoading = false;
            state.readDeviceErrorStatus = true;
            state.deviceReadStatus = false
        });

    },
});

export const { resetSetPointValues, handleReadDeviceReturnSuccess, resetReadDeviceStatus, setChangedBackupValues, handleReadDeviceReturnError } = setPointSlice.actions;
export default setPointSlice.reducer;
