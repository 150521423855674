import React, { useCallback } from 'react';
import thinArrow from '../../../../../images/thinArrow.svg';
import './WellProductionTrends.scss';

interface WellProductionTrendDropdownProps {
  isSelectOpen: boolean;
  options: string[];
  handleToggleDropdown: () => void;
  handleItemClick: (value: any) => void;
  handleClearButton: () => void;
  handleApplyButton: ((selectedOptions: []) => void);
  filterName: string;
  defaultFilterValue: string[];
  setDefaultFilterValue: React.Dispatch<React.SetStateAction<string[]>>;
  dropdownRef: any;
  prevFilter: string[];
}

const WellProductionTrendDropdown: React.FC<WellProductionTrendDropdownProps> = ({
  isSelectOpen,
  options,
  handleToggleDropdown,
  handleApplyButton,
  handleClearButton,
  filterName,
  defaultFilterValue,
  setDefaultFilterValue,
  handleItemClick,
  dropdownRef,
  prevFilter
}) => {

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>,) => {
    const value: string = e?.target.value;
    const checkFilterAvl: boolean = defaultFilterValue?.includes(value);
    if (checkFilterAvl) {
      const updatedFilteredValues = defaultFilterValue?.filter((filteredValue: any) => filteredValue !== value);
      setDefaultFilterValue(updatedFilteredValues);
    } else {
      setDefaultFilterValue((prev: string[]) => [...prev, value]);
    }
    handleItemClick(value);
  };

  const handleApply = useCallback(() => {
    const selected: any = options.filter(option => defaultFilterValue?.includes(option));
    setDefaultFilterValue(selected);
    handleApplyButton(selected);
  }, [defaultFilterValue, options, handleApplyButton]);

  const handleClear = () => {
    setDefaultFilterValue([]);
    handleClearButton();
  };

  return (
    <>
      <div className='well-production-trends__filter-dropdown' ref={dropdownRef}>
        <div className='well-production-trends__dropdown-input-container' onClick={handleToggleDropdown}>
          <input type='text' className='well-production-trends__dropdown-input' value={`All ${filterName} (${prevFilter?.length})`} readOnly />
          <img
            src={thinArrow}
            alt={isSelectOpen && options?.length ? 'Up Arrow' : 'Down Arrow'}
            className={`trend-img ${isSelectOpen && options?.length ? 'rotate-180' : ''}`}
          />
        </div>
        {isSelectOpen && options?.length ? (
          <div className='trends-filter-modal-container'>
            <div className='trends-filter-modal-header'>{`Filter ${filterName}`}</div>
            <div className='trends-filter-modal-body'>
              {options?.map((item, index) => {
                return (
                  <>
                    <div className='checkbox-main-container' key={index}>
                      <label className='checkbox-container'>
                        <input
                          type='checkbox'
                          id='showCheckbox'
                          checked={defaultFilterValue?.includes(item)}
                          onChange={(e) => handleCheckboxChange(e)}
                          value={item}
                        />
                        <span className='checkbox-checkmark'></span>
                        <span className='inline-block pl-[29px]'>{item}&nbsp;</span>
                      </label>
                    </div>
                  </>
                );
              })}
            </div>
            <div className='trends-filter-modal-footer'>
              <button className={'footer-btn'} onClick={handleClear}>
                Clear
              </button>
              <button className={'footer-btn'} onClick={handleApply}>
                Apply
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default WellProductionTrendDropdown;
