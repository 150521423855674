import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCardDatesAsync } from './RodLiftAnalysisService';

interface CardDateItemState {
  date: string;
  cardTypeId: string;
  cardTypeName: string;
  isSelected: boolean;
}

interface CardDateListState {
  values: CardDateItemState[];
}

const fetchRodLiftAnalysisCardDatesAsync = createAsyncThunk('RodLiftAnalysis/fetchCardDates', async (assetId: string) => {
  const response = await fetchCardDatesAsync(assetId);

  return response;
});

const initialState: CardDateListState = {
  values: [],
};

const rodLiftAnalysisCardDateSlice = createSlice({
  name: 'RodLiftAnalysis/CardDate',
  initialState,
  reducers: {
    setSelected(state, action: PayloadAction<string>) {
      const date = action.payload;

      state.values.forEach((x) => {
        if (x.date == date) {
          x.isSelected = true;
        } else {
          x.isSelected = false;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRodLiftAnalysisCardDatesAsync.fulfilled, (state, action) => {
      if (action?.payload?.values === undefined || action.payload.values.length === 0) {
        return;
      }

      state.values = action.payload?.values.map(
        (x) =>
          ({
            date: x.date,
            cardTypeId: x.cardTypeId,
            cardTypeName: x.cardTypeName,
          }) as CardDateItemState,
      );
    });
  },
});

export { fetchRodLiftAnalysisCardDatesAsync, CardDateItemState };
export const { setSelected } = rodLiftAnalysisCardDateSlice.actions;
export default rodLiftAnalysisCardDateSlice.reducer;
