import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { cancelScheduleById, createScheduleById, getCurrentFreqSetPointByWellId, getScheduleById, updateScheduleById } from './ScheduleSpeedChangeService';
import { ScheduleSpeedChange } from './model/ScheduleSpeedChange';
import { SimpleSpeedChange } from './model/SimpleSpeedChange';
import { SteppedSpeedChange } from './model/SteppedSpeedChange';

interface ScheduledState {
  loading: boolean;
  message: string | null;
  scheduleSpeedChange: ScheduleSpeedChange | null;
  currentFrequency: string
  wellName: any;
}

const initialState: ScheduledState = {
  loading: false,
  message: null,
  scheduleSpeedChange: null,
  wellName: '',
  currentFrequency: ''
};

export const fetchScheduleById: any = createAsyncThunk(
  'scheduleSpeedChange/fetchScheduleById',
  async ({ userId, deviceId, wellId }: { userId: string; deviceId: string; wellId: string }) => {
    const response = await getScheduleById(userId, deviceId, wellId);
    return response;
  },
);
export const fetchCurrentFreqSetPoint = createAsyncThunk('user/fetchSetPoint', async (wellId: string) => {
  const response = await getCurrentFreqSetPointByWellId(wellId);
  return response;
});

export const deleteScheduleById: any = createAsyncThunk(
  'scheduleSpeedChange/deleteScheduleById',
  async (id: string) => {
    const response = await cancelScheduleById(id);
    return response;
  },
);

const scheduledSpeedChangeSlice = createSlice({
  name: 'scheduleSpeedChange',
  initialState,
  reducers: {
    removeScheduleSpeedChangeData(state) {
      state.scheduleSpeedChange = null;
    },
    addWellName(state, action) {
      state.wellName = action
    }
  },
  extraReducers: (builder) => {
    // Handle the fetchScheduleById fulfilled action
    builder.addCase(fetchScheduleById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchScheduleById.fulfilled, (state, action) => {
      state.scheduleSpeedChange = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchScheduleById.rejected, (state, action) => {
      state.scheduleSpeedChange = null;
      state.message = action.payload as string;
      state.loading = false;
    });

    // Handle the CreateScheduleSpeed fulfilled action
    builder.addCase(createScheduleSpeed.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createScheduleSpeed.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createScheduleSpeed.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
    });

    // Handle Update Schedule Speed Change
    builder
      .addCase(updateScheduleSpeedChange.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateScheduleSpeedChange.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateScheduleSpeedChange.rejected, (state, action) => {
        state.loading = false;
        state.message = action.error.message || 'Failed to update schedule speed change';
      });
    // Handle get current frequency set point
    builder
      .addCase(fetchCurrentFreqSetPoint.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCurrentFreqSetPoint.fulfilled, (state, action) => {
        state.loading = false;
        state.currentFrequency = action.payload
      })
      .addCase(fetchCurrentFreqSetPoint.rejected, (state) => {
        state.loading = false;
        state.currentFrequency = '';
      });
    // Handle the deleteScheduleById fulfilled action
    // builder
    //   .addCase(deleteScheduleById.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(deleteScheduleById.fulfilled, (state) => {
    //     state.loading = false;
    //   })
    //   .addCase(deleteScheduleById.rejected, (state, action) => {
    //     state.loading = false;
    //     state.message = action.error.message || 'Failed to delete schedule speed change';
    //   });
  },
});

// Create ScheduleById
export const createScheduleSpeed = createAsyncThunk(
  'user/createScheduleSpeed',
  async (scheduleData: {
    userId: string;
    wellId: string;
    wellName: string;
    groupName: string;
    deviceId: string;
    simpleSpeedChanges: SimpleSpeedChange[];
    steppedSpeedChanges: SteppedSpeedChange[];
  }) => {
    const response = await createScheduleById(scheduleData);
    return response;
  },
);

// Async thunk for updating schedule speed change
export const updateScheduleSpeedChange = createAsyncThunk(
  'user/updateScheduleSpeedChange',
  async ({
    id,
    speedChanges,
  }: {
    id: string;
    speedChanges: {
      simpleSpeedChanges: SimpleSpeedChange[];
      steppedSpeedChanges: SteppedSpeedChange[];
    };
  }) => {
    return updateScheduleById(id, speedChanges);
  },
);

export const { removeScheduleSpeedChangeData, addWellName } = scheduledSpeedChangeSlice.actions;

export default scheduledSpeedChangeSlice.reducer;
