import axios from 'axios';
import { getAPIBaseURL } from '../../utilities/BaseURLUtility';

export const fetchGLAnalysisInitialState = async () => {
  try {
    const response = await axios.get(`${getAPIBaseURL()}/GLAnalysis`);
    return response.data;
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return [];
  }
};

export const fetchGLAnalysisSurveyDateInitialState = async () => {
  try {
    const response = await axios.get(`${getAPIBaseURL()}/GLAnalysisSurveyDate`);
    return response.data;
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return [];
  }
};

export const fetchGLAnalysisWellTestInitialState = async () => {
  try {
    const response = await axios.get(`${getAPIBaseURL()}/GLAnalysisWellTest`);
    return response.data;
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return [];
  }
};
