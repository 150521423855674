import AnalysisInputOutput from '../common/AnalysisInputOutput/AnalysisInputOutput';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import '../rod-lift-analysis/RodLiftAnalysis/RodLiftAnalysis.scss';
import '../../styles/Analysis.scss';
import XDAnalysis from '../common/XDAnalysis/XDAnalysis';
import AnalysisResultHeader from '../common/AnalysisResultHeader/AnalysisResultHeader';
import ESPChart from '../../images/pump-curve-chart-placeholder.png';
import ViewToggle1 from '../../images/view toggle-1.png';
import ViewToggle2 from '../../images/view toggle-2.png';
import ChevronDown from '../../images/chevron-down.png';
import Eye from '../../images/eye.png';
import FilterFunnel from '../../images/filter-funnel-01.png';
import UIIconButtonToolbar from '../common/UIIconButtonToolbar/UIIconButtonToolbar';
import { fetchESPAnalysisAsync } from './ESPAnalysisSlice';
import WellTestList from './WellTestList/WellTestList';
import ViewOptions from '../common/ViewOptions/ViewOptions';
import Tooltip from '../common/tooltip/ToolTip';

const espCurveViewOptions = [
  { id: 1, label: 'Tornado curves' },
  { id: 2, label: 'Pump curves' },
  { id: 3, label: 'Efficiency curves' },
  { id: 4, label: 'Power curve' },
  { id: 5, label: 'Recommended range' },
  { id: 6, label: 'Performance curve' },
];

const ESPAnalysis = () => {
  const dispatch = useAppDispatch();
  const analysisStore = useAppSelector((state) => state.espAnalysisResult);
  const wellTestStore = useAppSelector((state) => state.espAnalysisWellTest);

  const uiIconButtons = [
    {
      key: 0,
      name: 'view-toggle-1',
      active: true,
      image: ViewToggle1,
      tooltip: 'Curve View',
    },
    {
      key: 1,
      name: 'view-toggle-2',
      active: false,
      image: ViewToggle2,
      tooltip: 'Timeline View',
    },
  ];

  const [uiIconButtonState, setActive] = useState(uiIconButtons);

  function buttonClicked(id: number) {
    alert(uiIconButtons[id].name);
    const newArray = [...uiIconButtonState];
    newArray.forEach((button) => {
      if (id == button.key) {
        button.active = true;
      } else {
        button.active = false;
      }
    });
    setActive(newArray);

    return;
  }

  useEffect(() => {
    dispatch(fetchESPAnalysisAsync({ assetId: '1', dateTime: '5' }));
  }, []);

  const createAnalysisResultHeaderDescription = () => {
    const selectedWellTestDate = wellTestStore.values.find((x) => x.isSelected);

    if (selectedWellTestDate) {
      return `${selectedWellTestDate.date}`;
    }

    return '';
  };

  const onClickActionHandler = () => {
    alert('Button Clicked.');
  };

  const [isPumpDataCollapsed, setIsPumpDataCollapsed] = useState(false);
  const toggleBodyCollapse = () => {
    setIsPumpDataCollapsed((prevIsPumpDataCollapsed) => !prevIsPumpDataCollapsed);
  };

  return (
    <div className='card-viewer'>
      <div className='card-viewer-header'>
        <span className='card-viewer-title'>Pump curve</span>
      </div>
      <div className='card-viewer-body'>
        <div className='card-viewer-icons'>
          <UIIconButtonToolbar uiIconButtons={uiIconButtonState} buttonClicked={buttonClicked} />
          <div className='card-viewer-button-container'>
            <Tooltip content='Curve View Options' direction='top'>
              <ViewOptions
                options={espCurveViewOptions}
                buttonContent={
                  <div className='card-viewer-button'>
                    <img src={Eye} />
                    <img src={ChevronDown} />
                  </div>
                }
                onOptionChange={(id) => {
                  id;
                }}
              />
            </Tooltip>
            <button data-testid='card-viewer-filter' className='card-viewer-button' onClick={onClickActionHandler}>
              <img src={FilterFunnel} />
              <img src={ChevronDown} />
            </button>
          </div>
        </div>
        <div className='card-viewer-card'>
          <div className='flex flex-row flex-grow flex-shrink'>
            <img data-testid='card-viewer-chart' className='card-viewer-chart' src={ESPChart} />
            <div className='card-viewer-date-list'>
              <WellTestList />
            </div>
          </div>
        </div>
        <div className='rodliftanalysis-container'>
          <div className='rodliftanalysis-header'>
            <AnalysisResultHeader
              title='Pump curve data'
              description={createAnalysisResultHeaderDescription()}
              isCollapsed={isPumpDataCollapsed}
              toggleCollapse={toggleBodyCollapse}
            />
          </div>
          {!isPumpDataCollapsed && (
            <div className='rodliftanalysis-body'>
              <AnalysisInputOutput
                headerText='Input'
                values={analysisStore.inputs.map((x, index) => ({
                  key: index,
                  id: x.Id,
                  title: x.DisplayName,
                  value: x.DisplayValue,
                }))}
              />
              <AnalysisInputOutput
                headerText='Output'
                values={analysisStore.outputs.map((x, index) => ({
                  key: index,
                  id: x.Id,
                  title: x.DisplayName,
                  value: x.DisplayValue,
                }))}
              />
              <XDAnalysis headerText='Analysis Data' value={analysisStore.xdiagAnalysis.DisplayValue} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ESPAnalysis;
