import axios from 'axios';
import { getAPIBaseURL } from '../../utilities/BaseURLUtility';

export interface FetchWellTestResponseValues {
  Date: string;
}

export interface FetchWellTestResponse {
  Values: FetchWellTestResponseValues[];
}

const fetchWellTestAsync = async (assetId: string) => {
  try {
    const response = await axios.get(`${getAPIBaseURL()}/WellTest/${assetId}`);
    return response.data as FetchWellTestResponse;
  } catch (error) {
    console.error('Error fetching esp analysis well test dates', error);
  }
};

export interface AnalysisInputOutputResponseValues {
  Id: string;
  Name: string;
  DisplayName: string;
  Value: string;
  DisplayValue: string;
  DataTypeId: number;
  MeasurementAbbreviation: string;
  SourceId: number;
}

export interface FetchAnalysisResponseValues {
  Inputs: AnalysisInputOutputResponseValues[];
  Outputs: AnalysisInputOutputResponseValues[];
}

export interface FetchAnalysisResponse {
  Values: FetchAnalysisResponseValues;
}

const fetchESPAnalysisServiceAsync = async (assetId: string, dateTime: string) => {
  try {
    const response = await axios.get(`${getAPIBaseURL()}/ESPAnalysis/${assetId}/${dateTime}`);
    return response.data as FetchAnalysisResponse;
  } catch (error) {
    console.error('Error fetching esp analysis', error);
  }
};

export { fetchWellTestAsync, fetchESPAnalysisServiceAsync };
