import React from 'react';
import WIP from '../../../images/wip1.svg';

const AdminNotifications: React.FC = () => {
  return (
    <>
      <div className='flex flex-row flex-grow profile-block w-100 py-6 px-8'>
        <div className='card profile-card m-0'>
          <div className='header'>
            <span className='title'>Notification</span>
            <div>Create an notification details here.</div>
          </div>
          <div className='body'>
            <center>
              <img src={WIP} alt='work in progress' />
              <p className='mt-6'>Under construction / Maintanance</p>
            </center>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminNotifications;
