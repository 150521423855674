import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  AnalysisInputOutputResponseValues,
  fetchAnalysisAsync,
} from './RodLiftAnalysisService';

interface FetchAnalysisParameters {
  assetId: string;
  dateTime: string;
}

const fetchRodLiftAnalysisAsync = createAsyncThunk(
  'RodLiftAnalysis/fetchAnalysis',
  async (data: FetchAnalysisParameters) => {
    const response = await fetchAnalysisAsync(data.assetId, data.dateTime);

    return response;
  },
);

interface RodLiftAnalysisState {
  input: AnalysisInputOutputResponseValues[];
  output: AnalysisInputOutputResponseValues[];
  xdiagAnalysis: AnalysisInputOutputResponseValues;
}

const initialState: RodLiftAnalysisState = {
  input: [],
  output: [],
  xdiagAnalysis: {} as AnalysisInputOutputResponseValues,
};

const analysisSlice = createSlice({
  name: 'RodLiftAnalysis/Analysis',
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRodLiftAnalysisAsync.fulfilled, (state, action) => {
      if (action?.payload?.Values?.Input !== undefined) {
        state.input = action.payload.Values.Input;
      }

      if (action?.payload?.Values?.Output !== undefined) {
        state.output = action.payload.Values.Output.filter((x) => x.Id.toLowerCase() !== 'xdanalysis');
      }

      if (action?.payload?.Values?.Output !== undefined) {
        const analysis = action.payload.Values.Output.find((x) => x.Id.toLowerCase() === 'xdanalysis');

        if (analysis) {
          state.xdiagAnalysis = analysis;
        }
      }
    });
  },
});

export { fetchRodLiftAnalysisAsync }
export const { setInitialState } = analysisSlice.actions;
export default analysisSlice.reducer;