import React from 'react';
import Featured from '../../../../../images/featured-icon.svg';

interface ErrorModalProps {
    isErrorModal: boolean;
    isErrorModalClose: () => void;
    errorMessage: string;
}

const ErrorMessage: React.FC<ErrorModalProps> = ({ isErrorModal, isErrorModalClose, errorMessage }) => {

    return (
        <>
            {isErrorModal && (
                <div className='modal-overlay delete-modal-page'>
                    <div className='modal'>
                        <div className='flex flex-row flex-grow profile-block'>
                            <div className='notify-card'>
                                <div className='header-delete'>
                                    <div className='feature-img'>
                                        <img src={Featured} alt='' />
                                    </div>
                                    <p className='title'>Upload error</p>
                                    <p className='normal-text'>{errorMessage}</p>
                                </div>
                                <div className='flex justify-center pb-4'>
                                    <button onClick={isErrorModalClose} className='btn btn-primary'>
                                        OK
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ErrorMessage;
