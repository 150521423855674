export const graph1Data = (capacityData: CapacityCurveGraphSample[]) => {
  return ({
    head: capacityData.filter((data) => data.SeriesName === 'Head').map((data) => data.PointValue),
    power: capacityData.filter((data) => data.SeriesName === 'Power').map((data) => data.PointValue),
    efficiency: capacityData.filter((data) => data.SeriesName === 'Efficiency').map((data) => data.PointValue),
    min: capacityData.filter((data) => data.SeriesName === 'Min').map((data) => data.PointValue),
    bep: capacityData.filter((data) => data.SeriesName === 'BEP').map((data) => data.PointValue),
    max: capacityData.filter((data) => data.SeriesName === 'Max').map((data) => data.PointValue),
    targetH: capacityData.filter((data) => data.SeriesName === 'TargetH').map((data) => data.PointValue),
    targetV: capacityData.filter((data) => data.SeriesName === 'TargetV').map((data) => data.PointValue),
    targetPoint: capacityData.filter((data) => data.SeriesName === 'TargetPoint').map((data) => data.PointValue),
  })
};

export const graph3Data = (capacityData: CapacityCurveGraphSample[]) => {
  return ({
    f45: capacityData.filter((data) => data.SeriesName === 'F45').map((data) => data.PointValue),
    f50: capacityData.filter((data) => data.SeriesName === 'F50').map((data) => data.PointValue),
    f55: capacityData.filter((data) => data.SeriesName === 'F55').map((data) => data.PointValue),
    f60: capacityData.filter((data) => data.SeriesName === 'F60').map((data) => data.PointValue),
    f65: capacityData.filter((data) => data.SeriesName === 'F65').map((data) => data.PointValue),
    f70: capacityData.filter((data) => data.SeriesName === 'F70').map((data) => data.PointValue),
    f75: capacityData.filter((data) => data.SeriesName === 'F75').map((data) => data.PointValue),
    min: capacityData.filter((data) => data.SeriesName === 'Min').map((data) => data.PointValue),
    bep: capacityData.filter((data) => data.SeriesName === 'BEP').map((data) => data.PointValue),
    max: capacityData.filter((data) => data.SeriesName === 'Max').map((data) => data.PointValue),
    targetH: capacityData.filter((data) => data.SeriesName === 'TargetH').map((data) => data.PointValue),
    targetV: capacityData.filter((data) => data.SeriesName === 'TargetV').map((data) => data.PointValue),
    targetPoint: capacityData.filter((data) => data.SeriesName === 'TargetPoint').map((data) => data.PointValue),
  })
}

export const graph2Data = (capacityData: CapacityCurveGraphSample[]) => {
  return ({
    f45: capacityData.filter((data) => data.SeriesName === 'F45').map((data) => data.PointValue),
    f50: capacityData.filter((data) => data.SeriesName === 'F50').map((data) => data.PointValue),
    f55: capacityData.filter((data) => data.SeriesName === 'F55').map((data) => data.PointValue),
    f60: capacityData.filter((data) => data.SeriesName === 'F60').map((data) => data.PointValue),
    f65: capacityData.filter((data) => data.SeriesName === 'F65').map((data) => data.PointValue),
    f70: capacityData.filter((data) => data.SeriesName === 'F70').map((data) => data.PointValue),
    f75: capacityData.filter((data) => data.SeriesName === 'F75').map((data) => data.PointValue),
    min: capacityData.filter((data) => data.SeriesName === 'Min').map((data) => data.PointValue),
    bep: capacityData.filter((data) => data.SeriesName === 'BEP').map((data) => data.PointValue),
    max: capacityData.filter((data) => data.SeriesName === 'Max').map((data) => data.PointValue),
    targetH: capacityData.filter((data) => data.SeriesName === 'TargetH').map((data) => data.PointValue),
    targetV: capacityData.filter((data) => data.SeriesName === 'TargetV').map((data) => data.PointValue),
    targetPoint: capacityData.filter((data) => data.SeriesName === 'TargetPoint').map((data) => data.PointValue),
  })
}


export const iprGraphData = (iprData: iprGraphSample[]) => {
  if (typeof iprData === typeof "")
    iprData = JSON.parse(iprData as unknown as string);

  return ({
    wiggins: iprData.filter((data) => data.SeriesName === 'Wiggins').map((data) => data.PointValue),
    vogel: iprData.filter((data) => data.SeriesName === 'Vogel').map((data) => data.PointValue),
    gilbert: iprData.filter((data) => data.SeriesName === 'Gilbert').map((data) => data.PointValue),
  })
}

export const depletionGraphData = (depletionData: iprGraphSample[]) => {
  if(typeof depletionData === typeof "")
    depletionData = JSON.parse(depletionData as unknown as string);
  return ({
    wiggins: depletionData.filter((data) => data.SeriesName === 'Wiggins').map((data) => data.PointValue),
    depletion10Percent: depletionData.filter((data) => data.SeriesName === 'depletion10Percent').map((data) => data.PointValue),
    depletion20Percent: depletionData.filter((data) => data.SeriesName === 'depletion20Percent').map((data) => data.PointValue),
    depletion30Percent: depletionData.filter((data) => data.SeriesName === 'depletion30Percent').map((data) => data.PointValue),
    depletion40Percent: depletionData.filter((data) => data.SeriesName === 'depletion40Percent').map((data) => data.PointValue),
    depletion50Percent: depletionData.filter((data) => data.SeriesName === 'depletion50Percent').map((data) => data.PointValue),
    depletion60Percent: depletionData.filter((data) => data.SeriesName === 'depletion60Percent').map((data) => data.PointValue),
    depletion70Percent: depletionData.filter((data) => data.SeriesName === 'depletion70Percent').map((data) => data.PointValue),
    generalDepletion: depletionData.filter((data) => data.SeriesName === 'GeneralDepletion').map((data) => data.PointValue),
  })
}

export type CapacityCurveGraphSample = {
  SeriesName: string,
  PointValue: {
    X: number,
    Y: number
  }
}

export type iprGraphSample = {
  SeriesName: string,
  PointValue: {
    X: number,
    Y: number
  }
}



