import './CardDateList.scss';
import CardDateItem from '../CardDateItem/CardDateItem';
import { useEffect } from 'react';
import { fetchRodLiftAnalysisCardDatesAsync, setSelected } from '../CardDateSlice';
import { useAppSelector, useAppDispatch } from '../../../hooks/storeHooks';

interface CardDateListProps {
  assetId: string,
}

const CardDateList = (props: CardDateListProps) => {
  useEffect(() => {
    dispatch(fetchRodLiftAnalysisCardDatesAsync(props.assetId));
  }, []);

  const dispatch = useAppDispatch();
  const store = useAppSelector((state) => state.rodLiftAnalysis);

  const onClickActionHandler = (date: string) => {
    alert(date);
    dispatch(setSelected(date));

    return;
  };

  return (
    <div>
      {store.values.map((x, index) => (
        <CardDateItem
          key={index}
          date={x.date}
          name={x.cardTypeName}
          isSelected={x.isSelected}
          onClickAction={onClickActionHandler}
          showIcon={false}
        />
      ))}
    </div>
  );
};

export default CardDateList;
