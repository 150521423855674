import React from 'react';
import './HorizontalTabs.scss';

interface tabDetails {
  key: number;
  value: string;
  active: boolean;
}
interface HorizontalTabsProps {
  data: tabDetails[];
  tabClicked: (id: number) => void;
}
export const HorizontalTabs = (props: HorizontalTabsProps) => {
  const { data, tabClicked } = { ...props };
  return (
    <div className="horizontal-tabs">
      {data?.map((tab: tabDetails) => {
        const { value, key, active } = { ...tab };

        const className = active ? 'tab active' : 'tab inactive';
        return (
          <div
            className={className}
            onClick={() => {
              tabClicked(key);
            }}
          >
            {value}
          </div>
        );
      })}
    </div>
  );
};
