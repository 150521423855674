import React from 'react';
import {isMobileDevice} from '../../../utilities/BaseURLUtility';
import './MobileDeviceWarning.scss';
import warningBanner from "../../../images/mobileWarning.svg";


const MobileDeviceWarning: React.FC = () => {
  if(!isMobileDevice()){
    return null; // if no mobile
  }
  return (
    <div className="mobile-warning">
      <div className="img-warning">
        <img src={warningBanner} alt='Warning banner' className='img-mobileWarning'/>
      </div>
      <h3 className='heading-warning'>This website is only accessible from desktop. Mobile is not yet supported.</h3>
    </div>
  )
};

export default MobileDeviceWarning;