import React, { useEffect, useRef, useState } from 'react';
import './Admin.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { fetchOperators, fetchUsers } from '../UserSlice';
import SearchIcon from '../../../images/search-icon.svg';
import filtericon from '../../../images/filter-icon.svg';
import chevrondown from '../../../images/chevron-down.svg';
import UserList from './UserList';
import SelectedUser from './SelectedUser';
import { fetchUserById } from '../UserSlice';
import adminProfile from '../../../images/adminprofile.png';
import UserAction from '../components/user-action/UserAction';
import { Operator } from '../model/Operator';
// import Loader from '../../common/page-loader/ComponentLoader';
// import ToggleSwitch from '../../common/ToggleSwitch/ToggleSwitch';

const AdminProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const operators = useAppSelector((state) => state.user.operators);
  // const loading = useAppSelector((state) => state.user.userLoading);
  const users = useAppSelector((state) => state.user.users);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [userActionModalOpen, setUserActionModalOpen] = useState(false);
  const [userActionStatus, setUserActionStatus] = useState<string>('');
  const [isDdlOpen, setIsDdlOpen] = useState<boolean>(false);
  const [selectedOperators, setSelectedOperators] = useState<string[]>([]);
  const [operatorSearchTerm, setOperatorSearchTerm] = useState<string>('');
  const hElement = document.getElementById('main-root');

  const ddlRef = useRef(null);
  useEffect(() => {
    const fetchUser = async () => {
      if (selectedUserId) {
        await dispatch(fetchUserById(selectedUserId));
      }
    };

    fetchUser();
  }, [dispatch, selectedUserId]);

  useEffect(() => {
    const fetchData = async () => {
      if (users.length === 0) dispatch(fetchUsers());
    };
    fetchData();
  }, [dispatch, users]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ddlRef.current && !(ddlRef.current as HTMLElement).contains(event.target as Node)) {
        setIsDdlOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
  };

  const openDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleUsers = async (status: string) => {
    setUserActionModalOpen(true);
    setUserActionStatus(status);
    setIsDropdownOpen(false);
    hElement && hElement?.classList.add('modal-open');
  };

  const closeModal = () => {
    setUserActionModalOpen(false);
    setUserActionStatus('')
    hElement && hElement?.classList.remove('modal-open');
  };

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (event.target instanceof HTMLElement && event.target.closest('.dropdown-container')) return;
      setIsDropdownOpen(false);
    };
    document.body.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.body.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchOperators());
  }, [dispatch]);

  const toggleDdl = () => {
    setIsDdlOpen(!isDdlOpen);
  };
  const filteredOperators = operators?.filter((op: Operator) =>
    op.name.toLowerCase().includes(operatorSearchTerm.toLowerCase()),
  );

  const handleOperatorToggle = (operator: string) => {
    const isChecked = selectedOperators.includes(operator);
    const newCheckedElements = isChecked ? selectedOperators.filter((ele: any) => ele !== operator) : [...selectedOperators, operator];
    setSelectedOperators(newCheckedElements);
  };

  return (
    <>
      <div className='admin-profile__container w-100 py-6 px-8'>
        <div className='card profile-card admin-profile m-0'>
          <div className='header header-notify'>
            <div className='admin-header'>
              <p className='admin-title'>Lookout users : <span className='admin-users'>{users.length} users</span></p>
              <p className='admin-text'>Manage users and their account permissions here. </p>
            </div>
          </div>
          <div className='body user-details-body'>
            <div className='user-search-body relative'>
              <div className='admin-content-search'>
                <div className='admin-search-input-div'>
                  <div className='input-group'>
                    <img src={SearchIcon} alt='Search Icon' className='search-icon-block' />
                    <input className='custom-input' placeholder='Search by user, email, or phone number' onChange={handleSearchChange} />
                  </div>
                  <div className={`user-filter ml-3 relative ${isDdlOpen ? 'filter-active' : ''}`}>
                    <button className='toolbar-eye-btn' onClick={toggleDdl}>
                      <img src={filtericon} alt='Filter icon' className='filter-icon' />
                      <img src={chevrondown} className='chevron-icon' alt='Chevron Icon' />
                    </button>
                  </div>
                  {isDdlOpen && (
                    <div className='ddl-menu absolute user-filter-container' ref={ddlRef}>
                      <div className='user-filter-block'>
                        <div className='user-filter-header'>Filter operators</div>
                        <div className='user-filter-search'>
                          <input
                            type='text'
                            placeholder='Search for operator...'
                            value={operatorSearchTerm}
                            onChange={(e) => setOperatorSearchTerm(e.target.value)}
                            className='operator-search-input'
                          />
                        </div>
                        <div className='user-toggle-container'>
                          {filteredOperators?.map((operator: Operator, index: number) => (
                            <div className="toggle-switch-items" key={index}>
                              <h2>{operator.name}</h2>
                              <label className="switch">
                                <input type="checkbox" checked={selectedOperators.includes(operator.name)} value={operator.name} onChange={() => handleOperatorToggle(operator.name)} />
                                <span className="slider"></span>
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className='dropdown-container'>
                  <button className={`dropdown-btn px-2 py-1 ${isDropdownOpen ? 'admin-profile-users' : ''}`} onClick={() => openDropdown()}>
                    <span className='dot'></span>
                    <span className='dot'></span>
                    <span className='dot'></span>
                  </button>
                  {isDropdownOpen && (
                    <div className='dropdown-options'>
                      <button style={{ pointerEvents: 'none' }}>Bulk actions</button>
                      <button onClick={() => handleUsers('Active')}>Activate users</button>
                      <button onClick={() => handleUsers('InActive')}>Inactivate users</button>
                      <button onClick={() => handleUsers('Delete')}>Remove users</button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='adminuser-block'>
              <div className='flex flex-row'>
                <div className='basis-1/5 admin-first-column'>
                  <UserList
                    searchTerm={searchTerm}
                    selectedOperators={selectedOperators}
                    setSelectedUserId={setSelectedUserId}
                  />
                </div>
                <div className='basis-4/5'>
                  {/* {loading ? (
          <div className='userloader chart-loader flex items-center justify-center w-100 h-full'>
          <Loader />
        </div>
                  ) : ( */}
                  <>
                    {selectedUserId ? (
                      <div className='userblock w-100 p-6'>{selectedUserId && <SelectedUser />}</div>
                    ) : (
                      <div
                        className='userblock'
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100vh',
                          color: 'white',
                          textAlign: 'center',
                        }}
                      >
                        <img src={adminProfile} alt='' />
                        <div>
                          <p style={{ marginTop: '220px', marginLeft: '-200px' }}>Select a user profile</p>
                        </div>
                      </div>
                    )}
                  </>
                  {/* )} */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        userActionStatus &&
        <UserAction actionStatus={userActionStatus} onClose={closeModal} isOpen={userActionModalOpen} />
      }
    </>
  );
};

export default AdminProfile;
