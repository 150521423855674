import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import './EventSchdule.scss';
import chevron_right from '../../../../images/chevron-right.svg';
import { fetchJobSchedule } from '../../DashboardSlice';
import Close from '../../../../images/x-close.svg';
import download from '../../../../../src/images/download-icon.svg';
import { downloadDashboardCSV } from '../../DashboardServices';
import { AppUser } from '../../../user/model/AppUser';
import { updateAssetList, updateSelectedAsset } from '../../../navigation/AssetGroupsSlice';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../common/page-loader/ComponentLoader';
import NoData from '../no-data/NoData';
import { getUrlsArray } from '../../../../utilities/CommonFunctions';
import moment from 'moment-timezone';
import sortIcon from '../../../../images/arrow-up.svg';


interface EventSchedule {
  assetID?: string;
  wellName?: string | undefined;
  operator?: string | undefined;
  date?: string | undefined;
  jobType?: string | undefined;
  assignedTech?: string | undefined;
}

interface EventScheduleProps {
  jobScheduleOpenStatus?: boolean;
}
const EventSchdule: React.FC<EventScheduleProps> = ({ jobScheduleOpenStatus }) => {
  const navigate = useNavigate();
  const jobsScheduleData = useAppSelector((state) => state.dashboard.jobScheduleData);
  const eventsLoading = useAppSelector((state) => state.dashboard.jobScheduleLoading);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const storedUserData = localStorage.getItem('loggedInUser');
  const activeTab = sessionStorage.getItem('activeTabIndex');
  // const currentUser = useAppSelector((state) => state?.user?.currentUser);
  const urls = useAppSelector((state) => state?.dashboard?.urls);
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const dispatch = useAppDispatch();
  const [visibleEventSchedule, setVisibleEventSchedule] = useState<EventSchedule[]>([]);
  const [sortConfig, setSortConfig] = useState<{ key: keyof EventSchedule; direction: 'asc' | 'desc' } | null>({
    key: 'wellName',
    direction: 'asc',
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [displayCount, setDisplayCount] = useState(10);
  const [loading, setLoading] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const quickBaseUrl = urls && getUrlsArray(urls)?.[0]?.key === 'QuickBase_URL' && getUrlsArray(urls)?.[0]?.value;
  const hElement = document.getElementById('main-root');

  useEffect(() => {
    if (storedUser?.id) {
      dispatch(fetchJobSchedule(storedUser?.id));
    }
    setVisibleEventSchedule(jobsScheduleData?.slice(0, 5) || []);
  }, [jobsScheduleData?.length]);

  const handleDownloadCSV = async () => {
    toast.success('Job Schedule CSV download is in progress.!');
    const currentTime = moment().format("MMM-DD-YYYY hh:mm:ss A");
    const userId = storedUser?.id;
    const widgetName = 'JobSchedule';
    const currentDate = new Date().toISOString();
    const futureDate = new Date();
    futureDate.setFullYear(futureDate.getFullYear() + 100);
    const groupName = 'All Wells';
    const data = { userId, widgetName, startDate: currentDate, endDate: futureDate?.toISOString(), groupName };

    try {
      const response = await downloadDashboardCSV(data);
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Lookout-Landing_Page_EventSchedule_' + currentTime + '.csv');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading CSV:', error);
      toast.error('Job Schedule CSV download is failed. Please try after sometime!');
    }

    setIsDropdownOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
    hElement && hElement?.classList.add('modal-open');
  };

  const handleClose = () => {
    setIsModalOpen(false);
    hElement && hElement?.classList.remove('modal-open');
  };

  const requestSort = (key: keyof EventSchedule) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig?.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const sortedEventSchedule = React.useMemo(() => {
    const sortableItems = visibleEventSchedule ? [...visibleEventSchedule] : [];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];
        if (typeof aValue === 'undefined' || typeof bValue === 'undefined') {
          return 0;
        }
        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [visibleEventSchedule, sortConfig]);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleWellName = (wellName: string, assetID: string) => {
    window.scrollTo(0, 0);
    if (activeTab !== '0') {
      sessionStorage.setItem('activeTabIndex', '0');
    }
    const groupList = jobsScheduleData
      ? jobsScheduleData?.map((well: any) => {
        return {
          assetId: well?.assetID,
          assetName: well?.wellName,
          industryApplicationId: 4,
        };
      })
      : [];

    const selectedAsset = {
      assetName: wellName,
      assetGroupName: 'Well Groups',
      assetId: assetID,
      searchString: undefined,
    };

    if (selectedAsset) {
      sessionStorage.setItem('selectedWellName', JSON.stringify(selectedAsset));
    }

    if (groupList) {
      sessionStorage.setItem('wellNameList', JSON.stringify(groupList));
    }

    dispatch(updateSelectedAsset(selectedAsset));
    dispatch(updateAssetList(groupList));
    navigate('/layout/assets/?tab=details');
  };

  useEffect(() => {
    setNoResultsFound(jobsScheduleData?.length === 0);
  }, [jobsScheduleData]);

  const displayedRecords = jobsScheduleData?.slice(0, displayCount);
  const observer = useRef<IntersectionObserver>();
  const lastDocumentElementRef = useCallback(
    (node: HTMLTableRowElement | null) => {
      if (loading || !jobsScheduleData) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && displayCount < jobsScheduleData?.length) {
          setLoading(true);
          setTimeout(() => {
            setDisplayCount((prevDisplayCount) => prevDisplayCount + 10);
            setLoading(false);
          });
        }
      });
      if (node instanceof HTMLTableRowElement) observer.current.observe(node);
    },
    [loading, jobsScheduleData?.length],
  );

  return (
    <>
      <ToastContainer position='top-right' autoClose={3000} closeOnClick={true} />
      <div className='event-schedule-card' style={{ height: jobsScheduleData?.length ? 'auto' : '100%' }}>
        <div className='table-header-section'>
          <div
            className='title'
          >
            Event schedule
          </div>
          <div className=''>
            <div className='header-icon'>
              <div className='dropdown-container' ref={dropdownRef}>
                <button className='dropdown-btn' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                  <span className='dot'></span>
                  <span className='dot'></span>
                  <span className='dot'></span>
                </button>
                {isDropdownOpen && (
                  <div className='dropdown-options'>
                    <button>Actions</button>
                    <button className={`btn btn-default ${(jobsScheduleData?.length === 0 || jobsScheduleData === undefined || jobsScheduleData === null) ? 'btn-disabled' : ''}`} onClick={handleDownloadCSV}>
                      <img src={download} alt='icon' className='btn-icon' />
                      Download CSV
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {eventsLoading || !jobsScheduleData?.length ? <div className='separator'></div> : null}

        {eventsLoading ? (
          <div className='flex items-center justify-center w-100 h-full'>
            <Loader />
          </div>
        ) : jobsScheduleData?.length ? (
          <>
            <div id='scheduleWidgetDiv' className='table-container-dashboard' style={{ width: '100%', height: 'auto' }}>
              <table className='event-schedule w-100'>
                <thead className='sticky-table-header'>
                  <tr className='table-header-cell'>
                    <th className='table-header-item well-name' onClick={() => requestSort('wellName')}>
                      Well name
                      {sortConfig?.key === 'wellName' ? (
                        sortConfig.direction === 'asc' ? (
                          <img src={sortIcon} alt='sort-asc' className='sort-img' />
                        ) : (
                          <img src={sortIcon} alt='sort-dsc' className='sort-img rotate-180' />
                        )
                      ) : (
                        <img src={sortIcon} alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}

                    </th>
                    <th className='table-header-item operator' onClick={() => requestSort('operator')}>
                      Operator
                      {sortConfig?.key === 'operator' ? (
                        sortConfig.direction === 'asc' ? (
                          <img src={sortIcon} alt='sort-asc' className='sort-img' />
                        ) : (
                          <img src={sortIcon} alt='sort-dsc' className='sort-img rotate-180' />
                        )
                      ) : (
                        <img src={sortIcon} alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}

                    </th>
                    <th className='table-header-item event-date' onClick={() => requestSort('date')}>
                      Date
                      {sortConfig?.key === 'date' ? (
                        sortConfig.direction === 'asc' ? (
                          <img src={sortIcon} alt='sort-asc' className='sort-img' />
                        ) : (
                          <img src={sortIcon} alt='sort-dsc' className='sort-img rotate-180' />
                        )
                      ) : (
                        <img src={sortIcon} alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}

                    </th>
                    <th className='table-header-item event-type-one' onClick={() => requestSort('jobType')}>
                      Event type
                      {sortConfig?.key === 'jobType' ? (
                        sortConfig.direction === 'asc' ? (
                          <img src={sortIcon} alt='sort-asc' className='sort-img' />
                        ) : (
                          <img src={sortIcon} alt='sort-dsc' className='sort-img rotate-180' />
                        )
                      ) : (
                        <img src={sortIcon} alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}

                    </th>
                    <th className='table-header-item event-assigned' onClick={() => requestSort('assignedTech')}>
                      Assigned tech{' '}
                      {sortConfig?.key === 'assignedTech' ? (
                        sortConfig.direction === 'asc' ? (
                          <img src={sortIcon} alt='sort-asc' className='sort-img' />
                        ) : (
                          <img src={sortIcon} alt='sort-dsc' className='sort-img rotate-180' />
                        )
                      ) : (
                        <img src={sortIcon} alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody className='event-schedule-body'>
                  <>
                    {sortedEventSchedule?.map((job, index) => (
                      <tr key={index} className='table-row-general'>
                        <td
                          className='pointer well-name'
                          onClick={() => handleWellName(job.wellName ?? '', job.assetID ?? '')}
                        >
                          {job.wellName ?? '-'}
                        </td>
                        <td
                          className='operator'
                          data-title={job.operator}
                          style={jobScheduleOpenStatus == true ? { pointerEvents: 'none' } : {}}
                        >
                          {job.operator && job.operator?.length >= 20
                            ? `${job.operator.substring(0, 15)}...`
                            : job.operator}
                        </td>
                        <td className='event-date'>{formatDate(job.date) ?? '-'}</td>
                        {/* <td style={{ color: getEventTypeColor(job.jobType) }}>{job.jobType}</td> */}
                        <td className='event-type-one'>{job.jobType ?? '-'}</td>
                        <td className='event-assigned'>{job.assignedTech ?? '-'}</td>
                      </tr>
                    ))}
                  </>
                </tbody>
              </table>
            </div>
            <button className='btn view-all' onClick={handleOpen}>
              <span>View all</span>
              <img src={chevron_right} alt='' className='ml-2' />
            </button>
            {isModalOpen && (
              <div className='modal-overlay'>
                <div className='view-all-modal'>
                  <div className='flex flex-row flex-grow profile-block' style={{ marginTop: '0px' }}>
                    <div className='notify-card'>
                      <div className='modal-header'>
                        <div className='flex'>
                          <div className='grow'>
                            <h3 className='title'>Event Schedule</h3>
                          </div>
                          <div className='flex-none'>
                            <button onClick={handleClose}>
                              <img src={Close} alt='Expand' />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className='modal-content'>
                        {eventsLoading || loading ? (
                          <div className='flex items-center'>
                            <Loader />
                          </div>
                        ) : displayedRecords?.length && jobsScheduleData?.length ? (
                          <>
                            <div className='chart-wrap-modal ticket-modal'>
                              <table className='w-100 table table-fixed border-collapse'>
                                <thead>
                                  <tr className='table-header-cell'>
                                    <th className='table-header-item well-name'>Well name</th>
                                    <th className='table-header-item operator'>Operator</th>
                                    <th className='table-header-item job-date'>Date</th>
                                    <th className='table-header-item job-type'>Job type</th>
                                    <th className='table-header-item assigned'>Assigned tech</th>
                                  </tr>
                                </thead>
                                <tbody className='ticket-modal-body'>
                                  {displayedRecords?.map((job, index) => {
                                    const dateFormat = job.date?.split(' ');
                                    const timeHours = dateFormat && dateFormat[1]?.split('.');
                                    let newDate;
                                    let datewithHours;
                                    if (dateFormat && dateFormat[1].includes('00:00:00')) {
                                      newDate = dateFormat[0];
                                    } else {
                                      datewithHours = dateFormat && timeHours && dateFormat[0] + timeHours[0];
                                    }
                                    return (
                                      <tr
                                        key={index}
                                        className='table-row-general'
                                        ref={index + 1 == displayedRecords?.length ? lastDocumentElementRef : null}
                                      >
                                        <td
                                          className='well-name pointer'
                                          onClick={() => handleWellName(job.wellName ?? '', job.assetID ?? '')}
                                        >
                                          {job.wellName ?? '-'}
                                        </td>
                                        <td className='operator'>{job.operator ?? '-'}</td>
                                        <td className='job-date'>{formatDate(newDate) || datewithHours}</td>
                                        <td className='job-type'>{job.jobType ?? '-'}</td>
                                        <td className='assigned'>{job.assignedTech ?? '-'}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                            <div className='separator'></div>
                            <div className='flex items-center justify-center pt-2 viewall-url'><a href={quickBaseUrl} target='_blank'>View in quickbase</a></div>
                          </>
                        ) : (
                          noResultsFound && displayedRecords?.length === 0 && <NoData heading='No data found' />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <NoData heading='No data found' />
        )}
      </div>
    </>
  );
};

export default EventSchdule;
