import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AnalysisInputOutputResponseValues, fetchESPAnalysisServiceAsync } from './ESPAnalysisService';

interface FetchAnalysisParameters {
  assetId: string;
  dateTime: string;
}

const fetchESPAnalysisAsync = createAsyncThunk(
  'ESPAnalysis/fetchESPAnalysis',
  async (data: FetchAnalysisParameters) => {
    const response = await fetchESPAnalysisServiceAsync(data.assetId, data.dateTime);

    return response;
  },
);

interface ESPAnalysisState {
  inputs: AnalysisInputOutputResponseValues[];
  outputs: AnalysisInputOutputResponseValues[];
  xdiagAnalysis: AnalysisInputOutputResponseValues;
}

const initialState: ESPAnalysisState = {
  inputs: [],
  outputs: [],
  xdiagAnalysis: {} as AnalysisInputOutputResponseValues,
};

const espAnalysisSlice = createSlice({
  name: 'ESPAnalysis/Analysis',
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchESPAnalysisAsync.fulfilled, (state, action) => {
      if (action?.payload?.Values?.Inputs !== undefined) {
        state.inputs = action.payload.Values.Inputs;
      }

      if (action?.payload?.Values?.Outputs !== undefined) {
        state.outputs = action.payload.Values.Outputs.filter((x) => x.Id.toLowerCase() !== 'xdiaganalysis');
      }

      if (action?.payload?.Values?.Outputs !== undefined) {
        const analysis = action.payload.Values.Outputs.find((x) => x.Id.toLowerCase() === 'xdiaganalysis');

        if (analysis) {
          state.xdiagAnalysis = analysis;
        }
      }
    });
  },
});

export { fetchESPAnalysisAsync };
export const { setInitialState } = espAnalysisSlice.actions;
export default espAnalysisSlice.reducer;
