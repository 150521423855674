import { http } from '../../../../config/HttpCommon';

export const getTrendsById = async (wellName: string, startDate: string, endDate: string) => {
  const response = await http.get(`/GetAssetTrendsGraphData?wellName=${wellName}&startDate=${startDate}&endDate=${endDate}`);
  return response.data;
};

export const getTrendsFilterData = async () => {
  const response = await http.get(`/GetAssetTrends`);
  return response.data;
}