import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { setFilterTerm, toggleShowAlarms, toggleShowWeather } from './AlarmSlice';
import SearchIcon from '../../../../images/search-icon.svg';
import cloudSun from '../../../../images/weather-icon.svg';
import alerttriangle from '../../../../images/alert-triangle.png';
import ToggleSwitch from '../../../common/ToggleSwitch/ToggleSwitch';
import './AssetTab.scss';

interface Tab {
  label: string;
  component: React.ComponentType<any>;
}

interface TabsProps {
  tabs: Tab[];
  assetDetails?: boolean;
  groupName?: string;
}

const AssetTabs: React.FC<TabsProps> = ({ tabs, assetDetails = false, groupName }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const dispatch = useAppDispatch();
  const showAlarms = useAppSelector((state) => state.alarms.showAlarms);
  const showWeather = useAppSelector((state) => state.alarms.showWeather);
  const filterTerm = useAppSelector((state) => state.alarms.filterTerm);

  const handleShowAlarmsToggle = () => {
    dispatch(toggleShowAlarms());
  };

  const handleFilterTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setFilterTerm(e.target.value));
  };

  const handleShowWeatherToggle = () => {
    dispatch(toggleShowWeather());
  };

  const renderSearchInput = () => {
    return (
      <div
        className='input-group'
        style={{ width: '325px', marginRight: '24px', marginTop: (tabs[activeTabIndex].label === 'List' || tabs[activeTabIndex].label === 'Alarms') ? '1rem' : '0' }}
      >
        <div className='icon-block'>
          <img src={SearchIcon} alt='Search Icon' style={{ pointerEvents: 'visible', cursor: 'pointer' }} />
        </div>
        <input
          className='custom-input search'
          placeholder='Search'
          value={filterTerm}
          onChange={handleFilterTermChange}
          style={{ padding: '10px 14px 10px 32px' }}
        />
      </div>
    );
  };

  const renderAdditionalBlocks = () => {
    if (tabs[activeTabIndex].label === 'List' || tabs[activeTabIndex].label === 'Alarms') {
      return renderSearchInput();
    } else {
      return (
        <>
          <div className='block' style={{ display: 'none' }}>
            <img src={alerttriangle} alt='' className='me-2' />
            <p>Show Alarms Only</p>
            <ToggleSwitch checked={showAlarms} onChange={handleShowAlarmsToggle} label='' value='' />
          </div>
          <div className='block'>
            <img src={cloudSun} alt='' className='me-2' />
            <p>Show weather</p>
            <ToggleSwitch checked={showWeather} onChange={handleShowWeatherToggle} label='' value='' />
          </div>
          <div className='input-group' style={{ width: '325px', marginRight: '10px', marginTop: '10px' }}>
            <div className='icon-block'>
              <img src={SearchIcon} alt='Search Icon' />
            </div>
            {renderSearchInput()}
          </div>
        </>
      );
    }
  };

  const mapToggle = () => {
    if (tabs[activeTabIndex].label === 'Map') {
      return (
        <>
          <div className='block' style={{ display: 'none' }}>
            <img src={alerttriangle} alt='' className='me-2' />
            <p>Show Alarms Only</p>
            <ToggleSwitch checked={showAlarms} onChange={handleShowAlarmsToggle} label='' value='' />
          </div>
          <div className='block'>
            <img src={cloudSun} alt='' className='me-2' />
            <p>Show weather</p>
            <ToggleSwitch checked={showWeather} onChange={handleShowWeatherToggle} label='' value='' />
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className={`wrapper-location ${assetDetails && 'asset-details'}`}>
        <div className='ui-button-toolbar'>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`ui-button-toolbar-button ${index === activeTabIndex ? 'ui-button-toolbar-button-active' : ''
                }`}
              onClick={() => setActiveTabIndex(index)}
            >
              {tab.label}
            </div>
          ))}
        </div>
        <div className='show-alram-traffic'>
          {!assetDetails && renderAdditionalBlocks()}
          {assetDetails && mapToggle()}
        </div>
      </div>
      <div className='tab-content'>
        {tabs.map((tab, index) => {
          return (
            <div key={index} style={{ display: index === activeTabIndex ? 'block' : 'none' }}>
              {React.createElement(tab?.component, { groupName, } as React.Attributes)}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AssetTabs;
