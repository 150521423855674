import React, { useState } from 'react';

import UITip from './UITip';

import ViewToggle1 from '../../../images/view toggle-1.png';
import ViewToggle2 from '../../../images/view toggle-2.png';
import Eye from '../../../images/eye.png';
import FilterFunnel from '../../../images/filter-funnel-01.png';
import InfoCircle from '../../../images/info-circle.png';
import SetpointOptimization from '../../../images/Setpoint optimization.png';
import Settings from '../../../images/gearIcon.png';
import Download from '../../../images/download-01.png';
import TableView from '../../../images/table-view-options.png';
import DotsVertical from '../../../images/dots-vertical.png';
import Hand from '../../../images/hand.png';
import Calendar from '../../../images/calendar.png';

const cardViewerTipDetails = [
  {
    key: 0,
    image: ViewToggle1,
    description: 'Standard Card',
  },
  {
    key: 1,
    image: ViewToggle2,
    description: 'Timeline view lets you quickly scan through many cards',
  },
  {
    key: 2,
    image: Eye,
    description: 'Toggle On/Off card views',
  },
  {
    key: 3,
    image: FilterFunnel,
    description: 'Filter card types in the card library',
  },
];

const cardViewerTipData = {
  id: 0,
  title: 'Card Viewer',
  image: InfoCircle,
  uiTipDetails: cardViewerTipDetails,
};

const assetControlBoardTipDetails = [
  {
    key: 0,
    image: SetpointOptimization,
    description: 'Add content to your dashboard',
  },
  {
    key: 1,
    image: Settings,
    description: 'View well control menu',
  },
  {
    key: 2,
    image: Download,
    description: 'View well configuration',
  },
  {
    key: 3,
    image: TableView,
    description: 'Switch between available dashboard views',
  },
  {
    key: 4,
    image: DotsVertical,
    description: 'More actions',
  },
];

const assetControlBoardTipData = {
  id: 1,
  title: 'Asset control board',
  image: InfoCircle,
  uiTipDetails: assetControlBoardTipDetails,
};

const cardViewerTimelineTipDetails = [
  {
    key: 0,
    image: Calendar,
    description:
      'Select the date range for the cards you’d like to view, and XSPOC will load all the cards pulled between those dates into the card library list',
  },
  {
    key: 1,
    image: Hand,
    description: 'Quickly move through the cards in the card library using the timeline bar by dragging and scrolling',
  },
  {
    key: 2,
    image: FilterFunnel,
    description: 'You can filter the selected cards by card type using the view options menu',
  },
];

const cardViewerTimelineTipData = {
  id: 0,
  title: 'Card viewer timeline view',
  image: InfoCircle,
  uiTipDetails: cardViewerTimelineTipDetails,
};

function UIShowTip() {
  const [currentTipIndex, setCurrentTipIndex] = useState<number>(0);
  const tipData = [assetControlBoardTipData, cardViewerTipData, cardViewerTimelineTipData];
  const [showUITip, setShowUITip] = useState<boolean>(false);

  function nextButtonClicked() {
    setCurrentTipIndex((prevIndex: number) => (prevIndex + 1) % tipData?.length);
  }

  function previousButtonClicked() {
    setCurrentTipIndex((prevIndex: number) => (prevIndex === 0 ? tipData?.length - 1 : prevIndex - 1));
  }

  function offButtonClicked() {
    setShowUITip(false); // Close the UITip
  }

  function dotsVerticalIconClicked() {
    setShowUITip(!showUITip);
    setCurrentTipIndex(0);
  }

  return (
    <>
      <img className="filter-icon" src={DotsVertical} onClick={dotsVerticalIconClicked} style={{ height: '30px' }} />

      {showUITip && (
        <UITip
          uiTipDetails={tipData[currentTipIndex].uiTipDetails}
          title={tipData[currentTipIndex].title}
          image={tipData[currentTipIndex].image}
          nextButtonClicked={nextButtonClicked}
          previousButtonClicked={previousButtonClicked}
          offButtonClicked={offButtonClicked}
        />
      )}
    </>
  );
}

export default UIShowTip;
