import Tooltip from '../tooltip/ToolTip';
import './UIIconButtonToolbar.css';

interface UIIconButtonToolbarButtonProps {
  key: number;
  name: string;
  active: boolean;
  image: any;
  tooltip: string;
}

interface UIIconButtonToolbarProps {
  uiIconButtons: UIIconButtonToolbarButtonProps[];
  buttonClicked: (id: number) => void;
}

function UIIconButtonToolbar(props: UIIconButtonToolbarProps) {
  const { uiIconButtons, buttonClicked } = { ...props };

  return (
    <div id="ui-icon-button-toolbar" className="ui-icon-button-toolbar">
      {uiIconButtons.map((uiIconButton: UIIconButtonToolbarButtonProps) => {
        const { key, name, active, image, tooltip } = { ...uiIconButton };

        return (
          <Tooltip content={tooltip} direction="top" key={key}>
            <button
              key={key}
              id={name}
              className={
                active
                  ? 'ui-icon-button-toolbar-button ui-icon-button-toolbar-button-active semi-bold'
                  : 'ui-icon-button-toolbar-button semi-bold'
              }
              onClick={() => buttonClicked(key)}
            >
              <img
                src={image}
                alt={name}
                className={
                  active
                    ? 'ui-icon-button-toolbar-icon ui-icon-button-toolbar-icon-active semi-bold'
                    : 'ui-icon-button-toolbar-icon semi-bold'
                }
              />
            </button>
          </Tooltip>
        );
      })}
    </div>
  );
}

export default UIIconButtonToolbar;
