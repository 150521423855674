import React from 'react';
import chevrondown from '../../../images/Carrot.png';
import tableViewOptions from '../../../images/table-view-options.png';
import './UIEyeAndFilterButtons.scss';

function UITableViewOptionsButton() {
  return (
    <button
      className="toolbar-eye-btn"
      onClick={() => {
        alert('Table View Option Button Clicked');
      }}
    >
      <img src={tableViewOptions} alt="table view option icon" />
      <img src={chevrondown} className="chevron-icon" />
    </button>
  );
}

export default UITableViewOptionsButton;
