import React, { useState, useEffect, useRef } from 'react';
import annotation_plus from '../../../../../images/expand-icon.svg';
import download from '../../../../../../src/images/download-icon.svg';
import './WellPerformanceTrends.scss';
import WellPerformanceTrendsModel from './WellPerformanceTrendsModel';
import { useAppSelector } from '../../../../../hooks/storeHooks';
import { WellPerformanceTrend } from '../../../model/WellPerformanceTrend';
import NoData from '../../no-data/NoData';
import Loader from '../../../../common/page-loader/ComponentLoader';
import WellPerormanceTrendDateRangeSelector from './well-performance-trend-date-range/WellPerormanceTrendDateRangeSelector';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import calendarIcon from '../../../../../images/calendar-icon.svg';
import thinArrow from '../../../../../images/thinArrow.svg';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { formateDate } from '../../../../common/DateRangeSelector/DefaultRanges';
import { AppUser } from '../../../../user/model/AppUser';
import { useAppDispatch } from '../../../../../hooks/storeHooks';
import { fetchGetWellPerformanceTrends } from '../../../DashboardSlice';
import './WellPerformanceTrends.scss';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import moment from 'moment-timezone';
import championx from '../../../../../../src/images/championxLogo.png';
import axios from 'axios';
import { capitalizeFirstInWord } from '../../../../../utilities/CommonFunctions';

interface WellPerformanceTrendsProps {
  groupName: string;
  showZindex: any;
}

const WellPerformanceTrends: React.FC<WellPerformanceTrendsProps> = ({ groupName, showZindex }) => {
  const wellPerformanceTrend = useAppSelector((state) => state.dashboard?.wellPerformanceTrend);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedItem, setSelecteditem] = useState('Downhole current');
  const dropdownRef = useRef<HTMLDivElement>(null);
  const trendsRef = useRef<HTMLDivElement>(null);
  const hElement = document.getElementById('main-root');

  const chartId = `chart-series-1-${Math.random()}`;
  const [showCalendar, setShowCalendar] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [data, setData] = useState<WellPerformanceTrend[]>([]);
  const [loading, setLoading] = useState(false);
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const dispatch = useAppDispatch();

  const root = useRef<any>(null);
  const currentUser = useAppSelector((state) => state?.user?.currentUser);
  const wellperformancechartdiv = useRef<HTMLDivElement | null>(null);

  const selectOptions = ['Downhole current', 'Frequency', 'Motor temperature', 'Intake temperature', 'Intake pressure'];

  const toggleDropdwon = () => {
    setIsSelectOpen(!isSelectOpen);
  };

  const handleItemClick = (item: string) => {
    setSelecteditem(item);
    setIsSelectOpen(!isSelectOpen);
  };

  const getLast7DaysDate = () => {
    const today = new Date();
    const last7Days = new Date(today);
    last7Days.setDate(today.getDate() - 6);
    return last7Days;
  };

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: getLast7DaysDate(),
    endDate: new Date(),
    key: 'selection',
  });


  const openModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsModalOpen(true); // Open the modal
    hElement && hElement?.classList.add('modal-open');
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    hElement && hElement?.classList.remove('modal-open');
  };
  const cancelTokenSource = useRef<ReturnType<typeof axios.CancelToken.source> | null>(null);

  const fetchData = async (filter: string) => {
    try {
      if (storedUser?.id && filter) {
        cancelTokenSource.current = axios.CancelToken.source();
        const resultAction = await dispatch(
          fetchGetWellPerformanceTrends({
            userId: storedUser.id,
            filter: filter,
            startDate: formateDate(selectedDateRange.startDate),
            endDate: formateDate(selectedDateRange.endDate),
            groupName: groupName,
            cancelToken: cancelTokenSource?.current?.token
          }),
        );
        if (fetchGetWellPerformanceTrends.fulfilled.match(resultAction)) {
          const controllerDetails = resultAction.payload as WellPerformanceTrend[];
          const transformedData = controllerDetails.map((item) => ({
            date: new Date(item.date).getTime(),
            open: item.open,
            high: item.high,
            low: item.low,
            close: item.close,
            median: item.median,
            outlierHigh: item.high + 0.2 * item.high,
            outlierLow: item.low - Math.abs(0.5 * item.low),
          }));
          setLoading(false);
          setData(transformedData);
        }
        if (fetchGetWellPerformanceTrends.rejected.match(resultAction)) {
          setLoading(false);
          setData([]);
        }
      }
    } catch (error) {
      console.error("error", error);
    }
  };


  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    },);
    fetchData(capitalizeFirstInWord(selectedItem));

    return () => {
      cancelTokenSource.current?.cancel('Operation canceled by the user.');
    };
  }, [selectedItem, selectedDateRange.startDate, selectedDateRange.endDate, groupName]);

  function setToStartOfDay(date: any) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
  }
  function setToEndOfDay(date: any) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
  }
  // Ensure selectedDateRange.startDate is at the start of the day
  const startDate = setToStartOfDay(selectedDateRange.startDate);
  // Ensure selectedDateRange.endDate is at the end of the day
  const endDate = setToEndOfDay(selectedDateRange.endDate);

  useEffect(() => {
    if (data?.length > 0) {
      if (wellperformancechartdiv.current) {
        root.current = am5.Root.new(wellperformancechartdiv.current);
        // const root = am5.Root.new(chartId);
        root.current.setThemes([am5themes_Animated.new(root.current)]);

        
   // Create a main container to hold both the chart and the legend
   const mainContainer = root.current.container.children.push(
    am5.Container.new(root.current, {
      layout: root.current.verticalLayout,
      width: am5.percent(100),
      height: am5.percent(100),
    })
  );

  // Create the chart container
  const chartContainer = mainContainer.children.push(
    am5.Container.new(root.current, {
      width: am5.percent(100),
      height: am5.percent(100), // Adjust height to leave space for the legend
    })
  );

        const chart = chartContainer.children.push(
          am5xy.XYChart.new(root.current, {
            focusable: true,
            panX: false,
            panY: false,
            wheelX: 'none',
            wheelY: 'none',
            pinchZoomX: false,
            pinchZoomY: false,
            layout: root.current.verticalLayout,
            maxTooltipDistance: 0,
            pinchZoomDisabled: true,
          }),
        );

        const xAxis = chart.xAxes.push(
          am5xy.DateAxis.new(root.current, {
            groupData: false,
            extraMax: 0,
            extraMin: 0,
            min: startDate.getTime(),
            max: endDate.getTime(),
            strictMinMax: true,
            baseInterval: { timeUnit: 'day', count: 1 },
            renderer: am5xy.AxisRendererX.new(root.current, {
              minGridDistance: 50,
            }),
            tooltip: am5.Tooltip.new(root.current, {}),
          }),
        );

        const yAxis = chart.yAxes.push(
          am5xy.ValueAxis.new(root.current, {
            // max: adjustedMaxValue,
            renderer: am5xy.AxisRendererY.new(root.current, {}),
          }),
        );

        xAxis.get('renderer').labels.template.set('paddingLeft', 20);
        xAxis.get('renderer').labels.template.set('paddingTop', 20);

        const series = chart.series.push(
          am5xy.CandlestickSeries.new(root.current, {
            name: 'Series',
            xAxis: xAxis,
            yAxis: yAxis,
            openValueYField: 'open',
            highValueYField: 'high',
            lowValueYField: 'low',
            valueYField: 'close',
            valueXField: 'date',
          }),
        );

        series.columns.template.setAll({
          width: am5.percent(10),
        });

        series.columns.template.states.create('riseFromOpen', {
          fill: am5.color(0x60bfda),
          stroke: am5.color(0x60bfda),
        });
        series.columns.template.states.create('dropFromOpen', {
          fill: am5.color(0x60bfda),
          stroke: am5.color(0x60bfda),
        });

        series.columns.template.setAll({
          tooltipText:
            'Date: {valueX}\nQuartile1: {openValueY}\nLower Fence: {lowValueY}\nUpper Fence: {highValueY}\nQuartile3: {valueY},\nMedian: {median}',
        });

        series.columns.template.adapters.add('tooltipText', function (text: any, target: any) {
          const dataItem = target.dataItem;
          if (dataItem) {
            const date = new Date(dataItem.get('valueX'));
            const formattedDate = date.toLocaleDateString(undefined, { 
              weekday: 'long', 
              year: 'numeric', 
              month: 'short', 
              day: 'numeric' 
            });
            return text.replace('{valueX}', formattedDate);
          }
          return text;
        });

        // mediana series
        const medianaSeries = chart.series.push(
          am5xy.StepLineSeries.new(root.current, {
            stroke: am5.color('#001023'),
            xAxis: xAxis,
            yAxis: yAxis,

            valueYField: 'median',
            valueXField: 'date',
            noRisers: true,
            stepWidth: am5.percent(10),
          }),
        );
        medianaSeries.strokes.template.setAll({
          strokeWidth: 3,
        });

        const topSeries = chart.series.push(
          am5xy.StepLineSeries.new(root.current, {
            stroke: am5.color(0x60bfda),
            xAxis: xAxis,
            yAxis: yAxis,
            //  startLocation : 0.2,
            // endLocation :0.8,
            valueYField: 'high',
            valueXField: 'date',
            noRisers: true,
            stepWidth: am5.percent(5),
          }),
        );
        topSeries.strokes.template.setAll({
          strokeWidth: 2,
        });
        const bottomSeries = chart.series.push(
          am5xy.StepLineSeries.new(root.current, {
            stroke: am5.color(0x60bfda),
            xAxis: xAxis,
            yAxis: yAxis,
            //  startLocation : 0.2,
            //   endLocation :0.8,
            valueYField: 'low',
            valueXField: 'date',
            noRisers: true,
            stepWidth: am5.percent(5),
          }),
        );
        bottomSeries.strokes.template.setAll({
          strokeWidth: 2,
        });

        const outlierSeries = chart.series.push(
          am5xy.LineSeries.new(root.current, {
            name: 'Outlier High',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'outlierHigh',
            valueXField: 'date',
            tooltip: am5.Tooltip.new(root.current, {
              labelText: 'Outlier High',
              // labelText: "Outlier High {valueY}"
            }),
            fill: am5.color(0x001023),
            stroke: am5.color(0x4a5463),
          }),
        );

        outlierSeries.strokes.template.set('forceHidden', true);

        // Actual bullet
        outlierSeries.bullets.push(function () {
          const bulletCircle = am5.Circle.new(root.current, {
            radius: 3,
            fill: am5.color('#F4FBFC'),
          });
          return am5.Bullet.new(root.current, {
            sprite: bulletCircle,
          });
        });

        //outliers 2
        const outlierSeries2 = chart.series.push(
          am5xy.LineSeries.new(root.current, {
            name: 'Outlier Low',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'outlierLow',
            valueXField: 'date',
            tooltip: am5.Tooltip.new(root.current, {
              // labelText: "Outlier Low {valueY}"
              labelText: 'Outlier Low',
            }),
            fill: am5.color(0x001023),
            stroke: am5.color(0x4a5463),
          }),
        );

        outlierSeries2.strokes.template.set('forceHidden', true);

        // Actual bullet
        outlierSeries2.bullets.push(function () {
          const bulletCircle = am5.Circle.new(root.current, {
            radius: 3,
            fill: am5.color('#F4FBFC'),
          });
          return am5.Bullet.new(root.current, {
            sprite: bulletCircle,
          });
        });
        series.data.setAll(data);

        medianaSeries.data.setAll(data);
        topSeries.data.setAll(data);
        bottomSeries.data.setAll(data);
        outlierSeries.data.setAll(data);
        outlierSeries2.data.setAll(data);

        chart.set(
          'cursor',
          am5xy.XYCursor.new(root.current, {
            behavior: 'none',
            xAxis: xAxis,
          }),
        );

        const tooltipX: any = am5.Tooltip.new(root.current, {
          themeTags: ['axis'],
          layer: 0, // Set the layer for the entire tooltip, including the label
        });

        // tooltipX.get('background').set('layer', 0);

        const tooltipY: any = am5.Tooltip.new(root.current, {
          themeTags: ['axis'],
          layer: 0, // Set the layer for the entire tooltip, including the label
        });

        // tooltipY.get('background').set('layer', 0);

        xAxis.set('tooltip', tooltipX);
        yAxis.set('tooltip', tooltipY);

        // Set the labels color for x and y axis
        xAxis.get('renderer').labels.template.set('fill', am5.color('#fff'));
        yAxis.get('renderer').labels.template.set('fill', am5.color('#fff'));

        series.appear(1000);
        chart.appear(1000, 100);

        return () => {
          root.current.dispose();
        };
      }
    }
  }, [data]);

  useEffect(() => {
    if (showCalendar) {
      showZindex();
    }
  }, [showCalendar]);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
    if (trendsRef.current && !trendsRef.current.contains(event.target as Node)) {
      setIsSelectOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSlideOutClick = (e: any) => {
    if (e.target.id === 'range-calendar-input') return;
    if (showCalendar) {
      setShowCalendar(!showCalendar);
    }
  };
  const ref = useDetectClickOutside({ onTriggered: handleSlideOutClick });

  function formatDateRange(startDate: any, endDate: any) {
    const formatDate = (date: any) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${month}/${day}/${year}`;
    };

    const formattedStartDate = startDate ? formatDate(new Date(startDate)) : '';
    const formattedEndDate = endDate ? formatDate(new Date(endDate)) : '';

    return `${formattedStartDate} - ${formattedEndDate}`;
  }

  const formattedDateRange = formatDateRange(selectedDateRange.startDate, selectedDateRange.endDate);

  const printWidgetAsPDF = () => {
    if (root.current) {
      const exporting = am5plugins_exporting.Exporting.new(root.current, {
        menu: am5plugins_exporting.ExportingMenu.new(root.current, {}),
      });
      Promise.all([exporting.getPdfmake(), exporting.export('png')]).then((res) => {
        const pdfMake = res[0];
        const str = moment().format('MMM-DD-YYYY hh:mm:ss A');
        const doc = {
          pageSize: 'A4',
          pageOrientation: 'portrait',
          pageMargins: [30, 30, 30, 30],
          footer: {
            columns: [
              {
                text: `Generated By: ${currentUser?.firstName || ''} ${currentUser?.lastName || ''}`,
                alignment: 'left',
                width: '50%',
                bold: true,
                margin: [10, 10],
                fontSize: 9,
                color: 'black',
                decoration: 'underline',
              },
              {
                text: 'Generated on : ' + str,
                alignment: 'right',
                width: '50%',
                bold: true,
                margin: [10, 10],
                fontSize: 9,
                color: 'black',
                decoration: 'underline',
              },
            ],
          },
          content: [] as any[],
          styles: {
            tblheader: {
              fontSize: 9.5,
              color: 'white',
              fillColor: '#01485e',
              alignment: 'center',
            },
          },
        };

        const rect = {
          type: 'rect',
          x: -32,
          y: -20, //could be negative to account for padding
          w: 450,
          h: 25,
          linearGradient: ['#01485e', '#01485e'],
        };
        const rectHead = {
          type: 'rect',
          x: -32,
          y: -20, //could be negative to account for padding
          w: 150,
          h: 25,
          linearGradient: ['#484848', '#484848'],
        };
        doc.content.push({
          columns: [
            {
              stack: [
                { canvas: [rect] },
                {
                  columns: [
                    {
                      width: rect.w / 2,
                      noWrap: false, //clip at with by wrapping to next line and...
                      maxHeight: rect.h, //don't show the wrapped text
                      image: championx,
                      fit: [55, 55],
                      margin: [5.5, 7.5, 3],
                      background: 'white',
                    },
                    {
                      text: 'Landing Page',
                      width: rect.w / 2,
                      alignment: 'left',
                      bold: true,
                      margin: [35, 5],
                      fontSize: 13,
                      color: 'white',
                    },
                  ],
                  relativePosition: {
                    x: rect.x,
                    y: -rect.h,
                  },
                },
              ],
            },
            {
              stack: [
                { canvas: [rectHead] },
                {
                  columns: [
                    {
                      width: rectHead.w,
                      noWrap: false, //clip at with by wrapping to next line and...
                      maxHeight: rect.h, //don't show the wrapped text
                      text: 'LOOKOUT',
                      color: 'white',
                      bold: true,
                      alignment: 'right',
                      'font-size': 10,
                    },
                  ],
                  relativePosition: {
                    x: -80,
                    y: -20,
                  },
                },
              ],
            },
          ],
        });
        doc.content.push({
          text: ' ',
          width: '100%',
        });

        doc.content.push(
          {
            text: 'Well Performance Trends',
            width: '*',
            alignment: 'center',
            fontSize: 18,
            margin: [0, 0, 0, 5],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 175,
                y1: 0,
                x2: 362,
                y2: 0,
                lineWidth: 1,
              },
            ],
            margin: [0, 0, 0, 15],
          },
        );

        doc.content.push({
          text: formattedDateRange,
          width: '100%',
          alignment: 'center',
          fontSize: 13,
          margin: [0, 0, 0, 15],
        });

        doc.content.push({
          image: res[1],
          alignment: 'center',
          width: 450,
          height: 180,
          margin: [0, 0, 0, 20],
        });

        const fileName = 'Lookout-Landing_Page_WellPerformanceTrends_' + str + '.pdf';

        pdfMake.createPdf(doc).download(fileName);
      });
    }
  };
 

  return (
    <>
      <div className='table-header-section pie-section well-performance-trends'>
        <div className='title'>Well performance trends</div>
        <div className='header-icon'>
          <button
            className='expand-btn cancelSelectorName'
            onClick={openModal}
            style={{ cursor: 'pointer', zIndex: 0 }}
          >
            <img src={annotation_plus} alt='Expand' className='img-border' />
          </button>
          <div className='dropdown-container' ref={dropdownRef}>
            <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              <span className='dot'></span>
              <span className='dot'></span>
              <span className='dot'></span>
            </button>
            {isDropdownOpen && (
              <div className='dropdown-options'>
                <button>Actions</button>
                <button
                  className={`btn btn-default cancelSelectorName ${
                    wellPerformanceTrend === null || wellPerformanceTrend?.length === 0 ? 'btn-disabled' : ''
                  }`}
                  onClick={printWidgetAsPDF}
                >
                  <img src={download} alt='icon' className='btn-icon' />
                  Download PDF
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <hr />

      <>
        <div className='well-performance-trends__header-container'>
          <div className='well-performance-trends__dropdown' ref={trendsRef}>
            <div className='well-performance-trends__dropdown-input-container' onClick={toggleDropdwon}>
              <input type='text' className='well-performance-trends__dropdown-input' value={selectedItem} readOnly />
              <img
                src={thinArrow}
                alt={isSelectOpen ? 'Up Arrow' : 'Down Arrow'}
                className={`mr-3 ${isSelectOpen ? 'rotate-180' : ''}`}
              />
            </div>
            {isSelectOpen && (
              <ul className='well-performance-trends__dropdown-list'>
                {selectOptions.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => handleItemClick(item)}
                    className='well-performance-trends__dropdown-item'
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className='well-performance-trends__input-group'>
            <img src={calendarIcon} alt='Range Calendar' />
            <input
              id='range-calendar-input'
              className='input-date'
              value={`${formateDate(selectedDateRange.startDate)} - ${formateDate(selectedDateRange.endDate)}`}
              onClick={() => setShowCalendar(!showCalendar)}
            />
          </div>
        </div>
        <div ref={ref}>
          {showCalendar && (
            <WellPerormanceTrendDateRangeSelector
              setShowCalendar={setShowCalendar}
              setSelectedDateRange={setSelectedDateRange}
            />
          )}
        </div>
        <div id='wellPerformanceTrend-container' className='wellPerformanceChartSection'>
          {loading ? (
            // <div className='well-performance-loader'>
              <Loader />
            // </div>
          ) : data?.length > 0 ? (
            <div id={chartId} ref={wellperformancechartdiv} style={{ height: '100%', width: '100%' }}></div>
          ) : (
            <NoData heading='No data found' />
          )}
        </div>
      </>

      <WellPerformanceTrendsModel
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        heading='Well performance trends'
        setIsDropdownOpen={setIsDropdownOpen}
        dropdownRef={dropdownRef}
        selectedItem={selectedItem}
        setSelecteditem={setSelecteditem}
        selectedDateRange={selectedDateRange}
        setSelectedDateRange={setSelectedDateRange}
        data={data}
        loading={loading}
      />
    </>
  );
};

export default WellPerformanceTrends;
