import React, { useEffect, useState } from 'react';
import DashboardHeader from './components/dashboard-header/DashboardHeader';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import WellByStatus from './components/graph/well-by-status/WellByStatusGraph';
import ShutdownByReasonGraph from './components/graph/shutdown-by-reason/ShutdownByReasonGraph';
import WellPerformanceTrends from './components/graph/well-performance-trends/WellPerformanceTrends';
import WellProductionTrends from './components/graph/well-production-trends/WellProductionTrends';
import './Dashboard.scss';
import PerformanceSnapshot from './components/performance-snapshot/PerformanceSnapshot';
import AssetLocation from './components/asset-location/AssetLocation';
import UptimeDowntime from './components/graph/uptime-downtime/UptimeDowntimeGraph';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/storeHooks';
import { AppUser } from '../user/model/AppUser';
import { fetchGetPerformanceSnapshot } from './DashboardSlice';

interface IntialGraphSetup {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minH: number;
  minW: number;
}

const GroupDashboard: React.FC = () => {
  const initialLayout: IntialGraphSetup[] = [
    { i: 'Well By Status', x: 0, y: 4, w: 4, h: 4, minH: 1.7, minW: 2.5  }, // Adjusted y value
    { i: 'Uptime Downtime', x: 4, y: 4, w: 4, h: 4, minH: 1.7, minW: 2.5  }, // Adjusted y value
    { i: 'Shutdown By Reason', x: 8, y: 4, w: 4, h: 4, minH: 1.7, minW: 2.5  }, // Adjusted y value
    { i: 'Well Performance Trends', x: 0, y: 8, w: 6, h: 4, minH:1.7, minW: 3.7 }, // Adjusted y value
    { i: 'Well Production Trends', x: 6, y: 8, w: 6, h: 4, minH:1.7, minW: 3.7 }, // Adjusted y value
  ];

  const [layout, setLayout] = useState(initialLayout);
  const [zIndex, setZIndex] = useState(9);
  const [dynamicWidth, setDynamicWidth] = useState(calculateDynamicWidth);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const groupNameSet = location?.state?.groupNameSet ?? sessionStorage.getItem("selectedGroupName")
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;

  useEffect(() => {
    if (storedUser) {
      dispatch(fetchGetPerformanceSnapshot({ userId: storedUser?.id, groupName: groupNameSet }));
    }
  }, [groupNameSet]);

  function calculateDynamicWidth() {
    return window.innerWidth - 130;
  }
  useEffect(() => {
    const handleResize = () => {
      // Update the width based on the calculated value of 100vw - 80px
      setDynamicWidth(calculateDynamicWidth());
    };
    // Initial call to set the initial width
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  const onLayoutChange = (newLayout: IntialGraphSetup[]) => {
    setLayout(newLayout);
  };

  const handlePerformanceZindex = (item: any) => {
    if (item.i.startsWith('Well Performance Trends')) {
      setZIndex(15);
    }
  };

  const handleProductionZindex = (item: any) => {
    if (item.i.startsWith('Well Production Trends')) {
      setZIndex(9);
    }
  };

  return (
    <>
      <div className='main-content'>
        <DashboardHeader showContent={false} groupName={groupNameSet} />
        <div className='w-100 pb-0 p-5'>
          <div className='performanceSnapshot'>
            <PerformanceSnapshot />
            <div className='p-2 grid-container'>
              <GridLayout
                className='layout'
                layout={layout}
                draggableCancel=".cancelSelectorName"
                cols={12}
                rowHeight={75}
                onLayoutChange={onLayoutChange}
                isDraggable={true}
                width={dynamicWidth}
                useCSSTransforms={false}
              >
                {layout.map((item) => (
                  <div key={item.i} className='grid-item dashboard-graphs-wrapper' data-grid={item} style={{ zIndex: item.i.startsWith('Well Performance Trends') ? zIndex : 9 }}>
                    {item.i.startsWith('Well By Status') && <div className='grid-item-text h-full graph-card'> <WellByStatus groupName={groupNameSet} /></div>}
                    {item.i.startsWith('Uptime Downtime') && <div className='grid-item-text h-full graph-card'> <UptimeDowntime groupName={groupNameSet} /></div>}
                    {item.i.startsWith('Shutdown By Reason') && <div className='grid-item-text h-full graph-card'> <ShutdownByReasonGraph groupName={groupNameSet} /></div>}
                    {item.i.startsWith('Well Performance Trends') && <div className='grid-item-text trends-input h-full graph-card'> <WellPerformanceTrends groupName={groupNameSet} showZindex={() => handlePerformanceZindex(item)} /></div>}
                    {item.i.startsWith('Well Production Trends') && <div className='grid-item-text h-full graph-card'> <WellProductionTrends groupName={groupNameSet} showZindex={() => handleProductionZindex(item)} /></div>}
                    
                  </div>
                ))}
              </GridLayout>
            </div>
          </div>
        </div>
        <div className='w-100 p-5'>
          <AssetLocation groupName={groupNameSet} />
        </div>
      </div>
    </>
  );
};

export default GroupDashboard;
