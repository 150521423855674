import React from 'react';
import download from '../../../images/download-01.png';
import './UIEyeAndFilterButtons.scss';

function UIDownloadButton() {
  return (
    <button
      className="download-btn"
      onClick={() => {
        alert('Download Button Clicked');
      }}
    >
      <img src={download} alt="Download icon" />
    </button>
  );
}

export default UIDownloadButton;
