import Loader from '../../../../../images/asset-scan-loader.svg'

interface ScanLoaderModalProps {
    wellName: string | undefined
}

const ScanLoaderModal: React.FC<ScanLoaderModalProps> = ({ wellName }) => {
    console.log("wellName", wellName)
    return (
        <>
            <div className='modal-overlay scan-loader-modal'>
                <div className='modal'>
                    <div className='flex flex-row flex-grow profile-block'>
                        <div className='notify-card'>
                            <div className='heading-content'>
                                <div className="heading-1">
                                    Scanning asset...
                                </div>
                                <div className="asset-name">
                                    {wellName}
                                </div>
                            </div>
                            <div className="loading-content">
                                <div className="icon">
                                    <img src={Loader} alt='' />
                                </div>
                                <div className="label">
                                    Scanning...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* )} */}
        </>
    );
};

export default ScanLoaderModal;
