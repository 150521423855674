import UILabelItem, { UILabelItemProps } from '../UILabelItem/UILabelItem';
import './AnalysisInputOutput.css';

export interface AnalysisInputOutputProps {
  headerText: string;
  values: UILabelItemProps[] | undefined;
}

const AnalysisInputOutput = (props: AnalysisInputOutputProps) => {
  return (
    <div className="analysis-result-item-container  flex-grow flex-shrink">
      <div className="analysis-result-item-header-container">
        <label className="analysis-result-item-header-text">{props.headerText}</label>
      </div>
      <div className="analysis-input-output-body-container">
        {props?.values?.map((x) => <UILabelItem key={x.id} id={x.id} title={x.title} value={x.value} />)}
      </div>
    </div>
  );
};

export default AnalysisInputOutput;
