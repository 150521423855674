import axios from 'axios';
import { getAPIBaseURL } from '../../utilities/BaseURLUtility';

export interface FetchCardDatesResponseValues {
  date: string;
  cardTypeId: string;
  cardTypeName: string;
}

export interface FetchCardDatesResponse {
  values: FetchCardDatesResponseValues[];
}

const fetchCardDatesAsync = async (assetId: string) => {
  try {
  const response = await axios.get(`${getAPIBaseURL()}/CardDate/${assetId}`);    
    return response.data as FetchCardDatesResponse;
  } catch (error) {
    console.error('Error fetching rod lift analysis card dates', error);
  }
};

export interface AnalysisInputOutputResponseValues {
  Id: string;
  Name: string;
  DisplayName: string;
  Value: string;
  DisplayValue: string;
  DataTypeId: number;
  MeasurementAbbreviation: string;
  SourceId: number;
}

export interface FetchAnalysisResponseValues {
  Input: AnalysisInputOutputResponseValues[];
  Output: AnalysisInputOutputResponseValues[];
}

export interface FetchAnalysisResponse {
  Values: FetchAnalysisResponseValues;
}

const fetchAnalysisAsync = async (assetId: string, dateTime: string) => {
  try {
    const response = await axios.get(`${getAPIBaseURL()}/RodLiftAnalysis/${assetId}/${dateTime}`);

    return response.data as FetchAnalysisResponse;
  } catch (error) {
    console.error('Error fetching rod lift analysis', error);
  }
};

export { fetchCardDatesAsync, fetchAnalysisAsync };
