import React, { useEffect, useState } from 'react';
import EquipmentList from './components/EquipmentList';
import EquipmentDetails from './components/EquipmentDetails';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { AppUser } from '../../../user/model/AppUser';
import { fetchEquipment, fetchEquipmentDetails, fetchEquipmentVolume } from './EquipmentSlice';
import ESP from '../../../../images/ESP.svg';
import './Equipment.scss';
import Loader from '../../../common/page-loader/ComponentLoader';

interface AssetProps {
  assetId: string | undefined;
  assetName: string | undefined;
}

export interface EquipmentItem {
  key: string;
  value: string;
}

export interface EquipmentDetail {
  description: string;
  title: string;
  minimumDailyVolume: string;
  maximumDailyVolume: string;
  idealDailyVolume: string;
  housingPressureLimit: string;
  pullRating: string;
  oracleItemNumber: string;
}

const Equipment: React.FC = () => {
  const selectedAsset = useAppSelector((state) => state.assetGroups);
  const dispatch = useAppDispatch();
  const storedUserData = localStorage.getItem('loggedInUser');
  const [selectedEquipment, setSelectedEquipment] = useState<string | null>(null);
  const [selectedEquipmentIndex, setSelectedEquipmentIndex] = useState<number | null>(null);
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const [currentAsset, setCurrentAsset] = useState<AssetProps>({
    assetName: '',
    assetId: '',
  });
  const [equipmentNames, setEquipmentNames] = useState<EquipmentItem[]>([]);
  const [selectedEquipmentDetail, setSelectedEquipmentDetail] = useState<EquipmentDetail[]>([]);
  const [selectedDetail, setSelectedDetail] = useState<EquipmentDetail | null>(null);
  const [loading, setLoading] = useState(false);
  const [volumeDetails, setVolumeDetails] = useState();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (selectedAsset.selectedAssetName) {
      const data = {
        assetId: selectedAsset.selectedAssetId,
        assetName: selectedAsset?.selectedAssetName,
        industryApplicationId: 0,
      };
      setCurrentAsset(data);
    }
  }, [selectedAsset?.selectedAssetName]);

  useEffect(() => {
    // const assetId = selectedAsset?.selectedAssetId;
    const assetName = selectedAsset?.selectedAssetName
    if (storedUser?.id && assetName) {
      // setLoading(true);
      console.log('Well name on load ' + selectedDetail);
      dispatch(fetchEquipment({ userId: storedUser.id, assetName: assetName }))
        .unwrap()
        .then((response: EquipmentItem[]) => {
          // setLoading(false);
          setEquipmentNames(response);
        })

        .catch((error) => {
          console.error('Failed to fetch equipment:', error);
          // setLoading(false);
        });
    }
  }, [dispatch, storedUser?.id, currentAsset.assetId, currentAsset.assetName]);

  const handleEquipmentSelect = (index: number, equipmentId: string, equipmentName: string) => {
    console.log('handleEquipmentSelect called');
    const wellName = selectedAsset?.selectedAssetName ?? '';
    setLoading(true);
    setSelectedEquipmentDetail([]);
    setSelectedEquipment(equipmentName);
    setSelectedEquipmentIndex(index);
    if (storedUser?.id && wellName && equipmentId) {
      dispatch(
        fetchEquipmentDetails({
          userId: storedUser.id,
          wellName: wellName,
          equipmentId,
        }),
      )
        .unwrap()
        .then((response: EquipmentDetail[]) => {
          setSelectedEquipmentDetail(response);
          setLoading(false);
          setIsError(false);
        })
        .catch((error) => {
          setLoading(false);
          setIsError(true);
          if (error.response && error.response.status === 404) {
            setIsError(true);
          }
        });
    }
  };

  useEffect(() => {
    selectedEquipmentDetail.forEach((detail) => {
      console.log(detail);
    });
  }, [selectedEquipmentDetail]);

  const handleDetailSelect = (detail: EquipmentDetail) => {
    console.log(' HandleDetailSelect called for 3rd UI part ');
    setSelectedDetail(detail);
    console.log("Selected detail's oracleItemNumber:", detail.oracleItemNumber);
    if (detail.oracleItemNumber) {
      dispatch(fetchEquipmentVolume({ oracleItemNumber: detail.oracleItemNumber }))
        .unwrap()
        .then((volumeDetails) => {
          console.log('Volume details:', volumeDetails);
          setVolumeDetails(volumeDetails);
        })
        .catch((error) => {
          console.error('Failed to fetch volume details:', error);
        });
    }
  };
  if (isError) {
    console.log('Rendering No Data Found');
  } else {
    console.log('Rendering Equipment Details');
  }

  return (
    <>
      <div className='equipment-container flex flex-row flex-grow w-100 py-6 px-8'>
        <div className='card profile-card m-0'>
          <span className='title equipment-color'>ESP equipment</span>
          <div className='equipment-main-container'>
            {equipmentNames?.length > 0 && (
              <div className='equipment-list'>
                <EquipmentList
                  selectedEquipmentIndex={selectedEquipmentIndex}
                  onSelectEquipment={handleEquipmentSelect}
                  equipmentItems={equipmentNames}
                  currentAsset={currentAsset}
                />
              </div>
            )}
            <div className='equipment-details'>
              {loading ? (
                <div className='equipment-loader flex items-center justify-content loader-margin'>
                  <Loader />
                </div>
              ) : selectedEquipmentDetail?.length > 0 && !isError ? (
                <EquipmentDetails
                  selectedEquipment={selectedEquipment}
                  equipmentDetails={selectedEquipmentDetail}
                  volumeDetails={volumeDetails}
                  onSelectDetail={handleDetailSelect}
                />
              ) : (
                <div className='error-image-container'>
                  <img src={ESP} alt='No Data Found' />
                  <p className='main-text'>No equipment found</p>
                  <p className='normal-text'>Your equipment will show here once it is installed.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Equipment;
