import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../store';
import { getAPIBaseURL } from '../../utilities/BaseURLUtility';
import { Notification } from '../../models/Notification';

interface State {
  loading: boolean;
  error: string | undefined;
  notifications: Array<Notification>;
}

// Async thunk to fetch the initial state data
export const fetchInitialNotificationAsync = createAsyncThunk(
  'notificationsSlice/fetchInitialNotification',
  async () => {
    const data = await fetchInitialNotification();
    if (data && data.Values) {
      return data.Values;
    }
    return [];
  },
);

const initialState: State = {
  loading: false,
  error: undefined,
  notifications: [],
};

export const notificationsSlice = createSlice({
  name: 'notificationsList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitialNotificationAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInitialNotificationAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = action.payload;
      })
      .addCase(fetchInitialNotificationAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const fetchInitialNotification = async () => {
  try {
    const response = await axios.get(`${getAPIBaseURL()}/notifications`);
    return response.data;
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return {};
  }
};
export const notifications = (state: RootState) => state.notificationsList;

export default notificationsSlice.reducer;
