import './XDAnalysis.css';

export interface XDAnalysisProps {
  headerText: string;
  value: string;
}

const XDAnalysis = (props: XDAnalysisProps) => {
  return (
    <div className="analysis-result-item-container xd-analysis-container  flex-grow flex-shrink">
      <div className="analysis-result-item-header-container">
        <label className="analysis-result-item-header-text">{props.headerText}</label>
      </div>
      <div className="xd-analysis-text-container">
        <textarea className="xd-analysis-textarea" readOnly value={props.value} />
      </div>
    </div>
  );
};

export default XDAnalysis;
