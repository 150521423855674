import React from 'react';
import eye from '../../../images/eye.png';
import chevrondown from '../../../images/Carrot.png';
import './UIEyeAndFilterButtons.scss';

function UIEyeButton() {
  return (
    <button
      className="toolbar-eye-btn"
      onClick={() => {
        alert('Eye Button Clicked');
      }}
    >
      <img src={eye} alt="eye icon" />
      <img src={chevrondown} className="chevron-icon" />
    </button>
  );
}

export default UIEyeButton;
