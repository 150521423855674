import React, { useLayoutEffect, useRef } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import NoData from '../../../dashboard/components/no-data/NoData';
import Loader from '../../../common/page-loader/ComponentLoader';

interface ChartDataProps {
  date: string;
  name: string;
  value: string;
}

interface LineChartProps {
  chartName: string;
  index: number;
  unitOfMeasure: string;
  chartData: ChartDataProps[] | null,
  selectedDate: {
    startDate: Date | null,
    endDate: Date | null
  }
  loadingIcon: boolean;
  setTrendsMinMaxValue: (value: any) => void;
}

const chartColor: { trendName: string; color?: string; color1?: string }[] = [
  { trendName: "Drive voltage", color: "#12B76A", color1: "#6CE9A6" },
  { trendName: 'Intake temperature', color: "#F04438", color1: "#FDA29B" },
  { trendName: 'Intake pressure', color: "#004EB3", color1: "#4294FF" },
  { trendName: 'Motor current', color: "#FB743C", color1: "#FCA682" },
  { trendName: 'Tubing pressure', color: "#0094BD", color1: "#86CFE3" },
  { trendName: 'Vibration X', color: "#3A7E73", color1: "#52AFA0" },
  { trendName: 'Vibration Y', color: "#FFD072", color1: "#FFE5B2" },
  { trendName: 'System frequency', color: "#FEC84B", color1: "#FEF0C7" },
  { trendName: 'DC bus voltage', color: "#006DFA", color1: "#89BCFF" },
  { trendName: 'Motor temperature', color: "#F97066", color1: "#FECDCA" },
  { trendName: 'Casing pressure', color: "#6BBBAE", color1: "#9CD2C9" },
  { trendName: 'System RPM', color: "#B8C5CC", color1: "#E7ECEE" },

];
interface ChartDataContext {
  date: number;
  value: number;
  time: string;
}


let chartsToSync: am5xy.XYChart[] = [];

const LineChart: React.FC<LineChartProps> = ({ chartName, unitOfMeasure, index, chartData, selectedDate, loadingIcon, setTrendsMinMaxValue }) => {
  const chartIdRef = useRef(`chart-${chartName}-${index}-${Math.random()}`);
  const chartId = chartIdRef.current;

  function setToStartOfDay(date: any) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
  }
  function setToEndOfDay(date: any) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
  }
  // Ensure selectedDateRange.startDate is at the start of the day
  const startDate = setToStartOfDay(selectedDate.startDate);
  // Ensure selectedDateRange.endDate is at the end of the day
  const endDate = setToEndOfDay(selectedDate.endDate);


  function syncAxes(targetChart: am5xy.XYChart) {
    const targetAxis = targetChart.xAxes.getIndex(0);
    if (targetAxis && targetAxis._skipSync !== true) {
      const start = targetAxis.get("start");
      const end = targetAxis.get("end");
      am5.array.each(chartsToSync, function (chart) {
        if (chart !== targetChart) {
          const axis = chart.xAxes.getIndex(0);
          if (axis) {
            axis._skipSync = true;
            axis.setAll({
              start: start,
              end: end
            });
            axis._skipSync = false;
          }
        }
      });
    }
  }

  useLayoutEffect(() => {
    if (!chartData) return;

    const root = am5.Root.new(chartId);

    root.setThemes([
      am5themes_Dark.new(root)
    ]);
    const minValue = Math.min(...chartData.map(item => parseFloat(item.value)));
    const maxValue = Math.max(...chartData.map(item => parseFloat(item.value)));
    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: true,
      panY: true,
      wheelX: 'none',
      wheelY: 'zoomX',
      pinchZoomX: true,
      paddingTop: 30,
    }));

    chartsToSync.push(chart);
    const xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      maxDeviation: 0.1,
      groupData: false,
      extraMax: 0,
      extraMin: 0,
      strictMinMax: true,
      baseInterval: { timeUnit: 'minute', count: 1 },
      renderer: am5xy.AxisRendererX.new(root, {
      })
    }));

    if (selectedDate && selectedDate?.startDate)
      xAxis.set('min', new Date(startDate).getTime())
    if (selectedDate && selectedDate?.endDate)
      xAxis.set('max', new Date(endDate).getTime())

    xAxis.get('renderer').labels.template.set('paddingTop', 15);

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0.1,
      // min: 0,
      // max: maxValue,
      // extraMin: 0.1,
      // extraMax: 0.1,
      extraMin: 0,
      extraMax: 0,
      renderer: am5xy.AxisRendererY.new(root, {
      })
    }));


    const tooltip = am5.Tooltip.new(root, {
      getFillFromSprite: false,
      autoTextColor: false,
      dy: -10,
      dx: 0
    });


    tooltip?.get("background")?.setAll({
      fill: am5.color("#001023"),
      stroke: am5.color("#4A5463"),
    });
    tooltip.label.setAll({
      fill: am5.color("#F7F9F9"),
      fontFamily: "Mulish",
      fontSize: 14,
      fontWeight: "400",
      textAlign: "center",
      textBaseline: "middle",
    });


    tooltip.label.adapters.add("html", (html, target) => {
      const dataItem = target.dataItem as am5.DataItem<am5xy.IXYSeriesDataItem>;
      if (dataItem) {
        const dataContext = dataItem.dataContext as ChartDataContext;
        const date: any = new Date(dataContext?.date)
        const time = `${date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', second: '2-digit' })}`
        // console.log('date', date)
        // const dataContext = dataItem.dataContext as ChartDataContext;
        // const time = dataContext?.time;
        return `
        <div>
        <strong>${chartName}</strong> ${dataItem?.get("valueY")} ${unitOfMeasure ?? ""} ${time}
        </div>`;
    }
      return html;
    });


    const bulletColor = chartColor.find(item => item.trendName === chartName)
    const series = chart.series.push(am5xy.LineSeries.new(root, {
      name: chartName,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: 'value',
      valueXField: 'date',
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: "horizontal"
      }),
      snapTooltip: true,
      stroke: am5.color(bulletColor?.color ?? '#F8AA62'),
    }));

    series.strokes.template.setAll({
      strokeWidth: 2
    })

    series.set("tooltip", tooltip)

    series.bullets.push(() => {
      const circle = am5.Circle.new(root, {
        radius: 0,
        fill: am5.color(`${bulletColor?.color ?? '#0000'}`),
        stroke: am5.color(`${bulletColor?.color1 ?? '#fff'}`),
        strokeWidth: 5,
        visible: false,
      });

      circle.states.create('hover', {
        radius: 7,
        visible: true,
      });


      return am5.Bullet.new(root, {
        locationX: 0.5,
        sprite: circle,
      });
    });

    const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {
      snapToSeries: [series], // Pass series as an array
      // behavior: 'none'
      behavior: 'zoomXY' // Ensure cursor snaps to the series and allows zooming
    }));
    cursor.lineY.set('visible', false);
    cursor.lineX.set('stroke', am5.color('#60BFDA'));
    cursor.lineX.set('strokeWidth', 3); // Adjust the width as needed
    cursor.lineX.set('height', 5); // Adjust the height as needed


    cursor.events.on('cursormoved', cursorMoved);
    cursor.events.on('cursorhidden', hideBullets);

    let range: any | null = null;
    let range1: any | null = null;

    function createRange(value: number, endValue: number, color: am5.Color) {

      const rangeDataItem = yAxis.makeDataItem({
        value: value,
        endValue: endValue
      });

      range = yAxis.createAxisRange(rangeDataItem);

      const rangeDataItem1 = yAxis.makeDataItem({
        value: value,
        endValue: endValue
      });

      range1 = yAxis.createAxisRange(rangeDataItem1);

      if (endValue) {
        range?.get("axisFill")?.setAll({
          fill: color,
          fillOpacity: 0,
          visible: true
        });
        range?.get("label")?.setAll({
          fill: am5.color("#191F23"),
          text: `High : ${maxValue} ${unitOfMeasure ?? ''}`,
          inside: true,
          height: 23,
          width: 100,
          maxWidth: 120,
          oversizedBehavior: "wrap",
          textAlign: "center",
          centerX: 0,
          visible: false,
          dx: 5,
          dy: -12,
          location: 1,
          background: am5.RoundedRectangle.new(root, {
            fill: am5.color("#60BFDA"),
            cornerRadiusTL: 4,
            cornerRadiusTR: 4,
            cornerRadiusBL: 4,
            cornerRadiusBR: 4,
          })
        });

        range1?.get("axisFill")?.setAll({
          fill: color,
          fillOpacity: 0,
          visible: true
        });
        range1?.get("label")?.setAll({
          fill: am5.color("#191F23"),
          text: ` Low : ${minValue} ${unitOfMeasure ?? ''} `,
          height: 23,
          width: 100,
          maxWidth: 120,
          oversizedBehavior: "wrap",
          textAlign: "center",
          inside: true,
          centerX: 0,
          visible: false,
          dx: 5,
          dy: 12,
          location: 0,
          background: am5.RoundedRectangle.new(root, {
            fill: am5.color("#60BFDA"),
            cornerRadiusTL: 4,
            cornerRadiusTR: 4,
            cornerRadiusBL: 4,
            cornerRadiusBR: 4,
          })
        });

      }
      else {
        range?.get("label")?.setAll({
          fill: color,
          background: am5.RoundedRectangle.new(root, {
            fill: color
          })
        });
        range1?.get("label")?.setAll({
          fill: color,
          background: am5.RoundedRectangle.new(root, {
            fill: color
          })
        });
      }
      range?.get("grid")?.setAll({
        stroke: am5.color("#384252"),
        strokeOpacity: 0,
        location: 1,
      });
      range1?.get("grid")?.setAll({
        stroke: am5.color("#384252"),
        strokeOpacity: 0,
        location: 0,
      });
    }

    if (minValue !== Infinity && maxValue !== Infinity) {
      createRange(minValue, maxValue, am5.color("#253040"));
    }

    const handleStartEndChange = () => {

      setTimeout(() => {
        const startTimestamp = xAxis.getPrivate("selectionMin") ?? 0;
        const endTimestamp = xAxis.getPrivate("selectionMax") ?? 0;

        let totalValue = 0;
        let minValue = Infinity;
        let maxValue = -Infinity;
        let visibleDataCount = 0;

        chartData.forEach(dataPoint => {
          const dataTimestamp = new Date(dataPoint.date).getTime();

          if (dataTimestamp >= startTimestamp && dataTimestamp <= endTimestamp) {
            const value = Number(dataPoint.value) || 0;
            totalValue += value;
            minValue = Math.min(minValue, value);
            maxValue = Math.max(maxValue, value);
            visibleDataCount++;
          }
        });

        if (visibleDataCount === 0) {
          minValue = 0;
          maxValue = 0;
        }

        const mean = visibleDataCount > 0 ? (totalValue / visibleDataCount) : 0;
        
        setTrendsMinMaxValue((prev: any) => ({
          ...prev,
          [chartName]: {
            minValue: +minValue.toFixed(3),
            maxValue: +maxValue.toFixed(3),
            mean: +mean.toFixed(3)
          },
        }));

        // update the tooltip label
        range.get("label").setAll({
          text: `High: ${+maxValue.toFixed(3)} ${unitOfMeasure ?? ''}`,
          visible: true
        });


        range1.get("label").setAll({
          text: `Low: ${+minValue.toFixed(3)} ${unitOfMeasure ?? ''}`,
          visible: true
        });

      }, 50);

    };

    function debounce(func: any, wait: number) {
      let timeout: any;
      return function (...args: any[]) {
        const later = () => {
          clearTimeout(timeout);
          func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    }

    xAxis.on("end", debounce(handleStartEndChange, 500));

    handleStartEndChange();

    let previousBulletSprites: any = [];
    function cursorMoved() {
      for (let i = 0; i < previousBulletSprites?.length; i++) {
        previousBulletSprites[i].unhover();
        previousBulletSprites[i].set('visible', false); // Hide bullets when not hovered
      }
      previousBulletSprites = [];
      chart.series.each(function (series) {
        const dataItem = series?.get('tooltip')?.dataItem;
        if (dataItem) {
          const bulletSprite = dataItem?.bullets?.length && dataItem?.bullets[0]?.get('sprite');
          if (bulletSprite) {
            bulletSprite.hover();
            bulletSprite.set('visible', true); // Make bullet visible on hover
            previousBulletSprites.push(bulletSprite);
          }
        }
      });

      yAxis?.axisRanges?.each((range) => {
        range.get("label")?.set("visible", true)
        range.get("grid")?.set("strokeOpacity", 1)
        range.get("axisFill")?.set("fillOpacity", 0.5)
      })

      const cursorPosition = chart?.get("cursor")?.getPrivate("point");
      const tooltip = series.get("tooltip");
      if (cursorPosition && tooltip) {
        tooltip.setAll({
          dy: -(cursorPosition.y - 10),
        });
      }

    }

    function hideBullets() {
      for (let i = 0; i < previousBulletSprites.length; i++) {
        previousBulletSprites[i].unhover();
        previousBulletSprites[i].set('visible', false); // Hide bullets
      }
      previousBulletSprites = [];

      yAxis?.axisRanges?.each((range) => {
        range.get("label")?.set("visible", false)
        range.get("grid")?.set("strokeOpacity", 0)
        range.get("axisFill")?.set("fillOpacity", 0)
      })
    }

    const sortedData = [...chartData]?.sort((a, b) => new Date(a?.date).getTime() - new Date(b?.date).getTime());

    const data = sortedData?.map((chart) => {
      const data = { date: new Date(chart.date).getTime(), value: Number(chart.value), time: chart.date?.split("T")[1] }
      return data;
    });

    series.data.setAll(data);
    xAxis.on("start", () => syncAxes(chart));
    xAxis.on("end", () => syncAxes(chart));

    return () => {
      root.dispose();
      chartsToSync = chartsToSync.filter(c => c !== chart); // Remove disposed chart from sync list
    };

  }, [chartData, chartName, unitOfMeasure, index]);

  
  return (
    <div id={chartId} style={{ width: "100%", height: "200px" }} className={chartData ? '' : 'center-content'}>
      {!chartData && loadingIcon ? (
        <div className='trend-loader flex items-center justify-center w-100'>
          <Loader />
        </div>
      ) : (!chartData && <NoData heading='No data found' />)}
    </div>
  );
};

export default LineChart;

