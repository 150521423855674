import { useRef, useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/storeHooks';
import { AppUser } from '../../../../../user/model/AppUser';
import { convertFromISO, SimpleSpeedChange } from '../../model/SimpleSpeedChange';
import { SteppedSpeedChange } from '../../model/SteppedSpeedChange';
import { fetchUserById } from '../../../../../user/UserSlice';
import {
  createScheduleSpeed,
  deleteScheduleById,
  fetchScheduleById,
  updateScheduleSpeedChange,
} from '../../ScheduleSpeedChangeSlice';
import { ToastContainer, toast } from 'react-toastify';
import ScheduledSimpleSpeedChange from './SimpleSpeedChange';
import CancelModal from '../../../../../common/Cancel/CancelModal';
import Edit from '../../../../../../images/edit-05.svg';
import InfoCircle from '../../../../../../images/error-info-icon.svg';
import Loader from '../../../../../../images/preloading.gif';
import userIcon from '../../../../../../../src/images/user-03.svg';
import calendar from '../../../../../../../src/images/calendar.svg';
import Frequency from '../../../../../../../src/images/frequency.svg';
import speedChange from '../../../../../../../src/images/calendar-date.svg';

const SimpleSpeedChangeFrequency: React.FC = () => {
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state?.user?.currentUser);
  const [user, setUser] = useState<AppUser | null>(currentUser);
  const [activeTabIndex] = useState(0);
  const [simpleSpeedChangeInstance, setSimpleSpeedChangeInstance] = useState<SimpleSpeedChange | null>(null);
  const [steppedSpeedChangeInstance, setSteppedSpeedChangeInstance] = useState<SteppedSpeedChange | null>(null);
  const [isSimpleChangeOpen, setIsSimpleChangeOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<number | null>(null);
  const scheduleData = useAppSelector((state) => state.scheduleSpeedChange.scheduleSpeedChange);
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const selectedAsset = useAppSelector((state) => state.assetGroups) ?? '';
  const hElement = document.getElementById('main-root');

  useEffect(() => {
    if (!currentUser?.id) {
      dispatch(fetchUserById(storedUser?.id));
    }
  }, [currentUser?.id, dispatch]);

  useEffect(() => {
    if (currentUser) {
      setUser(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    closeModal()
  }, [selectedAsset?.selectedAssetId])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleAddSchedule = () => {
    setIsSimpleChangeOpen(true);
    setSimpleSpeedChangeInstance({
      userId: '',
      wellId: '',
      wellName: '',
      groupName: '',
      deviceId: '',
      scheduleId: 0,
      currentFrequency: '',
      newFrequency: '',
      scheduleDateTime: '',
      description: '',
      id: '',
    });
    setSteppedSpeedChangeInstance(null);
    setEditMode(false);
  };

  const closeModal = () => {
    setIsSimpleChangeOpen(false);
    setEditMode(false);
  };

  const handleEditSchedule = (simpleSpeedChange: SimpleSpeedChange, steppedSpeedChange?: SteppedSpeedChange) => {
    if (simpleSpeedChange.userId === user?.id || (scheduleData?.userId && scheduleData.userId === user?.id)) {
      setIsDropdownOpen(null);
      setIsSimpleChangeOpen(true);
      setSimpleSpeedChangeInstance(simpleSpeedChange);
      setSteppedSpeedChangeInstance(steppedSpeedChange || null);
      setEditMode(true);
    } else {
      toast.error('Only the creator can edit');
    }
  };

  const handleCancelEdit = () => {
    setIsDropdownOpen(null);
    setIsCancelModalOpen(true);
    closeModal();
    hElement && hElement?.classList.add('modal-open');
  };

  const handleSaveModal = (
    updatedSimpleSpeedChange: SimpleSpeedChange,
    updatedSteppedSpeedChange?: SteppedSpeedChange,
  ) => {
    if (!updatedSimpleSpeedChange.scheduleDateTime) {
      handleAddSchedule();
      closeModal();
    } else {
      if (editMode) {
        dispatch(
          updateScheduleSpeedChange({
            id: scheduleData?.id || '',
            speedChanges: {
              simpleSpeedChanges: [updatedSimpleSpeedChange],
              steppedSpeedChanges: updatedSteppedSpeedChange ? [updatedSteppedSpeedChange] : [],
            },
          }),
        );
      } else {
        dispatch(
          createScheduleSpeed({
            userId: updatedSimpleSpeedChange.userId,
            wellId: updatedSimpleSpeedChange.wellId,
            wellName: updatedSimpleSpeedChange.wellName,
            groupName: updatedSimpleSpeedChange.groupName,
            deviceId: updatedSimpleSpeedChange.deviceId,
            simpleSpeedChanges: [updatedSimpleSpeedChange],
            steppedSpeedChanges: updatedSteppedSpeedChange ? [updatedSteppedSpeedChange] : [],
          }),
        );
      }
      toast.success('Scheduled speed change successfully changed');
      closeModal();
    }
  };

  const openDropdown = (index: number) => {
    setIsDropdownOpen((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleConfirmCancel = async () => {
    try {
      const response = await dispatch(deleteScheduleById(scheduleData?.id || ''));
      if (response) {

        toast.success('Scheduled speed change successfully canceled', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        dispatch(fetchScheduleById(scheduleData?.id || ''))
        setIsCancelModalOpen(false);
        hElement && hElement?.classList.remove('modal-open');
      }
    } catch (error) {
      toast.error('Failed to cancel schedule speed change', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error('Error creating or updating schedule:', error);
    }

  };

  return (
    <>
      {isSimpleChangeOpen && (
        <ScheduledSimpleSpeedChange
          isOpen={isSimpleChangeOpen}
          simpleSpeedChange={simpleSpeedChangeInstance}
          steppedSpeedChange={steppedSpeedChangeInstance}
          onClose={closeModal}
          onSave={handleSaveModal}
          editMode={editMode}
        />
      )}
      {isCancelModalOpen && (
        <CancelModal
          isCancelModal={isCancelModalOpen}
          onClose={() => setIsCancelModalOpen(false)}
          onCancel={handleConfirmCancel}
        />
      )}
      <div className='tab-content text-left'>
        <div className='flex flex-row pb-4'>
          {isSimpleChangeOpen ? null : (
            <div className='card'>
              <div className='header'>
                <div className='flex w-full flex-row'>
                  <div className='basis-3/5'>
                    <span className='scheduleSpeed__title'>Simple Speed Change</span>
                    <div className='pt-3'>
                      Simple speed change allows a user to schedule a one-time speed change to an asset either
                      immediately, or at a later date.
                    </div>
                  </div>
                  <div className='basis-2/5 text-right'>
                    {!editMode && (
                      <button
                        className='scheduleSpeed btn btn-primary add-btn'
                        onClick={handleAddSchedule}
                        disabled={isLoading || (scheduleData?.simpleSpeedChanges?.length ?? 0) > 0}
                      >
                        <span className='btn-icon'>+</span> Add speed change
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {isLoading ? (
                <div className='body d-flex align-items-center'>
                  <div className='card m-0'>
                    <div className='body'>
                      <p className='loader-scan'>
                        <img src={Loader} alt='Loading ...' className='loader-icon' />
                        Scanning asset ...
                      </p>
                    </div>
                  </div>
                </div>
              ) : scheduleData?.simpleSpeedChanges?.length ? (
                scheduleData.simpleSpeedChanges.map((schData, index) => (
                  <div className='body scheduleSpeed' key={index}>
                    <div className='scheduleSpeed__card m-0'>
                      <div className='header'>
                        <div className='flex w-full flex-row'>
                          <div className='basis-four'>
                            <div className='flex w-full flex-row'>
                              <span className='scheduleSpeed__title two'>Simple Speed Change</span>
                              <span className='sm-highlt'>
                                <img src={speedChange} alt='speed-change' />
                                Speed change scheduled
                              </span>
                            </div>
                            <div className='list-inline-items'>
                              <span>
                                <img src={userIcon} alt='userIcon' />
                                {user?.firstName} {user?.lastName}
                              </span>
                              <span>
                                <img src={calendar} alt='calendar' />
                                {(() => {
                                  const convertedValue = convertFromISO(schData.scheduleDateTime);
                                  if (convertedValue)
                                    return `${convertedValue.date} - ${convertedValue.time} ${convertedValue.ampm}`;
                                })()}
                              </span>
                              <span>
                                <img src={Frequency} alt='frequency' />
                                Initial: {schData.currentFrequency ?? ''} Hz Target: {schData.newFrequency} Hz
                              </span>
                            </div>
                          </div>
                          <div className='basis-one'>
                            <div className='scheduleSpeed__dropdown-container' ref={dropdownRef}>
                              <button
                                className='scheduleSpeed__dropdown-btn'
                                onClick={() => openDropdown(activeTabIndex)}
                              >
                                <span className='dot'></span>
                                <span className='dot'></span>
                                <span className='dot'></span>
                              </button>
                              {isDropdownOpen === activeTabIndex && (
                                <div className='dropdown-options'>
                                  <button>Actions</button>
                                  <button className='btn btn-default' onClick={() => handleEditSchedule(schData)}>
                                    <img src={Edit} alt='icon' className='mr-3' />
                                    Edit
                                  </button>
                                  <button className='btn btn-default' onClick={handleCancelEdit}>
                                    <img src={InfoCircle} alt='icon' className='mr-3' />
                                    Cancel
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='scheduleSpeed__description'>
                        <div>{schData.description}</div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className='body'>
                  <div className='card m-0'>
                    <div className='scheduleSpeed__body'>
                      <p>No schedule data available.</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <ToastContainer position='top-right' autoClose={3000} />
      </div>
    </>
  );
};

export default SimpleSpeedChangeFrequency;
