import React from 'react';
import InactiveIcon from '../../images/inactive.svg';
import './inactive-page.scss';

const InactivePage = () => {
  return (
    <div className='inactive-page__container'>
      <img src={InactiveIcon} alt='inactive-icon' className='inactive-page__icon' />
      <div className='inactive-page__content'>
        <p className='inactive-page__text'>
          Your LOOKOUT account is currently inactive. To reactivate your account or if you have any questions, please
          contact LOOKOUT support.
        </p>
        <a href='mailto:Optimizationhelp@championx.com' className='inactive-page__email-link'>Optimizationhelp@championx.com</a>
      </div>
    </div>
  );
};

export default InactivePage;
