import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface ChildGroup {
    assetId: string;
    assetName: string;
    industryApplicationId: number;
    
}

interface AssetGroupValues {
    assets: ChildGroup[] | null;
    childGroups: ChildGroup[] | null;
    groupName: string;
}

interface AssetGroupState {
    dateCreated: string | null;
    id: string | null;
    values: AssetGroupValues[] | null;
}

const initialState: AssetGroupState = {
    dateCreated: null,
    id: null,
    values: null
};

export const assetGroupSlice = createSlice({
  name: 'assetGroup',
  initialState,
  reducers: {
    setAssetGroupState(state, action: PayloadAction<AssetGroupState>) {
      state.dateCreated = action.payload.dateCreated;
      state.id = action.payload.id;
      state.values = action.payload.values;
    },
  },
});

export const { setAssetGroupState } = assetGroupSlice.actions;

export const getAssetGroup = (state: RootState) => state.assetGroup;

export default assetGroupSlice.reducer;
