import React from 'react';
import './AnalysisResultHeader.css';

interface AnalysisResultHeaderProps {
  title: string;
  description: string;
  isCollapsed: boolean;
  toggleCollapse: () => void;
}

const AnalysisResultHeader: React.FC<AnalysisResultHeaderProps> = (props: AnalysisResultHeaderProps) => {
  return (
    <div className="analysis-result-header-container">
      <button className="analysis-result-button" onClick={props.toggleCollapse}>
        <svg
          className="analysis-result-image"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          {props.isCollapsed ? (
            <path d="M12 5V19M5 12H19" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
          ) : (
            <path d="M5 11H19" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
          )}
        </svg>
      </button>
      <label className="analysis-result-title-text">{props.title}</label>
      <label className="analysis-result-description">{props.description}</label>
    </div>
  );
};

export default AnalysisResultHeader;
