import React, { useState } from 'react';
import './analysis-asset-tabs.scss';

interface Tab {
  label: string;
  component: React.ComponentType<any>;
}

interface TabsProps {
  tabs: Tab[];
  assetDetails?: boolean;
}

const AnalysisAssetTabs: React.FC<TabsProps> = ({ tabs, assetDetails=false }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <>
      <div className={`wrapper-location ${assetDetails && 'asset-details'}`}>
        <div className='ui-button-toolbar tab-header'>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`ui-button-toolbar-button padding-left-0 ${
                index === activeTabIndex ? 'ui-button-toolbar-button-active' : ''
              }`}
              onClick={() => setActiveTabIndex(index)}
            >
              {tab.label}
            </div>
          ))}
        </div>
      </div>
      <div className='tab-content'>
        {tabs.map((tab, index) => (
          <div key={index} style={{ display: index === activeTabIndex ? 'block' : 'none' }}>
            {React.createElement(tab.component)}
          </div>
        ))}
      </div>
    </>
  );
};

export default AnalysisAssetTabs;
