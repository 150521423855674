import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import  { AxiosError } from 'axios';
import { AnalysisTabStateProps, ProjectTabDetailsProps, WellTabDetailsProps, TubingTabDetailsProps, OtherTabDetailsProps, FluidTabDetailsProps } from './analysisProps';
import { GetMainReportData, getAnalysisTabDetails } from './AnalysisService';

const initialState: AnalysisTabStateProps = {
  loading: false,
  project: null,
  well: null,
  tubing: null,
  fluid: null,
  other: null,
  error: null,
  mainReportData: null,
  mainReportDataLoading:false,
}

export const fetchProjectTabDetails = createAsyncThunk(
  'equipment/fetchProjectTabDetails',
  async ({ userId, projectId, analysisType }: { userId: string, projectId: string, analysisType: string }, { rejectWithValue }) => {
    try {
      return await getAnalysisTabDetails(userId, projectId, analysisType);
    }  catch (error) {
      let errMessage = 'Unknown error occurred';
      if (error instanceof AxiosError && error.response) {
        errMessage = error.response.data.message || 'Failed to fetch analysis';
      }
      return rejectWithValue(errMessage);
  }
}
);

export const fetchWellTabDetails = createAsyncThunk(
  'equipment/fetchWellTabDetails',
  async ({ userId, projectId, analysisType }: { userId: string, projectId: string, analysisType: string }, { rejectWithValue }) => {
    try {
      return await getAnalysisTabDetails(userId, projectId, analysisType);
    }  catch (error) {
      let errMessage = 'Unknown error occurred';
      if (error instanceof AxiosError && error.response) {
        errMessage = error.response.data.message || 'Failed to fetch analysis';
      }
      return rejectWithValue(errMessage);
  }
}
);

export const fetchFluidTabDetails = createAsyncThunk(
  'equipment/fetchFluidTabDetails',
  async ({ userId, projectId, analysisType }: { userId: string, projectId: string, analysisType: string }, { rejectWithValue }) => {
    try {
      return await getAnalysisTabDetails(userId, projectId, analysisType);
    }  catch (error) {
      let errMessage = 'Unknown error occurred';
      if (error instanceof AxiosError && error.response) {
        errMessage = error.response.data.message || 'Failed to fetch analysis';
      }
      return rejectWithValue(errMessage);
  }
}
);

export const fetchTubingTabDetails = createAsyncThunk(
  'equipment/fetchTubingTabDetails',
  async ({ userId, projectId, analysisType }: { userId: string, projectId: string, analysisType: string }, { rejectWithValue }) => {
    try {
      return await getAnalysisTabDetails(userId, projectId, analysisType);
    }  catch (error) {
      let errMessage = 'Unknown error occurred';
      if (error instanceof AxiosError && error.response) {
        errMessage = error.response.data.message || 'Failed to fetch analysis';
      }
      return rejectWithValue(errMessage);
  }
}
);

export const fetchOtherTabDetails = createAsyncThunk(
  'equipment/fetchOtherTabDetails',
  async ({ userId, projectId, analysisType }: { userId: string, projectId: string, analysisType: string }, { rejectWithValue }) => {
    try {
      return await getAnalysisTabDetails(userId, projectId, analysisType);
    }  catch (error) {
      let errMessage = 'Unknown error occurred';
      if (error instanceof AxiosError && error.response) {
        errMessage = error.response.data.message || 'Failed to fetch analysis';
      }
      return rejectWithValue(errMessage);
  }
}
);

export const fetchMainReport = createAsyncThunk('user/mainReportData', async(wellApiNumber: string)=>{
  const response = await GetMainReportData(wellApiNumber);
  return response;
})


const analysisTabSlice = createSlice({
  name: 'analysisTab',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
      // project
      builder.addCase(fetchProjectTabDetails.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchProjectTabDetails.fulfilled, (state, action: PayloadAction<ProjectTabDetailsProps>) => {
        state.project = action.payload;
        state.loading = false;
      });
      builder.addCase(fetchProjectTabDetails.rejected, (state, action) => {
        state.project = null;
        state.error = action.payload as string;
        state.loading = false;
      });

      //well
      builder.addCase(fetchWellTabDetails.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchWellTabDetails.fulfilled, (state, action: PayloadAction<WellTabDetailsProps>) => {
        state.well = action.payload;
        state.loading = false;
      });
      builder.addCase(fetchWellTabDetails.rejected, (state, action) => {
        state.well = null;
        state.error = action.payload as string;
        state.loading = false;
      });

      //tubing
      builder.addCase(fetchTubingTabDetails.pending, (state) => {
        state.loading = true;
      })
      builder.addCase(fetchTubingTabDetails.fulfilled, (state, action: PayloadAction<TubingTabDetailsProps>) => {
        state.tubing = action.payload;
        state.loading = false;
      })
      builder.addCase(fetchTubingTabDetails.rejected, (state, action) => {
        state.tubing = null;
        state.error = action.payload as string;
        state.loading = false;
      })

      // fluid
      builder.addCase(fetchFluidTabDetails.pending, (state) => {
        state.loading = true;
      })
      builder.addCase(fetchFluidTabDetails.fulfilled, (state, action: PayloadAction<FluidTabDetailsProps>) => {
        state.fluid = action.payload;
        state.loading = false;
      })
      builder.addCase(fetchFluidTabDetails.rejected, (state, action) => {
        state.fluid = null;
        state.error = action.payload as string;
        state.loading = false;
      })

      // other
      builder.addCase(fetchOtherTabDetails.pending, (state) => {
        state.loading = true;
      })
      builder.addCase(fetchOtherTabDetails.fulfilled, (state, action: PayloadAction<OtherTabDetailsProps>) => {
        state.other = action.payload;
        state.loading = false;
      })
      builder.addCase(fetchOtherTabDetails.rejected, (state, action) => {
        state.other = null;
        state.error = action.payload as string;
        state.loading = false;
      })

      //mainreportdata
      builder.addCase(fetchMainReport.pending, (state) => {
        state.loading = true;
        state.mainReportDataLoading = true;
      })
      builder.addCase(fetchMainReport.fulfilled, (state, action) => {
        action.payload.graph1Data = JSON.parse(action.payload?.graph1Data || "[]");
        action.payload.graph2Data = JSON.parse(action.payload?.graph2Data || "[]");
        action.payload.graph3Data = JSON.parse(action.payload?.graph3Data || "[]");
        state.mainReportData = action.payload;
        state.loading = false;
        state.mainReportDataLoading = false;
      })
      builder.addCase(fetchMainReport.rejected, (state, action) => {
        state.mainReportData =JSON.parse("[]");
        state.error = action.payload as string;
        state.loading = false;
        state.mainReportDataLoading = false;
      })
  }
});

export default analysisTabSlice.reducer;