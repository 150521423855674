import React, { useEffect, useState } from 'react';
import './schduledSpeedChangeFrequency.scss'
import arrowsquare from '../../../../images/arrow-square-right.svg';
import { Options } from '../../data/Options';
import SimpleSpeedChangeFrequency from './components/schduled-speed-change-frequency/SimpleSpeedChangeFrequency';
import SteppedSpeedChangeFrequency from './components/stepped-speed-change-frequency/SteppedSpeedChangeFrequency';
import { fetchScheduleById } from './ScheduleSpeedChangeSlice';
import { AppUser } from '../../../user/model/AppUser';
import { useAppDispatch } from '../../../../hooks/storeHooks';

interface ScheduledSpeedChangeFrequencyProps {
  selectedOptions: Options;
  onClose: () => void;
}

const ScheduledSpeedChangeFrequency: React.FC<ScheduledSpeedChangeFrequencyProps> = ({ selectedOptions, onClose }) => {
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tabs = [
    { label: 'Simple (once)', component: SimpleSpeedChangeFrequency },
    { label: 'Stepped (multiple)', component: SteppedSpeedChangeFrequency },
  ];
  const dispatch = useAppDispatch()
  useEffect(() => {
    const fetchData = async () => {
      if (selectedOptions && storedUser) {
        dispatch(
          fetchScheduleById({
            userId: storedUser?.id,
            deviceId: storedUser?.id,
            wellId: selectedOptions?.wellId,
            wellName: selectedOptions?.wellName,
          }),
        );
      }
    };

    if (storedUser) {
      fetchData();
    }
  }, [selectedOptions, storedUser, dispatch]);

  const handleTabClick = (index: number) => {
    setActiveTabIndex(index);
  };

  const renderTabComponent = (Component: React.ElementType, props: any) => {
    return <Component {...props} />;
  };


  return (
    <>
      <div className='scheduleSpeed modal right-modal Schduled-speed'>
        <div className='border-bottom text-left pd-20'>
          <button onClick={onClose}>
            <img src={arrowsquare} alt='close' />
          </button>
        </div>
        <div className='heading border-bottom pd-20'>Scheduled speed change frequency</div>

        <div>
          <div className='ui-button-toolbar'>
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`ui-button-toolbar-button basis-1/2 ${index === activeTabIndex ? 'ui-button-toolbar-button-active semi-bold' : 'semi-bold'
                  }`}
                onClick={() => handleTabClick(index)}
              >
                {tab.label}
              </div>
            ))}
          </div>
          <div className='tab-content'>{renderTabComponent(tabs[activeTabIndex].component, { selectedOptions })}</div>
        </div>

      </div>
    </>
  );
};

export default ScheduledSpeedChangeFrequency;
