import '../../rod-lift-analysis/CardDateList/CardDateList.scss';
import WellTestItem from '../WellTestItem/WellTestItem';
import { useEffect } from 'react';
import { fetchESPAnalysisWellTestAsync, setSelected } from '../WellTestSlice';
import { useAppSelector, useAppDispatch } from '../../../hooks/storeHooks';

const WellTestList = () => {
  useEffect(() => {
    dispatch(fetchESPAnalysisWellTestAsync('mock'));
  }, []);

  const dispatch = useAppDispatch();
  const store = useAppSelector((state) => state.espAnalysisWellTest);

  const onClickActionHandler = (date: string) => {
    alert(date);
    dispatch(setSelected(date));

    return;
  };

  return (
    <div>
      {store.values.map((x, index) => (
        <WellTestItem key={index} date={x.date} isSelected={x.isSelected} onClickAction={onClickActionHandler} />
      ))}
    </div>
  );
};

export default WellTestList;
