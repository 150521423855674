import './AssetAnalysis.scss';
import '../rod-lift-analysis/RodLiftAnalysis/RodLiftAnalysis.scss';
import RodLiftAnalysis from '../rod-lift-analysis/RodLiftAnalysis/RodLiftAnalysis';
import TrendGroup from '../../images/trend-group-label-placeholder.png';

const AssetAnalysis = () => {
  return (
    <div>
      <div className='flex flex-column flex-grow flex-shrink'>
        <br />
        <div className='flex flex-row flex-grow flex-shrink'>
          <div className='flex flex-column flex-grow flex-shrink' style={{ paddingLeft: '1em', width: '50%' }}>
            <RodLiftAnalysis />
          </div>
          <div className='flex flex-column flex-grow flex-shrink' style={{ paddingLeft: '1em', width: '50%' }}>
            <div className='card-viewer'>
              <div className='card-viewer-header'>
                <span className='card-viewer-title'>Trend group label</span>
              </div>
              <div className='card-viewer-body'>
                <img data-testid='trend-group-chart' className='trend-group-chart' src={TrendGroup} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetAnalysis;
