import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../hooks/storeHooks';
import { setUIButtons, switchActiveButton } from '../navigation/UIButtonToolbarSlice';
import UIButtonToolbar from '../navigation/components/UIButtonToolbar';
import UISettingsButton from '../common/UIButtons/UISettingsButton';
import UIDownloadButton from '../common/UIButtons/UIDownloadButton';
import UITableViewOptionsButton from '../common/UIButtons/UITableViewOptionsButton';
import Dropdown from '../common/Dropdown/Dropdown';
import { fetchInitialStateAsync } from './TableViewSlice';
import { fetchInitialGroupStatusStateAsync } from './GroupStatusSlice';
import AssetDetailsTable from './AssetDetails';
import './GroupStatus.scss';
import Tooltip from '../common/tooltip/ToolTip';

const GroupStatus = () => {
  const uiButtons = [
    {
      name: 'List',
      active: true,
      id: 0,
    },
    {
      name: 'Map',
      active: false,
      id: 1,
    },
    {
      name: 'Alarms',
      active: false,
      id: 2,
    },
  ];

  const uiButtonsFromRedux = useAppSelector((state) => state.uiButtons.uiButtons);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setUIButtons(uiButtons));
    dispatch(fetchInitialStateAsync());
    dispatch(fetchInitialGroupStatusStateAsync());
  }, []);

  const tableViews = useAppSelector((state) => state.tableView.tableViews);
  const groupStatus = useAppSelector((state) => state.groupStatus.groupStatus);

  function buttonClicked(id: number) {
    alert(uiButtonsFromRedux[id].name);
    dispatch(switchActiveButton({ id: id, buttonType: 'ui' }));
  }
  return (
    <div className='group-status-container'>
      <div className='group-status-header'>
        <div className='group-status-content'>
          <div className='group-status-frame'>
            <div className='text-and-badge'>
              <div className='text'>Group status</div>
            </div>
          </div>
        </div>
      </div>
      <div className='divider'></div>
      <div className='group-status-sub-header'>
        <div className='tabs'>
          <UIButtonToolbar uiButtons={uiButtonsFromRedux} buttonClicked={buttonClicked} />
        </div>
        <div className='options'>
          <Tooltip content='Well Control' direction='left'>
            <UISettingsButton />
          </Tooltip>
          <UIDownloadButton />
          <div className='vertical-divider'></div>
          <UITableViewOptionsButton />
          <Tooltip content='Available Views' direction='right'>
            <Dropdown
              assets={tableViews?.Values?.map((tableView) => {
                return tableView.Name;
              })}
              showEyeIcon={true}
            />
          </Tooltip>
        </div>
      </div>
      {groupStatus?.Values?.Columns && groupStatus?.Values?.Columns?.length > 0 && (
        <AssetDetailsTable columns={groupStatus?.Values?.Columns} data={groupStatus?.Values.Rows} />
      )}
    </div>
  );
};

export default GroupStatus;
