import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface HistorySilterProps {
    defaultTrendsIds: string[];
    filteredValues: string[];
    finalFilteredValue: string[];
}

const initialState: HistorySilterProps = {
    defaultTrendsIds: [],
    filteredValues: [],
    finalFilteredValue: []
};

export const historyFilterSlice = createSlice({
  name: 'historyFilter',
  initialState,
  reducers: {
    setDefaultTrends(state, action: PayloadAction<string[]>) {
      state.defaultTrendsIds = action.payload;
    },
    setFilteredValues(state, action: PayloadAction<string[]>) {
        state.filteredValues = action.payload;
    },
    addFilterValue(state, action: PayloadAction<string>){
        state.filteredValues.push(action.payload);
    },
    setFinalfiltered(state, action: PayloadAction<string[]>){
        state.finalFilteredValue = action.payload
    }
  },
});

export const { setDefaultTrends, setFilteredValues, addFilterValue, setFinalfiltered } = historyFilterSlice.actions;

// export const getHistoryFilterState = (state: RootState) => state.historyFilter.defaultTrendsId;

export default historyFilterSlice.reducer;
