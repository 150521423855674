import React from 'react';
import Plus from '../../../images/plus.png';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import Dropdown from '../../common/Dropdown/Dropdown';
import Breadcrumb from '../../navigation/components/Breadcrumb';
import Line from '../../../images/line-02.png';
import Settings from '../../../images/gearIcon.png';
import Tools from '../../../images/tool-02.png';
import ChevronDown from '../../../images/chevron-down.svg';
import Dots from '../../../images/dots-vertical.png';
import UIButtonToolbar from '../../navigation/components/UIButtonToolbar';
import './Asset.scss';

function Asset() {
  const navigate = useNavigate();
  const { id } = useParams();
  const currentURLPath = location.pathname.split('/dashboard')[2];

  const navButtons = [
    {
      name: 'Status',
      active: currentURLPath === 'status',
      id: 0,
    },
    {
      name: 'Analysis',
      active: currentURLPath === 'analysis',
      id: 1,
    },
  ];

  function onNavChange(navButtonId: number) {
    const navButton = navButtons.find((navButton) => navButton.id == navButtonId);
    if (navButton) {
      navigate(`/layout/asset/${navButton.name.toLowerCase()}/${id}`);
    }
  }
  const onClickActionHandler = () => {
    alert('Button Clicked.');
  };

  return (
    <div>
      <div
        className='flex flex-row flex-grow flex-shrink'
        style={{
          padding: '1em',
          backgroundColor: 'white',
          borderBottom: '1px solid #ccc',
        }}
      >
        {id && (
          <div style={{ fontSize: 'large', flexGrow: 1 }}>
            <Breadcrumb />
          </div>
        )}
        <div className='trend-group-button-container'>
          <button data-testid='card-viewer-plus' className='trend-group-button' onClick={onClickActionHandler}>
            <img src={Plus} />
          </button>
          <img src={Line} />
          <button data-testid='card-viewer-settings' className='trend-group-button' onClick={onClickActionHandler}>
            <img src={Settings} />
            <img src={ChevronDown} />
          </button>
          <button data-testid='card-viewer-tools' className='trend-group-button' onClick={onClickActionHandler}>
            <img src={Tools} />
            <img src={ChevronDown} />
          </button>
          <div className='assets'>
            <Dropdown assets={['Custom dashboard 1']} showEyeIcon={true} /> {/* Change options later */}
          </div>
          <button data-testid='card-viewer-dots' className='trend-group-button' onClick={onClickActionHandler}>
            <img src={Dots} />
          </button>
        </div>
      </div>
      {id ? (
        <>
          <UIButtonToolbar uiButtons={navButtons} buttonClicked={onNavChange} />
          <Outlet />
        </>
      ) : (
        <div>Bad Route</div>
      )}
    </div>
  );
}

export default Asset;
