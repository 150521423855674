import { FC, useEffect, useRef } from 'react';
import './AssetImageOverlay.css';

const DisplayStatusState = {
  Normal: 0,
  OK: 1,
  Warning: 2,
  Error: 3,
  Fatal: 4,
  Emphasis: 5,
} as const;

const DisplayStatusStateCssClassMap = new Map<number, string>([
  [DisplayStatusState.Normal, ''],
  [DisplayStatusState.OK, 'good'],
  [DisplayStatusState.Warning, 'warning'],
  [DisplayStatusState.Error, 'error'],
  [DisplayStatusState.Fatal, 'fatal'],
  [DisplayStatusState.Emphasis, 'emphasis'],
]);

interface Props {
  bkgndImgUrl: string;
  data: IImageOverlayModel[];
}

export const AssetImageOverlay: FC<Props> = ({ bkgndImgUrl, data }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const overlayLayerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (imageRef.current?.naturalHeight && imageRef.current?.naturalWidth) {
      ScaleImageToFit();
    }
    window.addEventListener('resize', ScaleImageToFit);

    // remove the event listener before the component gets unmounted
    return () => window.removeEventListener('resize', ScaleImageToFit);
  }, [imageRef.current?.naturalHeight, imageRef.current?.naturalWidth]);

  function ScaleImageToFit() {
    if (!containerRef.current || !headerRef.current || !imageRef.current || !overlayLayerRef.current) return;

    const padding = 16;

    const horizontalScale = (containerRef.current.clientWidth - padding) / imageRef.current.naturalWidth;
    const verticalScale =
      (containerRef.current.clientHeight - padding - headerRef.current.offsetHeight) / imageRef.current.naturalHeight;

    // Use the smaller scale factor to preserve the height/width ratio
    const scale = Math.min(horizontalScale, verticalScale);

    // Scale the image
    imageRef.current.height = imageRef.current.naturalHeight * scale;
    imageRef.current.width = imageRef.current.naturalWidth * scale;

    // Scale the data overlays
    overlayLayerRef.current.setAttribute('style', `transform:scale(${scale})`);
  }

  function GetDataOverlayCssClass(dv: IDataValueModel): string {
    return 'data-value ' + DisplayStatusStateCssClassMap.get(dv.displayState)!;
  }

  return (
    <div
      ref={containerRef}
      className='img-view-container card flex flex-column flex-grow'
      style={{ minWidth: '400px', minHeight: '400px' }}
    >
      <div ref={headerRef} className='header'>
        <span className='title'>Well Status</span>
      </div>

      <div className='image-view-component body flex flex-grow p-relative'>
        <img ref={imageRef} src={bkgndImgUrl} alt='rod lift well diagram' height='0' width='0' />

        <div ref={overlayLayerRef} className='data-overlay-layer'>
          {data.map((iom: IImageOverlayModel, ndx: number) => {
            return (
              <div
                key={ndx}
                className='data-overlay'
                style={{ left: `${iom.position.x}px`, top: `${iom.position.y}px` }}
              >
                {iom.dataValues.map((dv: IOverlayDataValueModel, index: number) => {
                  return (
                    <div key={index} className={GetDataOverlayCssClass(dv)} title={`${dv.label}: ${dv.value}`}>
                      <span className='label'>{dv.label}:</span>
                      <span className='value'>{dv.value ?? '——'}</span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
