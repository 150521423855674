import { http } from '../../../../config/HttpCommon';
import { SimpleSpeedChange } from './model/SimpleSpeedChange';
import { SteppedSpeedChange } from './model/SteppedSpeedChange';

export const getScheduleById = async (userId: string, deviceId: string, wellId: string) => {
  const response = await http.get(`/getScheduleSpeedChange/${userId},${deviceId},${wellId}`);
  return response.data;
};

export const getCurrentFreqSetPointByWellId = async (wellId: string) => {
  const response = await http.get(`/GetCurrentFreqSetPoint/${wellId}`);
  return response.data;
};

export const cancelScheduleById = async (id: string) => {
  const response = await http.delete(`/DeleteScheduleSpeedChange/${id}`);
  return response.data;
};

export const createScheduleById = async (scheduleData: {
  userId: string;
  wellId: string;
  wellName: string;
  groupName: string;
  deviceId: string;
  simpleSpeedChanges: SimpleSpeedChange[];
  steppedSpeedChanges: SteppedSpeedChange[];
}) => {
  try {
    const response = await http.post('/createScheduleSpeed', scheduleData);
    return response.data;
  } catch (error) {
    console.error('Error creating schedule data :', error);
    throw error;
  }
};

export const updateScheduleById = async (
  id: string,
  speedChangeData: {
    simpleSpeedChanges: SimpleSpeedChange[];
    steppedSpeedChanges: SteppedSpeedChange[];
  },
) => {
  const response = await http.put(`/updateScheduleSpeedChange/${id}`, speedChangeData);
  return response.data;
};
