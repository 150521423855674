import React from 'react';
import './PerformanceSnapshot.scss';

const PerformanceSnapshot: React.FC = () => {
  return (
    <>
      <div className='performance-snapshot dashboard-first-block'>
        <div className='card-performance dashboard-card-first'>
          <div className='block-wrap'>
            <div className='dash-content'>
              <div className='para-dashboard '>
                <p className='text-para-dash'>Performance snapshot</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PerformanceSnapshot;
