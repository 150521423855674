import { useEffect, useState } from 'react';
import { AppUser } from '../../../../user/model/AppUser';
import { fetchTubingTabDetails } from '../AnalysisSlice';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks';
// import PageLoader from '../../../../common/page-loader/PageLoader';
import { TubingTabDetailsProps } from '../analysisProps';
import '../analysis.scss';
import { updateAssetControlScanStatus } from '../../../../asset/AssetControlSlice';

const TubingTab = () => {
  // const { loading }  = useAppSelector((state)=> state.analysisTab);
  const dispatch = useAppDispatch();
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const [tubingTabDetails, setTubingTabDetails] = useState<TubingTabDetailsProps | null>(null);
  const selectedAsset = useAppSelector((state) => state.assetGroups);
  const assetControlData = useAppSelector((state) => state?.assetControl)

  useEffect(() => {
    const wellName = selectedAsset?.selectedAssetName ?? '';
    if (wellName === '' || storedUser?.id === '') return;
    dispatch(fetchTubingTabDetails({ userId: storedUser.id, projectId: wellName, analysisType: 'tubing' }))
      .unwrap()
      .then((response: TubingTabDetailsProps) => {
        setTubingTabDetails(response);
      })
      .catch((error) => {
        console.error('Failed to fetch tubing tab details:', error);
      });

    if (assetControlData?.assetControlScanStatus)
      dispatch(updateAssetControlScanStatus(false))

  }, [dispatch, storedUser?.id, selectedAsset?.selectedAssetName, assetControlData?.assetControlScanStatus === true]);


  const convertInchToMm = (passingValue: string | undefined) => {
    if (passingValue) {
      const convertedValue = 25.4 * Number(passingValue); // Conversion factor from inches to millimeters
      return `${decimalToFraction(Number(passingValue))}" (${Number.isInteger(convertedValue) ? convertedValue : convertedValue.toFixed(2)} mm)`
    }
  }

  const convertPoundToKilogram = (passingValue: string | undefined) => {
    const lbToKg = 0.453592;
    const ftToM = 0.3048;
    if (passingValue) {
      const convertedValue = Number(passingValue) * (lbToKg / ftToM);
      return `${passingValue} lb/ft (${Number.isInteger(convertedValue) ? convertedValue : convertedValue.toFixed(2)} kg/m)`;
    }
  }

  const decimalToFraction = (decimal: number) => {
    const wholeNumber = Math.floor(decimal); // Get the whole number part
    const fractionalPart = decimal - wholeNumber; // Get the fractional part

    if (fractionalPart) {
      // Convert fractional part to a simplified fraction
      let numerator = Math.round(fractionalPart * 1000000); // Use a large multiplier for precision
      let denominator = 1000000; // Initial denominator

      // Reduce the fraction using greatest common divisor (GCD)
      const gcd = (a: number, b: number): number => {
        return b ? gcd(b, a % b) : a;
      };

      const divisor = gcd(numerator, denominator);
      numerator /= divisor;
      denominator /= divisor;

      // Format the mixed fraction string
      let fractionString = "";
      if (numerator === 0) {
        fractionString = ""; // No fractional part
      } else if (wholeNumber === 0) {
        fractionString = `${numerator}/${denominator}`; // Fraction only
      } else {
        fractionString = `${wholeNumber} - ${numerator}/${denominator}`; // Mixed fraction
      }

      return fractionString;
    }
    return decimal;
  }

  return (
    <>
      {/* {loading && <PageLoader />} */}
      <div className='analysis-content'>
        <div className='analysis-title'>Casing</div>
        <div className='cus-details'>
          <div>Casing OD*</div>
          <div>{convertInchToMm(tubingTabDetails?.casingOD)}</div>
        </div>

        <div className='cus-details'>
          <div>Casing weight*</div>
          <div>{convertPoundToKilogram(tubingTabDetails?.casingWeight)}</div>
        </div>

        <div className='cus-details'>
          <div>Casing ID</div>
          <div>{tubingTabDetails?.casingID ? `${tubingTabDetails?.casingID} in` : ''}</div>
        </div>

        <div className='cus-details'>
          <div>Casing drift ID</div>
          <div> {tubingTabDetails?.casingDriftID ? `${tubingTabDetails?.casingDriftID} in` : ''}</div>
        </div>
      </div>

      <div className='analysis-content'>
        <div className='analysis-title'>Linear</div>
        <div className='cus-details'>
          <div>Linear OD</div>
          <div>{tubingTabDetails?.linearOD}</div>
        </div>

        <div className='cus-details'>
          <div>Linear weight</div>
          <div>{tubingTabDetails?.linearWeight}</div>
        </div>

        <div className='cus-details'>
          <div>Liner ID</div>
          <div>{tubingTabDetails?.linerID}</div>
        </div>

        <div className='cus-details'>
          <div>Liner top @MD</div>
          <div>{tubingTabDetails?.linerTopMD}</div>
        </div>
      </div>

      <div className='analysis-content'>
        <div className='analysis-title'>Tubing</div>
        <div className='cus-details'>
          <div>Tubing OD*</div>
          <div>{convertInchToMm(tubingTabDetails?.tubingOD)}</div>
        </div>

        <div className='cus-details'>
          <div>Tubing type*</div>
          <div>{tubingTabDetails?.tubingType}</div>
        </div>

        <div className='cus-details'>
          <div>Tubing weight</div>
          <div>{convertPoundToKilogram(tubingTabDetails?.tubingWeight)}</div>
        </div>

        <div className='cus-details'>
          <div>Tubing ID</div>
          <div>{tubingTabDetails?.tubingID}</div>
        </div>

        <div className='cus-details'>
          <div>Tubing*</div>
          <div>{tubingTabDetails?.tubing}</div>
        </div>
      </div>

      <div className='analysis-tab-content'>
        <div>Well trajectory</div>
        <div>{tubingTabDetails?.wellTrajectory}</div>
      </div>
    </>
  );
};

export default TubingTab;
