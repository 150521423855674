import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { SteppedSpeedChange } from '../../model/SteppedSpeedChange';
import 'primeng/resources/themes/saga-blue/theme.css';
import 'primeng/resources/primeng.min.css';
import { createScheduleById } from '../../ScheduleSpeedChangeService';
import { useDispatch } from 'react-redux';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import Toolbar from 'react-multi-date-picker/plugins/toolbar';
import { fetchScheduleById, updateScheduleSpeedChange } from '../../ScheduleSpeedChangeSlice';
import { AppDispatch } from '../../../../../../store';
import { useAppSelector } from '../../../../../../hooks/storeHooks';
import { convertFromISO, SimpleSpeedChange } from '../../model/SimpleSpeedChange';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Info_Circle from '../../../../../../../src/images/error-info-icon.svg';
import Back_Left from '../../../../../../../src/images/arrow-narrow-left.svg';
import Question from '../../../../../../../src/images/question-mark.svg';
import Calendar from '../../../../../../../src/images/calendar.svg';
import { AppUser } from '../../../../../user/model/AppUser';

interface SteppedSpeedChangeProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (steppedSpeedChanges: SteppedSpeedChange) => void;
  steppedSpeedChange: SteppedSpeedChange | null;
  editMode: boolean;
}

const ScheduledSteppedSpeedChange: React.FC<SteppedSpeedChangeProps> = ({
  isOpen,
  onClose,
  onSave,
  steppedSpeedChange,
  editMode,
}) => {

  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const [showHeader] = useState(true);
  const selectedGroupName = '';
  const [updatedUserId, setupdatedUserId] = useState<string>(steppedSpeedChange?.userId || '');
  const [updatedWellId, setupdatedWellId] = useState<string>(steppedSpeedChange?.wellId || '');
  const [updatedGroupName, setupdatedGroupName] = useState<string>(steppedSpeedChange?.groupName || '');
  const [updatedWellName, setupdatedWellName] = useState<string>(steppedSpeedChange?.wellName || '');
  const [updatedDeviceId, setupdatedDeviceId] = useState<string>(steppedSpeedChange?.deviceId || '');

  const [updatedNewFrequency, setupdatedNewFrequency] = useState<string>(steppedSpeedChange?.newFrequency || '');
  const [updatedRestartFrequency, setupdatedRestartFrequency] = useState<string>(
    steppedSpeedChange?.restartFrequency || '',
  );
  const [updatedScheduledDate, setupdatedScheduledDate] = useState<Date | null>(null);
  const [timeInput, setTimeInput] = useState<string>('');
  const [ampmInput, setAmPmInput] = useState<string>('');
  const [updatedStepFrequency, setupdatedStepFrequency] = useState<string>(steppedSpeedChange?.stepFrequency || '');
  const [updatedStepInterval, setupdatedStepInterval] = useState<string>(steppedSpeedChange?.stepInterval || '');
  const [updatedScheduledDescription, setupdatedScheduledDescription] = useState<string>('');
  const steppedData = useAppSelector((state) => state?.scheduleSpeedChange?.scheduleSpeedChange);
  const wellName = useAppSelector((state) => state.scheduleSpeedChange.wellName.payload);
  const dispatch: AppDispatch = useDispatch();
  const selectedAsset = useAppSelector((state) => state.assetGroups) ?? '';
  const [newFrequencyErrorMessage, setNewFrequencyErrorMessage] = useState('');
  const [restartFrequencyErrorMessage, setRestartFrequencyErrorMessage] = useState('');
  const [stepFrequencyErrorMessage, setStepFrequencyErrorMessage] = useState('');
  const [stepIntervalErrorMessage, setStepIntervalErrorMessage] = useState('');
  const ToastContainer = () => <div></div>;
  // const validateFrequency = (value: string) => {
  //   const regex = /^(?:\d{1,3}(?:\.\d+)?|\d+)$/;
  //   return regex.test(value);
  // };

  useEffect(() => {
    if (steppedSpeedChange) {
      const convertedDateTime = convertFromISO(steppedSpeedChange?.scheduleDateTime);
      setTimeInput(convertedDateTime ? convertedDateTime?.time : '');
      setAmPmInput(convertedDateTime ? convertedDateTime?.ampm : '');
      setupdatedScheduledDate(convertedDateTime ? new Date(convertedDateTime?.date) : null)
      setupdatedScheduledDescription(steppedSpeedChange?.description);
    }
  }, [steppedSpeedChange]);

  useEffect(() => {
    if (steppedSpeedChange?.scheduleDateTime) {
      setupdatedUserId(steppedSpeedChange.userId || '');
      setupdatedWellId(steppedSpeedChange.wellId || '');
      setupdatedGroupName(steppedSpeedChange.groupName || '');
      setupdatedWellName(steppedSpeedChange.wellName || '');
      setupdatedDeviceId(steppedSpeedChange.deviceId || '');
      setupdatedNewFrequency(steppedSpeedChange.newFrequency || '');
      setupdatedRestartFrequency(steppedSpeedChange.restartFrequency || '');
      setupdatedStepFrequency(steppedSpeedChange.stepFrequency || '');
      setupdatedStepInterval(steppedSpeedChange.stepInterval || '');
      setupdatedScheduledDescription(steppedSpeedChange?.description || '');
    }
  }, [steppedSpeedChange, updatedDeviceId, updatedWellId, updatedGroupName, updatedWellName, updatedUserId]);

  const handleCreateOrUpdate = async () => {
    try {
      const isNewFrequencyValid = /^(?:\d{1,3}(?:\.\d*)?|\.\d+)$/.test(updatedNewFrequency);
      const isRestartFrequencyValid = /^(?:\d{1,3}(?:\.\d*)?|\.\d+)$/.test(updatedRestartFrequency);
      const isStepFrequencyValid = /^(?:\d{1,3}(?:\.\d*)?|\.\d+)$/.test(updatedStepFrequency);
      const isStepIntervalValid = parseFloat(updatedStepInterval) > 0 && parseFloat(updatedStepInterval) >= 0.1;

      if (!isNewFrequencyValid || !isRestartFrequencyValid || !isStepFrequencyValid || !isStepIntervalValid) {
        toast.error('Frequency must be a positive number', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      const currentDate = new Date();
      const formattedDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
      const selectedScheduleTime = new Date(
        `${updatedScheduledDate ? updatedScheduledDate.toLocaleDateString() : formattedDate} ${timeInput} ${ampmInput}`,
      );
      if (selectedScheduleTime < currentDate) {
        toast.error('Schedule time cannot be in the past', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      const updatedSteppedSpeedChange: SteppedSpeedChange = {
        userId: storedUser?.id,
        wellId: selectedAsset?.selectedAssetId ?? '',
        wellName: selectedGroupName,
        groupName: selectedGroupName,
        deviceId: storedUser?.id,
        currentFrequency: '57.4',
        newFrequency: updatedNewFrequency,
        restartFrequency: updatedRestartFrequency,
        scheduleDateTime: selectedScheduleTime.toISOString(),
        stepFrequency: updatedStepFrequency,
        stepInterval: updatedStepInterval,
        description: updatedScheduledDescription,
        id: '',
        scheduleId: 0,
      };
      const simpleSpeedChangeArray: SimpleSpeedChange[] = [];
      const updatedSimpleSpeedChange: SimpleSpeedChange[] = editMode
        ? []
        : simpleSpeedChangeArray?.length > 0
          ? [simpleSpeedChangeArray[0]]
          : [];

      let response;
      if (editMode && steppedSpeedChange) {
        response = await dispatch(
          updateScheduleSpeedChange({
            id: steppedData?.id || '',
            speedChanges: {
              simpleSpeedChanges: updatedSimpleSpeedChange,
              steppedSpeedChanges: [updatedSteppedSpeedChange],
            },
          }),
        );
      } else {
        response = await createScheduleById({
          userId: updatedSteppedSpeedChange.userId,
          wellId: updatedSteppedSpeedChange.wellId,
          wellName: updatedSteppedSpeedChange.wellName,
          groupName: updatedSteppedSpeedChange.groupName,
          deviceId: updatedSteppedSpeedChange.deviceId,
          simpleSpeedChanges: updatedSimpleSpeedChange,
          steppedSpeedChanges: [updatedSteppedSpeedChange],
        });
      }

      if (response) {
        toast.success('Scheduled speed change applied', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        if (!(editMode && steppedSpeedChange)) {
          const firstSteppedSpeedChange = response.steppedSpeedChanges?.[0];
          if (firstSteppedSpeedChange) {
            onSave(firstSteppedSpeedChange);
          }
        }

        dispatch(
          fetchScheduleById({
            userId: storedUser?.id,
            deviceId: storedUser?.id,
            wellId: selectedAsset?.selectedAssetId ?? '',
            wellName: selectedGroupName,
          }),
        );
        onClose();
      }
    } catch (error) {
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error('Error creating schedule:', error);
    }
  };

  const handleInputChange = (fieldName: string, value: string | Date | null) => {
    switch (fieldName) {
      case 'currentFrequency':
        return;
      case 'newFrequency':
        if (value !== null && typeof value === 'string') {
          const regex = /^(?:\d{1,3}(?:\.\d*)?|\.\d+)$/;
          if (value.trim() === '') {
            setNewFrequencyErrorMessage('Frequency must be a positive number');
          } else if (!regex.test(value)) {
            setNewFrequencyErrorMessage('Frequency must be a positive number');
          } else {
            setNewFrequencyErrorMessage('');
          }
          setupdatedNewFrequency(value);
        }
        break;
      case 'restartFrequency':
        if (value !== null && typeof value === 'string') {
          const regex = /^(?:\d{1,3}(?:\.\d*)?|\.\d+)$/;
          if (value.trim() === '') {
            setRestartFrequencyErrorMessage('Frequency must be a positive number');
          } else if (!regex.test(value)) {
            setRestartFrequencyErrorMessage('Frequency must be a positive number');
          } else {
            setRestartFrequencyErrorMessage('');
          }
          setupdatedRestartFrequency(value);
        }
        break;
      case 'stepFrequency':
        if (value !== null && typeof value === 'string') {
          const regex = /^(?:\d{1,3}(?:\.\d*)?|\.\d+)$/;
          if (value.trim() === '') {
            setStepFrequencyErrorMessage('Frequency must be a positive number');
          } else if (!regex.test(value)) {
            setStepFrequencyErrorMessage('Frequency must be a positive number');
          } else {
            setStepFrequencyErrorMessage('');
          }
          setupdatedStepFrequency(value);
        }
        break;
      case 'stepInterval':
        if (value !== null && typeof value === 'string') {
          const regex = /^(?:\d{1,3}(?:\.\d*)?|\.\d+)$/;
          if (value.trim() === '') {
            setStepIntervalErrorMessage('Step interval must be a positive number and at least 0.1 Hz');
          } else if (!regex.test(value)) {
            setStepIntervalErrorMessage('Step interval must be a positive number and at least 0.1 Hz');
          } else {
            setStepIntervalErrorMessage('');
          }
          setupdatedStepInterval(value);
        }
        break;
      case 'description':
        setupdatedScheduledDescription(value as string);
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    onClose();
  };

  const validateTimeFormat = (time: string): boolean => {
    const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    if (!regex.test(time)) return false;
    const [hours, minutes] = time.split(':').map(Number);
    return hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59;
  };

  const handleTimeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    value = value.replace(/[^0-9:]/g, '');
    if (value.length > 5) {
      value = value.slice(0, 5);
    }
    if (value.length > 2 && value[2] !== ':') {
      value = `${value.slice(0, 2)}:${value.slice(2)}`;
    }
    if (value.length > 5 && !validateTimeFormat(value)) {
      toast.error('Time must be in HH:MM format with exactly 2 digits for hours and minutes');
    } else {
      const splitedArray = value?.split(':')
      if (splitedArray?.length === 1 && Number(splitedArray[0]) <= 12) {
        setTimeInput(value)
      } else if (splitedArray?.length === 2 && Number(splitedArray[1]) < 60) {
        setTimeInput(value)
      }
    }
  };

  const handleAmPmChange = (value: string) => {
    setAmPmInput(value);
  };

  const handleDateSelection = (date: DateObject | DateObject[] | null) => {
    if (date) {
      const selectedDate = Array.isArray(date) ? date[0].toDate() : date.toDate();
      setupdatedScheduledDate(selectedDate);
    } else {
      setupdatedScheduledDate(null);
    }
  };

  return isOpen ? (
    <>
      <ToastContainer />
      <div className='scheduleSpeed__add-block'>
        {showHeader && (
          <div className='scheduleSpeed__header-bar'>
            <button onClick={handleBack}>
              <img src={Back_Left} alt='close' />
              <span>Back to scheduled speed change</span>
            </button>
          </div>
        )}
        <div className='scheduleSpeed__profile-block'>
          <div className='scheduleSpeed__notify-card'>
            <div className='scheduleSpeed__header'>
              <span className='title'>Create new scheduled speed change</span>
              <div>{wellName}</div>
            </div>
            <div className='scheduleSpeed__form-body'>
              <div className='scheduleSpeed__time-block'>
                <div className='scheduleSpeed__form-control relative'>
                  <label>Current frequency</label>
                  <input
                    type='text'
                    className='time-input-field'
                    placeholder={'57.4 Hz'}
                    disabled={true}
                  />
                  <img src={Question} alt='question-mark' className='absolute right-9 top-4' />
                </div>
                <div className='scheduleSpeed__form-control'>
                  <label className='label'>New frequency</label>
                  <div className={`input-field__container ${newFrequencyErrorMessage ? 'error-container' : ''}`}>
                    <input
                      type='text'
                      className='time-input-field'
                      placeholder='Enter new frequency'
                      value={updatedNewFrequency}
                      onChange={(e) => {
                        const pattern = /^\d{0,3}(\.\d{0,1})?$/
                        const value = e.target.value
                        if (pattern.test(value)) {
                          handleInputChange('newFrequency', e.target.value)
                        } else {
                          e.preventDefault()
                        }
                      }}
                    />
                    {newFrequencyErrorMessage && (
                      <img src={Info_Circle} alt='Info_Circle' className='stepped-error-icon' />
                    )}
                    {newFrequencyErrorMessage && <p className='error-message'>{newFrequencyErrorMessage}</p>}
                  </div>
                </div>
                <div className='scheduleSpeed__form-control'>
                  <label>Restart frequency</label>
                  <div className={`input-field__container ${restartFrequencyErrorMessage ? 'error-container' : ''}`}>
                    <input
                      type='text'
                      className='time-input-field'
                      placeholder='Enter restart frequency'
                      value={updatedRestartFrequency}
                      onChange={(e) => {
                        const pattern = /^\d{0,3}(\.\d{0,1})?$/
                        const value = e.target.value
                        if (pattern.test(value)) {
                          handleInputChange('restartFrequency', e.target.value)
                        } else {
                          e.preventDefault()
                        }
                      }}
                    />
                    {restartFrequencyErrorMessage && (
                      <img src={Info_Circle} alt='Info_Circle' className='stepped-error-icon' />
                    )}
                    {restartFrequencyErrorMessage && <p className='error-message'>{restartFrequencyErrorMessage}</p>}
                  </div>
                </div>
                <div className='scheduleSpeed__form-control'>
                  <label>Schedule time</label>
                  <div className='date-time-picker'>
                    <div className='date-time-container'>
                      <DatePicker
                        mobileLabels={{ OK: 'Apply', CANCEL: 'Cancel' }}
                        format='MM/DD/YYYY'
                        className='date-input-field rmdp-mobile bg-dark'
                        placeholder='MM / DD / YYYY'
                        weekDays={["Mo", "Tu", "We", "Th", "Fr", "Sat", "Su"]}
                        value={updatedScheduledDate ? new Date(updatedScheduledDate) : new Date()}
                        onChange={(date) => {
                          handleDateSelection(date);
                        }}
                        plugins={[<Toolbar position='top' sort={['today']} />]}
                        minDate={new Date().setHours(0, 0, 0, 0)}
                        showOtherDays={true}
                      />
                      <div className='calendar-icon'>
                        <img src={Calendar} alt='calendar' className='calendar-img' />
                      </div>
                      <div className='hours-mints-input'>
                        <input
                          type='text'
                          className='time-input-field hours-mints'
                          placeholder='HH : MM'
                          value={timeInput}
                          maxLength={5}
                          onChange={handleTimeInputChange}
                        />
                      </div>
                      <div className='ampm-button-group'>
                        <button
                          type='button'
                          className={`ampm-button ${ampmInput === 'AM' ? 'active' : ''}`}
                          onClick={() => handleAmPmChange('AM')}
                        >
                          AM
                        </button>
                        <span className='scheduleSpeed__seperator'></span>
                        <button
                          type='button'
                          className={`ampm-button ${ampmInput === 'PM' ? 'active' : ''}`}
                          onClick={() => handleAmPmChange('PM')}
                        >
                          PM
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='scheduleSpeed__form-control'>
                  <label>Step frequency</label>
                  <div className={`input-field__container ${stepFrequencyErrorMessage ? 'error-container' : ''}`}>
                    <input
                      type='text'
                      className='time-input-field'
                      placeholder='Enter minutes'
                      value={updatedStepFrequency}
                      onChange={(e) => {
                        const pattern = /^\d{0,3}(\.\d{0,1})?$/
                        const value = e.target.value
                        if (pattern.test(value)) {
                          handleInputChange('stepFrequency', e.target.value)
                        } else {
                          e.preventDefault()
                        }
                      }}
                    />
                    {stepFrequencyErrorMessage && (
                      <img src={Info_Circle} alt='Info_Circle' className='stepped-error-icon' />
                    )}
                    {stepFrequencyErrorMessage && <p className='error-message'>{stepFrequencyErrorMessage}</p>}
                  </div>
                </div>
                <div className='scheduleSpeed__form-control'>
                  <label>Step interval</label>
                  <div className={`input-field__container ${stepIntervalErrorMessage ? 'error-container' : ''}`}>
                    <input
                      type='text'
                      className='time-input-field'
                      placeholder='Enter step interval'
                      value={updatedStepInterval}
                      onChange={(e) => handleInputChange('stepInterval', e.target.value)}
                    />
                    {stepIntervalErrorMessage && (
                      <img src={Info_Circle} alt='Info_Circle' className='stepped-error-icon' />
                    )}
                    {stepIntervalErrorMessage && <p className='error-message'>{stepIntervalErrorMessage}</p>}
                  </div>
                </div>
                <div className='scheduleSpeed__form-control'>
                  <label>Description</label>
                  <textarea
                    className='time-input-field description-input-field'
                    placeholder='Enter description about scheduled speed change'
                    value={updatedScheduledDescription}
                    onChange={(e) => handleInputChange('description', e.target.value)}
                  />
                </div>
              </div>
              <div className='divider'></div>
              <div className='scheduleSpeed__btn-notify'>
                <button type='button' className='btn btn-secondary' onClick={onClose}>
                  Cancel
                </button>
                {!editMode && (
                  <button type='button' className='btn btn-primary' onClick={handleCreateOrUpdate}>
                    Create
                  </button>
                )}
                {editMode && (
                  <button type='button' className='btn btn-primary' onClick={handleCreateOrUpdate}>
                    Update
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default ScheduledSteppedSpeedChange;
