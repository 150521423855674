import React from 'react';
import UIShowTip from '../../common/UITip/UIShowTip';

function AssetWithRedux() {
  return (
    <div className='assetreduxpage'>
      <a href='/espanalysis/0'>ESP Analysis</a>
      <br />
      <UIShowTip />
    </div>
  );
}

export default AssetWithRedux;
