import React from 'react';
import Featured from '../../../../images/featured-icon.svg';
import { capitalizeFirstLetter } from '../../../common/DateRangeSelector/DefaultRanges';
import activateUser from '../../../../images/activate-user.svg';

interface ConfirmationModelProps {
  isValidAction: boolean;
  isModalClose: () => void;
  isModalOk: () => void;
  action: string;
}

const ConfirmationModel: React.FC<ConfirmationModelProps> = ({ isValidAction, isModalClose, isModalOk, action }) => {
  return (
    <>
      {isValidAction && (
        <div className='modal-overlay delete-modal-page'>
          <div className='modal'>
            <div className='flex flex-row flex-grow profile-block user-actions-modal'>
              <div className='notify-card'>
                <div className='header-delete'>
                  <div className='feature-img pt-4'>
                    {action === 'Active' ? (
                      <img src={activateUser} alt='activate user' />
                    ) : (
                      <img src={Featured} alt='' />
                    )}
                  </div>
                  <p className='title'>{capitalizeFirstLetter(action === 'Active' ? 'reactivate' : action)} user</p>
                  <p className='normal-text pb-2' style={{ color: '#b8c5cc' }}>
                    Are you sure, you want to {capitalizeFirstLetter(action === 'Active' ? 'reactivate' : action)} this
                    user's profile?
                  </p>
                </div>
                <div className='flex justify-center items-center gap-2 py-4'>
                  {action === 'Active' ? (
                    <>
                      <button onClick={isModalClose} className='btn btn-secondary'>
                        No
                      </button>{' '}
                      &nbsp;
                      <button onClick={isModalOk} className='btn btn-primary'>
                        Yes
                      </button>
                    </>
                  ) : (
                    <>
                      <button onClick={isModalOk} className='btn btn-secondary'>
                        OK
                      </button>{' '}
                      &nbsp;
                      <button onClick={isModalClose} className='btn btn-dark-orange'>
                        Cancel
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationModel;
