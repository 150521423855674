import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchGLAnalysisInitialState } from './GLAnalysisService';

interface State {
  loading: boolean;
  glAnalysis: GLAnalysis | null | undefined;
  error: string | undefined;
}

interface GLAnalysis {
  Id: string;
  DateCreated: string;
  Values: Data;
}

interface Data {
  Inputs: AnalysisData[];
  Outputs: AnalysisData[];
  Valves: ValveData[];
}

interface AnalysisData {
  Id: string;
  Name: string;
  DisplayName: string;
  Value: string;
  DisplayValue: string;
  DateTypeId: number;
  MeasurementAbbreviation: string;
  SourceId: number;
}

interface ValveData {
  Position: number;
  Description: string;
  Depth: string;
  ClosingPressureAtDepth: string;
  OpeningPressureAtDepth: string;
  InjectionPressureAtDepth: string;
  TubingCriticalVelocityAtDepth: string;
  Status: string;
}

// Async thunk to fetch the initial state data
export const fetchInitialStateAsync = createAsyncThunk('glAnalysisSlice/fetchInitialState', async () => {
  const data = await fetchGLAnalysisInitialState();
  return data;
});

const initialState: State = {
  loading: false,
  glAnalysis: null,
  error: undefined,
};

export const glAnalysisSlice = createSlice({
  name: 'glAnalysisData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitialStateAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInitialStateAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.glAnalysis = action.payload;
      })
      .addCase(fetchInitialStateAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default glAnalysisSlice.reducer;
