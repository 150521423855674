import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../hooks/storeHooks';
import { fetchWellTestInitialStateAsync, setSelectedWellStatus } from './GLAnalysisWellTestSlice';
import { fetchSurveyDateInitialStateAsync, setSelectedSurveyDate } from './GLAnalysisSurveyDateSlice';
import CardDateItem from '../rod-lift-analysis/CardDateItem/CardDateItem';

interface GLAnalysisProps {
  tabId: number;
}
const GlAnalysisWellTest = (props: GLAnalysisProps) => {
  const { tabId } = { ...props };
  const dispatch = useAppDispatch();
  const glAnalysisData =
    tabId == 0
      ? useAppSelector((state) => state.glAnalysisWellTest?.glAnalysisWellTest)
      : useAppSelector((state) => state.gLAnalysisSurveyDate?.glAnalysisSurveyDate);

  const fetchInitialStateAsync = tabId == 0 ? fetchWellTestInitialStateAsync : fetchSurveyDateInitialStateAsync;
  const setSelected = tabId == 0 ? setSelectedWellStatus : setSelectedSurveyDate;

  useEffect(() => {
    dispatch(fetchInitialStateAsync());
  }, [dispatch, fetchInitialStateAsync]);

  const onClickActionHandler = (date: string) => {
    alert(date);
    dispatch(setSelected(date));
    return;
  };
  return (
    <div>
      {glAnalysisData?.Values?.map((wellTest, index) => (
        <CardDateItem
          key={index}
          date={wellTest.Date}
          name={wellTest?.AnalysisTypeName}
          isSelected={wellTest.IsSelected}
          onClickAction={onClickActionHandler}
          showIcon={false}
        />
      ))}
    </div>
  );
};

export default GlAnalysisWellTest;
