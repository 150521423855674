import React, { useState, useEffect } from 'react';
import '../../schduledSpeedChangeFrequency.scss';
import { SteppedSpeedChange } from '../../model/SteppedSpeedChange';
import Edit from '../../../../../../images/edit-05.svg';
import InfoCircle from '../../../../../../images/error-info-icon.svg';
import Loader from '../../../../../../../src/images/preloading.gif';
import ScheduledSteppedSpeedChange from './SteppedSpeedChange';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppUser } from '../../../../../user/model/AppUser';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/storeHooks';
import { fetchUserById } from '../../../../../user/UserSlice';
import {
  createScheduleSpeed,
  deleteScheduleById,
  fetchScheduleById,
  updateScheduleSpeedChange,
} from '../../ScheduleSpeedChangeSlice';
import CancelModal from '../../../../../common/Cancel/CancelModal';
import userIcon from '../../../../../../../src/images/user-03.svg';
import calendar from '../../../../../../../src/images/calendar.svg';
import Frequency from '../../../../../../../src/images/frequency.svg';
import speedChange from '../../../../../../../src/images/calendar-date.svg';
import Step_Frequency from '../../../../../../../src/images/switch-vertical-01.svg';
import Step_Interval from '../../../../../../../src/images/clock-stopwatch.svg';
import Refresh from '../../../../../../../src/images/refresh-ccw-01.svg';
import { convertFromISO } from '../../model/SimpleSpeedChange';

const SteppedSpeedChangeFrequency: React.FC = () => {
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state?.user?.currentUser);
  const [user, setUser] = useState<AppUser | null>(currentUser);
  const [steppedSpeedChangeInstance, setSteppedSpeedChangeInstance] = useState<SteppedSpeedChange | null>(null);
  const [activeTabIndex] = useState(1);
  const [isSteppedChangeOpen, setIsSteppedChangeOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<number | null>(null);
  const steppedData = useAppSelector((state) => state.scheduleSpeedChange.scheduleSpeedChange);
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const selectedAsset = useAppSelector((state) => state.assetGroups) ?? '';
  const hElement = document.getElementById('main-root');

  useEffect(() => {
    if (!currentUser?.id) {
      dispatch(fetchUserById(storedUser?.id));
    }
  }, [currentUser?.id, dispatch]);

  useEffect(() => {
    if (currentUser) {
      setUser(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    closeModal()
  }, [selectedAsset?.selectedAssetId])

  const hadleAddStepped = () => {
    setIsSteppedChangeOpen(true);
    setSteppedSpeedChangeInstance({
      userId: '',
      wellId: '',
      wellName: '',
      groupName: '',
      deviceId: '',
      scheduleId: 0,
      currentFrequency: '',
      newFrequency: '',
      restartFrequency: '',
      scheduleDateTime: '',
      stepFrequency: '',
      stepInterval: '',
      description: '',
      id: '',
    });
    setEditMode(false);
  };

  const closeModal = () => {
    setIsSteppedChangeOpen(false);
    setEditMode(false);
  };

  const handleEditSchedule = (steppedSpeedChange: SteppedSpeedChange) => {
    if (steppedSpeedChange.userId === user?.id || (steppedData?.userId && steppedData.userId === user?.id)) {
      setIsDropdownOpen(null);
      setIsSteppedChangeOpen(true);
      setSteppedSpeedChangeInstance(steppedSpeedChange);
      setSteppedSpeedChangeInstance(steppedSpeedChange || null);
      setEditMode(true);
    } else {
      toast.error('Only the creator can edit');
    }
  };

  const handleCancelEdit = () => {
    setIsDropdownOpen(null);
    setIsCancelModalOpen(true);
    closeModal();
    hElement && hElement?.classList.add('modal-open');
  };

  const handleSaveModal = (updatedSteppedSpeedChange: SteppedSpeedChange) => {
    if (!updatedSteppedSpeedChange.scheduleDateTime) {
      hadleAddStepped();
      closeModal();
    } else {
      if (editMode) {
        dispatch(
          updateScheduleSpeedChange({
            id: steppedData?.id || '',
            speedChanges: {
              simpleSpeedChanges: [],
              steppedSpeedChanges: [updatedSteppedSpeedChange],
            },
          }),
        );
      } else {
        dispatch(
          createScheduleSpeed({
            userId: updatedSteppedSpeedChange.userId,
            wellId: updatedSteppedSpeedChange.wellId,
            wellName: updatedSteppedSpeedChange.wellName,
            groupName: updatedSteppedSpeedChange.groupName,
            deviceId: updatedSteppedSpeedChange.deviceId,
            simpleSpeedChanges: [],
            steppedSpeedChanges: [updatedSteppedSpeedChange],
          }),
        );
      }
      toast.success('Scheduled speed change successfully changed');
      closeModal();
    }
  };

  const openDropdown = (index: number) => {
    setIsDropdownOpen((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleConfirmCancel = async () => {

    try {
      const response = await dispatch(deleteScheduleById(steppedData?.id || ''));
      if (response) {

        toast.success('Scheduled speed change successfully canceled', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        dispatch(fetchScheduleById(steppedData?.id || ''))
        setIsCancelModalOpen(false);
        hElement && hElement?.classList.remove('modal-open');
      }
    } catch (error) {
      toast.error('Failed to cancel schedule speed change', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error('Error creating or updating schedule:', error);
    }

  };

  return (
    <>
      {isSteppedChangeOpen && (
        <ScheduledSteppedSpeedChange
          isOpen={isSteppedChangeOpen}
          steppedSpeedChange={steppedSpeedChangeInstance}
          onClose={closeModal}
          onSave={handleSaveModal}
          editMode={editMode}
        />
      )}
      {isCancelModalOpen && (
        <CancelModal
          isCancelModal={isCancelModalOpen}
          onClose={() => setIsCancelModalOpen(false)}
          onCancel={handleConfirmCancel}
        />
      )}
      <div className='tab-content text-left'>
        <div className='flex flex-row pb-4'>
          {isSteppedChangeOpen ? null : (
            <div className='card'>
              <div className='header'>
                <div className='flex w-full flex-row'>
                  <div className='basis-3/5'>
                    <span className='scheduleSpeed__title'>Stepped Speed Change</span>
                    <div className='pt-3'>
                      Stepped speed change allows a user to schedule a one time speed change to an asset either
                      immediately, or at a later date.
                    </div>
                  </div>
                  <div className='basis-2/5 text-right'>
                    {!editMode && (
                      <button
                        className='scheduleSpeed btn btn-primary add-btn'
                        onClick={hadleAddStepped}
                        disabled={isLoading || (steppedData?.steppedSpeedChanges?.length ?? 0) > 0}
                      >
                        <span className='btn-icon'>+</span> Add speed change
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {isLoading ? (
                <div className='body d-flex align-items-center'>
                  <div className='card m-0'>
                    <div className='body'>
                      <p className='loader-scan'>
                        <img src={Loader} alt='Loading ...' className='loader-icon' />
                        Scanning asset ...
                      </p>
                    </div>
                  </div>
                </div>
              ) : steppedData?.steppedSpeedChanges?.length ? (
                steppedData.steppedSpeedChanges.map((stpData, index) => (
                  <div className='body scheduleSpeed' key={index}>
                    <div className='scheduleSpeed__card m-0'>
                      <div className='header'>
                        <div className='flex w-full flex-row'>
                          <div className='basic-four'>
                            <div className='flex w-full flex-row'>
                              <span className='scheduleSpeed__title two'>Stepped Speed Change</span>
                              <span className='sm-highlt'>
                                <img src={speedChange} alt='speed-change' />
                                Speed change scheduled
                              </span>
                            </div>
                            <div className='list-inline-items'>
                              <span>
                                <img src={userIcon} alt='userIcon' />
                                {user?.firstName} {user?.lastName}
                              </span>
                              <span>
                                <img src={calendar} alt='calendar' />
                                {(() => {
                                  const convertedValue = convertFromISO(stpData.scheduleDateTime);
                                  if (convertedValue)
                                    return `${convertedValue.date} - ${convertedValue.time} ${convertedValue.ampm}`;
                                })()}
                              </span>
                              <span>
                                <img src={Frequency} alt='frequency' />
                                Initial: {stpData.currentFrequency} Hz Target: {stpData.newFrequency} Hz
                              </span>
                              <span>
                                <img src={Step_Frequency} alt='step-frequency' />
                                Step: {stpData.stepFrequency} Hz
                              </span>
                              <span>
                                <img src={Step_Interval} alt='step-interval' />
                                Interval: {stpData.stepInterval} minutes
                              </span>
                              <span>
                                <img src={Refresh} alt='refresh' />
                                Restart Frequency: {stpData.restartFrequency} Hz
                              </span>
                            </div>
                          </div>
                          <div className='basis-one'>
                            <div className='scheduleSpeed__dropdown-container'>
                              <button
                                className='scheduleSpeed__dropdown-btn'
                                onClick={() => openDropdown(activeTabIndex)}
                              >
                                <span className='dot'></span>
                                <span className='dot'></span>
                                <span className='dot'></span>
                              </button>
                              {isDropdownOpen === activeTabIndex && (
                                <div className='dropdown-options'>
                                  <button>Actions</button>
                                  <button className='btn btn-default' onClick={handleEditSchedule.bind(null, stpData)}>
                                    <img src={Edit} alt='icon' className='mr-3' />
                                    Edit
                                  </button>
                                  <button className='btn btn-default' onClick={handleCancelEdit}>
                                    <img src={InfoCircle} alt='icon' className='mr-3' />
                                    Cancel
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='scheduleSpeed__description'>
                        <div>{stpData.description}</div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className='body'>
                  <div className='card m-0'>
                    <div className='scheduleSpeed__body'>
                      <p>No schedule data available.</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <ToastContainer position='top-right' autoClose={3000} />
      </div>
    </>
  );
};

export default SteppedSpeedChangeFrequency;
