import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWellTestAsync } from './ESPAnalysisService';

interface WellTestItemState {
  date: string;
  isSelected: boolean;
}

interface WellTestListState {
  values: WellTestItemState[];
}

const fetchESPAnalysisWellTestAsync = createAsyncThunk('ESPAnalysis/fetchWellTest', async (assetId: string) => {
  const response = await fetchWellTestAsync(assetId);
  return response;
});

const initialState: WellTestListState = {
  values: [],
};

const espAnalysisCardDateSlice = createSlice({
  name: 'ESPAnalysis/WellTest',
  initialState,
  reducers: {
    setSelected(state, action: PayloadAction<string>) {
      const date = action.payload;
      console.log('action', date);

      state.values.forEach((x) => {
        if (x.date == date) {
          x.isSelected = true;
        } else {
          x.isSelected = false;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchESPAnalysisWellTestAsync.fulfilled, (state, action) => {
      if (action?.payload?.Values === undefined || action.payload.Values?.length === 0) {
        return;
      }

      state.values = action.payload?.Values.map(
        (x) =>
          ({
            date: x.Date,
          }) as WellTestItemState,
      );
    });
  },
});

export { fetchESPAnalysisWellTestAsync, WellTestItemState };
export const { setSelected } = espAnalysisCardDateSlice.actions;
export default espAnalysisCardDateSlice.reducer;
