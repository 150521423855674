import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { fetchGLAnalysisSurveyDateInitialState } from './GLAnalysisService';

interface State {
  loading: boolean;
  glAnalysisSurveyDate: GLAnalysisSurveyDate | null | undefined;
  error: string | undefined;
}

interface GLAnalysisSurveyDate {
  Id: string;
  DateCreated: string;
  Values: SurveyDataData[];
}

interface SurveyDataData {
  Date: string;
  IsSelected: boolean;
  AnalysisTypeName: string;
}

// Async thunk to fetch the initial state data
export const fetchSurveyDateInitialStateAsync = createAsyncThunk(
  'glAnalysisSurveyDateSlice/fetchSurveyDateInitialState',
  async () => {
    const data = await fetchGLAnalysisSurveyDateInitialState();
    return data;
  },
);

const initialState: State = {
  loading: false,
  glAnalysisSurveyDate: null,
  error: undefined,
};

export const glAnalysisSurveyDateSlice = createSlice({
  name: 'glAnalysisSurveyDateData',
  initialState,
  reducers: {
    setSelectedSurveyDate(state, action: PayloadAction<string>) {
      const date = action.payload;

      state.glAnalysisSurveyDate?.Values.forEach((x) => {
        if (x.Date == date) {
          x.IsSelected = true;
        } else {
          x.IsSelected = false;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSurveyDateInitialStateAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSurveyDateInitialStateAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.glAnalysisSurveyDate = action.payload;
      })
      .addCase(fetchSurveyDateInitialStateAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setSelectedSurveyDate } = glAnalysisSurveyDateSlice.actions;
export default glAnalysisSurveyDateSlice.reducer;
