import React from 'react'
import ErrorIcon from '../../../../images/error-icon.png'
import './NoData.scss'

interface NoDataProps {
    heading: string,
    description?: string
}

const NoData: React.FC<NoDataProps> = ({ heading, description }) => {
    return (
        <div className='no-data'>
            <div className='icon'>
                <img src={ErrorIcon} alt='' />
            </div>
            <div className='heading'>
                {heading}
            </div>
            <div className='description'>
                {description}
            </div>

        </div>
    )
}

export default NoData