import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getTrendsById, getTrendsFilterData } from './TrendsService';
import { TrendsDetailsProps } from './TrendsDetails';


interface TrendsState {
  loading: boolean;
  message: string | null;
  trends: TrendsDetailsProps[] | null;
  trendsFilterData: any | null;
}

const initialState: TrendsState = {
  loading: false,
  message: null,
  trends: null,
  trendsFilterData: null,
};

export const fetchTrendsById = createAsyncThunk(
  'events/fetchTrendsById',
  async ({wellName, startDate, endDate }: { wellName: string; startDate: string; endDate: string }) => {
    const response = await getTrendsById(wellName, startDate, endDate);
    return response;
  },
);

export const fetchTrendsChartFilterData = createAsyncThunk(
  'events/fetchTrendsChartFilterData',
  async () => {
    const response = await getTrendsFilterData();
    return response;
  },
);

const trendsSlice = createSlice({
  name: 'trends',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle the fetchEventsById fulfilled action
    builder.addCase(fetchTrendsById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchTrendsById.fulfilled, (state, action: PayloadAction<any>) => {
      state.trends = null;
      state.trends = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchTrendsById.rejected, (state, action) => {
      state.trends = null;
      state.message = action.payload as string;
      state.loading = false;
    });


    //fetch filter data
    builder.addCase(fetchTrendsChartFilterData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchTrendsChartFilterData.fulfilled, (state, action: PayloadAction<any>) => {
      state.trendsFilterData = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchTrendsChartFilterData.rejected, (state, action) => {
      state.trendsFilterData = null;
      state.message = action.payload as string;
      state.loading = false;
    });
  },
});


export default trendsSlice.reducer;
