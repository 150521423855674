// EquipmentService.ts
import { http } from '../../../../config/HttpCommon';

export const getEquipment = async (userId: string, assetId: string) => {
  try {
    const response = await http.get(`GetEquipment/${userId}/${assetId}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch equipment data');
  }
};

export const getEquipmentDetails = async (userId: string, wellName: string, equipmentId: string) => {
  try {
    const response = await http.get(`GetEquipmentDetails/${userId}/${wellName}/${equipmentId}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch equipment details');
  }
};


export const getEquipmentVolume =async (oracleItemNumber: string)=>{
  try {
    const response = await http.get(`GetEquipmentVolume/${oracleItemNumber}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch equipment volume details');
  }
};
 
