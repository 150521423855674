import React from 'react';
import CustomModal from '../ExpandModel';
import Close from '../../../../../images/x-close.svg';
import WellPerformanceTrendsChart from './WellPerformanceTrendsChart';

interface WellPerformanceTrendsModelProps {
  isOpen: boolean;
  onRequestClose: () => void;
  heading: string;
  setIsDropdownOpen: any;
  dropdownRef: any;
  selectedItem: any;
  setSelecteditem: any;
  selectedDateRange: any;
  setSelectedDateRange: any;
  data: any;
  loading: any;
}

const WellPerformanceTrendsModel: React.FC<WellPerformanceTrendsModelProps> = ({
  isOpen,
  onRequestClose,
  heading,
  setIsDropdownOpen,
  dropdownRef,
  selectedItem,
  setSelecteditem,
  selectedDateRange,
  setSelectedDateRange,
  data,
  loading,
}) => {

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onRequestClose} width='65rem' classname='wellPerTrends'>
      <div className='modal-header'>
        <div className='flex'>
          <div className='grow'>
            <h3>{heading}</h3>
          </div>
          <div className='flex-none'>
            <button onClick={onRequestClose}>
              <img src={Close} alt='Expand' />
            </button>
          </div>
        </div>
      </div>
      <div className='modal-content'>
        <WellPerformanceTrendsChart
          setIsDropdownOpen={setIsDropdownOpen}
          dropdownRef={dropdownRef}
          chartName={'series'}
          index={2}
          selectedItem={selectedItem}
          setSelecteditem={setSelecteditem}
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={setSelectedDateRange}
          showZindex={() => null}
          data={data}
          loading={loading}
        />
      </div>
    </CustomModal>
  );
};

export default WellPerformanceTrendsModel;
