import { http } from "../../../../config/HttpCommon";

export const getAnalysisTabDetails = async (userId: string, projectId: string, analysisType: string) => {
    const response = await http.get(`/GetAnalysisTabDetails/${userId}/${projectId}/${analysisType}`);
    return response.data;
};

export const GetMainReportData = async (wellApiNumber: string)=>{
    const response = await http.get(`/GetMainReportData?wellApiNumber=${wellApiNumber}`);
    return response.data;
}