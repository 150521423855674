import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { fetchGLAnalysisWellTestInitialState } from './GLAnalysisService';

interface State {
  loading: boolean;
  glAnalysisWellTest: GLAnalysisWellTest | null | undefined;
  error: string | undefined;
}

interface GLAnalysisWellTest {
  Id: string;
  DateCreated: string;
  Values: WellTestData[];
}

interface WellTestData {
  Date: string;
  AnalysisTypeId: number;
  AnalysisTypeName: string;
  AnalysisResultId: string;
  IsSelected: boolean;
}

// Async thunk to fetch the initial state data
export const fetchWellTestInitialStateAsync = createAsyncThunk(
  'glAnalysisWellTestSlice/fetchInitialState',
  async () => {
    const data = await fetchGLAnalysisWellTestInitialState();
    return data;
  },
);

const initialState: State = {
  loading: false,
  glAnalysisWellTest: null,
  error: undefined,
};

export const glAnalysisWellTestSlice = createSlice({
  name: 'glAnalysisWellTestData',
  initialState,
  reducers: {
    setSelectedWellStatus(state, action: PayloadAction<string>) {
      const date = action.payload;

      state.glAnalysisWellTest?.Values.forEach((x) => {
        if (x.Date == date) {
          x.IsSelected = true;
        } else {
          x.IsSelected = false;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWellTestInitialStateAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchWellTestInitialStateAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.glAnalysisWellTest = action.payload;
      })
      .addCase(fetchWellTestInitialStateAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setSelectedWellStatus } = glAnalysisWellTestSlice.actions;
export default glAnalysisWellTestSlice.reducer;
