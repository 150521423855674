import { useEffect } from "react";
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { useAppSelector } from "../../../../../hooks/storeHooks";
import { depletionGraphData } from "./graphs";
import Loader from "../../../../common/page-loader/ComponentLoader";

const DepletionData = () => {
    const mainReportData = useAppSelector((state) => state.analysisTab.mainReportData);
    const depletionData = depletionGraphData(mainReportData?.depletiongraphdata || []);
    const loading = useAppSelector((state) => state.analysisTab.mainReportDataLoading);

    useEffect(() => {
        let root:any;
        if (!loading) {
             root = am5.Root.new('DepletionChartdiv');
            intializeChart(root)
        }

        return () => {
            root?.dispose();
        };
    }, [depletionData]);


    const intializeChart = (root: am5.Root) => {
        root.setThemes([am5themes_Animated.new(root)]);

        const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: 'none',
                wheelY: 'none',
                pinchZoomX: false,
                pinchZoomY: false,
            }),
        );

        const xRenderer = am5xy.AxisRendererX.new(root, {
            minorGridEnabled: true,
            minGridDistance: 100,
        });
        xRenderer.labels.template.set('fill', am5.color('#fff'));
        const xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                autoZoom: false,
                min: 0,
                renderer: xRenderer,
                tooltip: am5.Tooltip.new(root, {}),
            }),
        );
        const xAxisLabel = am5.Label.new(root, {
            // text: "Liquid rate (bpd)",
            fill: am5.color("#fff"),
            // rotation: -90,
            centerX: am5.p100,
            centerY: am5.p100,
            x: 700,
            y: 70,
        });

        xAxis.children.push(xAxisLabel);

        const yRenderer = am5xy.AxisRendererY.new(root, {
            minorGridEnabled: true,
            minGridDistance: 50,
        });
        yRenderer.labels.template.set('fill', am5.color('#fff'));
        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                min: 0,
                renderer: yRenderer,
            }),
        );
        const yAxisLabel = am5.Label.new(root, {
            text: 'Head (ft)',
            fill: am5.color("#fff"),
            rotation: -90,
            centerX: am5.p100,
            centerY: am5.p100,
            x: -15,
            y: 200,
        });

        yAxis.children.push(yAxisLabel);

        const seriesWiggins = am5xy.SmoothedXYLineSeries.new(root, {
            name: 'Wiggins',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'Y',
            valueXField: 'X',
            stroke: am5.color("#FEC84B"),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
            }),
            fill: am5.color("#FEC84B"),
        });
        seriesWiggins.data.setAll(depletionData.wiggins);
        seriesWiggins.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['X', 'Y'],
        });

        const series10Percent = am5xy.SmoothedXYLineSeries.new(root, {
            name: '10%',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'Y',
            valueXField: 'X',
            stroke: am5.color("#FB743C"),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
            }),
            fill: am5.color("#FB743C"),
        });
        series10Percent.data.setAll(depletionData.depletion10Percent);
        series10Percent.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['X', 'Y'],
        });

        const series20Percent = am5xy.SmoothedXYLineSeries.new(root, {
            name: '20%',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'Y',
            valueXField: 'X',
            stroke: am5.color("#60BFDA"),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
            }),
            fill: am5.color("#60BFDA"),
        });
        series20Percent.data.setAll(depletionData.depletion20Percent);
        series20Percent.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['X', 'Y'],
        });

        const series30Percent = am5xy.SmoothedXYLineSeries.new(root, {
            name: '30%',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'Y',
            valueXField: 'X',
            stroke: am5.color("#7ABFBA"),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
            }),
            fill: am5.color("#7ABFBA"),
        });
        series30Percent.data.setAll(depletionData.depletion30Percent);
        series30Percent.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['X', 'Y'],
        });

        const series40Percent = am5xy.SmoothedXYLineSeries.new(root, {
            name: '40%',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'Y',
            valueXField: 'X',
            stroke: am5.color("#4294FF"),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
            }),
            fill: am5.color("#4294FF"),
        });
        series40Percent.data.setAll(depletionData.depletion40Percent);
        series40Percent.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['X', 'Y'],
        });

        const series50Percent = am5xy.SmoothedXYLineSeries.new(root, {
            name: '50%',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'Y',
            valueXField: 'X',
            stroke: am5.color("#F97066"),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
            }),
            fill: am5.color("#F97066"),
        });
        series50Percent.data.setAll(depletionData.depletion50Percent);
        series50Percent.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['X', 'Y'],
        });

        const series60Percent = am5xy.SmoothedXYLineSeries.new(root, {
            name: '60%',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'Y',
            valueXField: 'X',
            stroke: am5.color("#32D583"),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
            }),
            fill: am5.color("#32D583"),
        });
        series60Percent.data.setAll(depletionData.depletion60Percent);
        series60Percent.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['X', 'Y'],
        });

        const series70Percent = am5xy.SmoothedXYLineSeries.new(root, {
            name: '70%',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'Y',
            valueXField: 'X',
            stroke: am5.color("#B8D7FF"),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
            }),
            fill: am5.color("#B8D7FF"),
        });
        series70Percent.data.setAll(depletionData.depletion70Percent);
        series70Percent.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['X', 'Y'],
        });

        // const seriesGeneralDepletion = am5xy.SmoothedXYLineSeries.new(root, {
        //     name: 'GeneralDepletion',
        //     xAxis: xAxis,
        //     yAxis: yAxis,
        //     valueYField: 'Y',
        //     valueXField: 'X',
        //     stroke: am5.color("#4294FF"),
        //     tooltip: am5.Tooltip.new(root, {
        //         pointerOrientation: 'horizontal',
        //         labelText: '{valueY}',
        //     }),
        // fill: am5.color("#4294FF"),
        // });
        // seriesGeneralDepletion.data.setAll(depletionData.generalDepletion);
        // seriesGeneralDepletion.strokes.template.setAll({
        //     strokeDasharray: [4, 4],
        // });
        // seriesGeneralDepletion.data.processor = am5.DataProcessor.new(root, {
        //     numericFields: ['X', 'Y'],
        // });

        chart.series.push(seriesWiggins);
        chart.series.push(series10Percent);
        chart.series.push(series20Percent);
        chart.series.push(series30Percent);
        chart.series.push(series40Percent);
        chart.series.push(series50Percent);
        chart.series.push(series60Percent);
        chart.series.push(series70Percent);

        const legend = am5.Legend.new(root, {
            layout: am5.GridLayout.new(root, {
                maxColumns: 8,
                fixedWidthGrid: false,
            }),
            marginTop: 30,
            useDefaultMarker: true,
        });

        legend.markerRectangles.template.setAll({
            cornerRadiusTL: 10,
            cornerRadiusTR: 10,
            cornerRadiusBL: 10,
            cornerRadiusBR: 10,
            width: 12,
            height: 12,
          });

        // Set legend labels color
        legend.labels.template.setAll({
            fill: am5.color(0xffffff), // Adjust color as needed
        });

        // Customize or simplify the legend markers
        // legend.markers.template.setAll({
        //     width: 20, // Adjust width as needed
        //     height: 20, // Adjust height as needed
        // });


        // Add the legend to the chart
        chart.bottomAxesContainer.children.push(legend);

        // Link the legend to the series
        legend.data.setAll([seriesWiggins, series10Percent, series20Percent, series30Percent, series40Percent, series50Percent, series60Percent, series70Percent]);

    }

    return (
        <>
            {loading ? (
                <div className='flex items-center justify-content loader-margin'>
                    <Loader />
                </div>
            ) : (<div>
                <p className="depletion-curve">Depletion curve</p>
                <div id="DepletionChartdiv" style={{ width: '100%', height: '500px' }}></div>
            </div>)
            }

        </>
    )

};

export default DepletionData;