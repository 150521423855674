export enum Role {
    Admin = 1,
    Assetcontrol = 2,
    Readonly = 3
}

export enum Privilege {
    Admin = 'Admin',
    Assetcontrol = 'Asset Control',
    Readonly = 'Read Only'
}