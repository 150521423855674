import React, { useEffect } from 'react';
import './NotificationList.scss';
import { useAppSelector } from '../../../../hooks/storeHooks';
import { Notification } from '../../../../models/Notification';

interface NotificationListProps {
  onEventTypeIdsChange: (eventTypeIds: number[]) => void;
}

const NotificationList = ({ onEventTypeIdsChange }: NotificationListProps) => {
  const notifications = useAppSelector((state) => state.notificationsList?.notifications);
  const selectedAssetId = useAppSelector((state) => state.assetGroups.selectedAssetId);
  const filterId = useAppSelector((state) => state.notificationFilter.currentFilterId);

  const notificationsFiltered = () => {
    return (
      notifications?.filter((notification: Notification) => {
        const matchesAsset = !selectedAssetId || notification.NodeId === selectedAssetId;
        const matchesFilter = filterId === null || notification.EventTypeId == filterId;
        return matchesAsset && matchesFilter;
      }) || []
    );
  };

  useEffect(() => {
    const eventTypeIds = notifications.map((notification: Notification) => notification.EventTypeId);
    onEventTypeIdsChange(eventTypeIds);
  }, [notifications]);

  return (
    <>
      <div data-testid='notification-list' className='notification-wrapper'>
        <div>
          {notificationsFiltered().map((notification: any, index: number) => {
            return (
              <div key={index} className='comment-box notitification-with-buttons'>
                <h4>
                  <span>{notification.Date}</span>
                </h4>
                <div data-testid={`${index}-type`} className='notify-buttons red'>
                  <a>{notification.EventTypeName}</a>
                </div>
                <p data-testid={`${index}-note`}>{notification.Note}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default NotificationList;
