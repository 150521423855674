import React from 'react';
import ErrorIcon from '../../../../../images/error-icon.png'

interface AlertModelProps {
  isValidAction: boolean;
  isModalClose: () => void;
  isModalOk: () => void;
}

const AlertModel: React.FC<AlertModelProps> = ({ isValidAction, isModalClose, isModalOk }) => {
  return (
    <>
      {isValidAction && (
        <div className='modal-overlay delete-modal-page document-delete'>
          <div className='modal'>
            <div className='flex flex-row flex-grow profile-block'>
              <div className='notify-card'>
                <div className='header-delete'>
                  <div className='feature-img'>
                    <img src={ErrorIcon} alt='' />
                  </div>
                  <p className='title'>
                    Delete?
                  </p>
                  <p className='normal-text'>
                    Are you sure you want to remove this document?
                  </p>
                </div>
                <div className='delete-modal__footer'>
                  <button onClick={isModalClose} className='btn btn-dark-orange' >
                    Cancel
                  </button>
                  <button onClick={isModalOk} className='btn btn-primary'>
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AlertModel;
