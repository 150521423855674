import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks';
import { resetSetPointValues } from '../SetPointsSlice';
import BackupValues from './BackupValues';

interface SetPointDetailsProps {
  wellId: string;
  selectedSetpointGroup: number | null;
}

const SetPointDetails: React.FC<SetPointDetailsProps> = ({ selectedSetpointGroup, wellId }) => {
  const dispatch = useAppDispatch();
  const setPointValues = useAppSelector((state) => state.setPoint.apiResponse?.values);
  const setPointItems =
    setPointValues?.find((setPoint) => setPoint.setpointGroup === selectedSetpointGroup)?.setpoints || [];
  const setPointData = useAppSelector((state) => state.setPoint)

  useEffect(() => {
    return () => {
      dispatch(resetSetPointValues());
    };
  }, []);


  const getDeviceValue = (parameter: number) => {
    if (!setPointData.deviceValue) return ''
    return setPointData.deviceValue.find((item) => item.address === parameter)?.value
  }
  return (
    <>

      {setPointItems?.length ? (
        <div className='setpoint-details-card'>
          {/* <div className='table-header-section'></div> */}

          <div id='scheduleWidgetDiv' className='p-0 setpoint-details-div flex gap-3' style={{ width: '100%', height: '100%' }}>
            <table className='w-100 table border-collapse table-fixed'>
              <thead>
                <tr className='table-header-cell'>
                  <th className='table-header-item light-color'>Parameter</th>
                  <th className={`table-header-item ${!setPointData.deviceReadStatus && 'light-color'}`}>Description</th>
                  <th className={`table-header-item ${!setPointData.deviceReadStatus && 'light-color'}`}>Backup Date</th>
                  <th className='table-header-item back-up'>Backup</th>
                  <th className={`table-header-item ${!setPointData.deviceReadStatus && 'light-color'}`}>Device</th>
                </tr>
              </thead>
              <tbody>
                {setPointItems?.map((setPointItem, index) => (
                  <tr key={index} className='table-row-general'>
                    <td className='light-color'>{setPointItem.parameter}</td>
                    <td className={`${!setPointData.deviceReadStatus ? 'light-color' : ''}`}>{setPointItem.description}</td>
                    <td className={`${!setPointData.deviceReadStatus ? 'light-color' : ''}`}>{setPointItem.backupDate}</td>
                    <td className='back-up'>
                      <BackupValues setPointItem={setPointItem} wellId={wellId} />
                    </td>
                    <td className={`${!setPointData.deviceReadStatus ? 'light-color' : ''}`}>{setPointItem.device ?? getDeviceValue(Number(setPointItem.parameter))}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SetPointDetails;
