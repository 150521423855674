import { http } from "../../../../config/HttpCommon";

export const getDocuments = async (userId: string, wellId: string) => {
  const response = await http.get(`/getAssetDocument/${userId}/${wellId}`);
  return response.data;
};

export const downloadAssetDocument = async (id: string) => {
  const response = await http.post(`/downloadAssetDocument`, id, {
    responseType: 'blob', // Set the response type to blob
  });
  return response.data;
};
export const deleteAssetDocument = async (ids: string[]) => {
  const response = await http.post(`/deleteAssetDocument`, ids);
  return response.data;
};

export const createDocumentData = async (assetDocument: FormData) => {
  const config = {
    ...http,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  try {
    const response = await http.post('/createAssetDocument', assetDocument, config);
    return response.data;
  } catch (error) {
    console.error('Error creating Asset Document:', error);
    throw error;
  }
};
