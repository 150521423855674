import React from 'react';
import './GLAnalysis/GLAnalysis.scss';

interface CardHeaderInterface {
  value: string;
}
const CardHeader = (props: CardHeaderInterface) => {
  const { value } = { ...props };
  return (
    <div className="card-header">
      <div className="card-header-content">
        <div className="card-header-frame">
          <div className="card-header-text-container">
            <div className="card-header-text">{value}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardHeader;
