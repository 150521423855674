import { useEffect, useState } from 'react';
import { AppUser } from '../../../../user/model/AppUser';
import { fetchOtherTabDetails } from '../AnalysisSlice';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks';
// import PageLoader from '../../../../common/page-loader/PageLoader';
import { OtherTabDetailsProps } from '../analysisProps';
import '../analysis.scss';
import { updateAssetControlScanStatus } from '../../../../asset/AssetControlSlice';

const OtherTab = () => {
  // const { loading }  = useAppSelector((state)=> state.analysisTab);
  const dispatch = useAppDispatch();
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const [otherTabDetails, setOtherTabDetails] = useState<OtherTabDetailsProps | null>(null);
  const selectedAsset = useAppSelector((state) => state.assetGroups);
  const assetControlData = useAppSelector((state) => state?.assetControl)

  useEffect(() => {
    const wellName = selectedAsset?.selectedAssetName ?? '';
    if (wellName === '' || storedUser?.id === '') return;
    dispatch(fetchOtherTabDetails({ userId: storedUser.id, projectId: wellName, analysisType: 'other' }))
      .unwrap()
      .then((response: OtherTabDetailsProps) => {
        setOtherTabDetails(response);
      })
      .catch((error) => {
        console.error('Failed to fetch other tab details:', error);
      });
    if (assetControlData?.assetControlScanStatus)
      dispatch(updateAssetControlScanStatus(false))

  }, [dispatch, storedUser?.id, selectedAsset?.selectedAssetName, assetControlData?.assetControlScanStatus === true]);

  return (
    <>
      {/* {loading && <PageLoader />} */}
      <div className='analysis-content'>
        <div className='analysis-title'>Power conditions</div>
        <div className='cus-details'>
          <div>Supply power</div>
          <div>{otherTabDetails?.supply_Power}</div>
        </div>

        <div className='cus-details'>
          <div>Supply voltage / Hz</div>
          <div>{otherTabDetails?.supply_Voltage}</div>
        </div>

        <div className='cus-details'>
          <div>Available KVA</div>
          <div>{otherTabDetails?.available_KVA}</div>
        </div>

        <div className='cus-details'>
          <div>Controller</div>
          <div>{otherTabDetails?.controller}</div>
        </div>
      </div>

      <div className='analysis-content'>
        <div className='analysis-title'>Gas impurities</div>
        <div className='cus-details'>
          <div>Oxygen (O2)</div>
          <div>{otherTabDetails?.oxygen}</div>
        </div>

        <div className='cus-details'>
          <div>Nitrogen (N2)</div>
          <div>{otherTabDetails?.nitrogen}</div>
        </div>

        <div className='cus-details'>
          <div>H25</div>
          <div>{otherTabDetails?.h25}</div>
        </div>

        <div className='cus-details'>
          <div>CO2</div>
          <div>{otherTabDetails?.cO2}</div>
        </div>
      </div>

      <div className='analysis-content'>
        <div className='analysis-title'>Problems/Concerns</div>
        <div className='cus-details'>
          <div>Corrosion</div>
          <div>{otherTabDetails?.corrosion}</div>
        </div>

        <div className='cus-details'>
          <div>Paraffin</div>
          <div>{otherTabDetails?.paraffin}</div>
        </div>

        <div className='cus-details'>
          <div>Scale</div>
          <div>{otherTabDetails?.scale}</div>
        </div>

        <div className='cus-details'>
          <div>Sand/Solids</div>
          <div>{otherTabDetails?.sand_Solids}</div>
        </div>

        <div className='cus-details'>
          <div>Viscosity/Erosions</div>
          <div>{otherTabDetails?.viscosity_Erosions}</div>
        </div>
      </div>

      <div className='analysis-content'>
        <div className='analysis-title'>Other</div>
        <div className='cus-details'>
          <div>Packer required</div>
          <div>{otherTabDetails?.packer_Required}</div>
        </div>

        <div className='cus-details'>
          <div>Chemical</div>
          <div>{otherTabDetails?.chemical}</div>
        </div>

        <div className='cus-details'>
          <div>Y-tool</div>
          <div>{otherTabDetails?.y_Tool}</div>
        </div>

        <div className='cus-details'>
          <div>SCDA compatibility</div>
          <div>{otherTabDetails?.scada_Compatibility}</div>
        </div>
      </div>
    </>
  );
};

export default OtherTab;
