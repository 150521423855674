import React from 'react';
import { useTable, useSortBy } from 'react-table';
import './GroupStatus.scss';
import ChevronVertical from '../../images/chevron-selector-vertical.png';

const AssetDetailsTable: React.FC<CustomTableProps> = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );

  return (
    <div className="table-container">
      <table className="well-table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="table-header-cell">
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} className="table-header-item">
                  {column.render('Header')}
                  <span className='sort-image'>
                    <img src={ChevronVertical} className="chevron-vertical" />
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  let className = 'table-row-general';
                  let cellValue = cell.render('Cell');

                  switch (cell.column.Id) {
                    case 1:
                      className = 'table-row-name';
                      cellValue = (
                        <a href="#" className="table-row-link">
                          {cell.render('Cell')}
                        </a>
                      );
                      break;
                    case 7:
                      className = 'table-row-alarms';
                      cellValue = (
                        <div className="alarm-badges">
                          {cell.value?.map((alarm: any) => (
                            <div className="badge">
                              <span className="badge-text">{alarm}</span>
                            </div>
                          ))}
                        </div>
                      );
                      break;
                    // Add more cases as needed for other column Ids

                    default:
                      break;
                  }

                  return (
                    <td {...cell.getCellProps()} className={className}>
                      {cellValue}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

// Define the types for the columns and data props
interface CustomTableProps {
  columns: any;
  data: any;
}

export default AssetDetailsTable;
