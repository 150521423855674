import React, { useState, useRef, useEffect } from "react";
import { defaultStaticRanges, defineds, formateDate } from "./WellPerormanceTrendRanges";
import { addDays, endOfDay, format, startOfDay } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./wellperformance-trend-datepicker.scss";
import PropTypes from "prop-types";
import { DateRangePicker } from 'react-date-range';

interface WellPerormanceTrendDateRangeSelectorProps {
     isOpen?: boolean;
     ranges?: any;
     onChange?: any;
     onSubmit?: any;
     setShowCalendar?: any;
     rest?: any;
     setSelectedDateRange: any;
}

const WellPerormanceTrendDateRangeSelector: React.FC<WellPerormanceTrendDateRangeSelectorProps> = ({ ranges, onChange, onSubmit, setShowCalendar, setSelectedDateRange, ...rest }) => {
     const [calendar, setCalendar] = useState({
          startDate: startOfDay(addDays(new Date(), -6)),
          endDate: endOfDay(new Date()),
          key: "selection"
     })
     const dateRef = useRef<HTMLDivElement>(null);

     function formatDateDisplay(date: any, defaultText?: any) {
          if (!date) return defaultText;
          return format(date, "MM/DD/YYYY");
     }

     const handleSelect = (ranges: any) => {
          setCalendar(ranges.selection);
     };

     const handleApply = () => {
          setSelectedDateRange({
               startDate: calendar.startDate,
               endDate: calendar.endDate,
               key: calendar.key
          });
          setShowCalendar(false);
     };

     const handleOnClose = () => {
          console.log(ranges, onChange, onSubmit, rest, formatDateDisplay);
          setShowCalendar(false);
     }

     const handleClickOutside = (event: MouseEvent) => {
          if (dateRef.current && !dateRef.current.contains(event.target as Node)) {
               setShowCalendar(false);
          }
     };

     useEffect(() => {
          document.addEventListener('mousedown', handleClickOutside); 
          return () => {
               document.removeEventListener('mousedown', handleClickOutside);
          };
     }, []);

     return (
          <React.Fragment>
               <div className="date-picker__container" ref={dateRef} >
                    <DateRangePicker
                         onChange={handleSelect}
                         moveRangeOnFirstSelection={false}
                         months={2}
                         ranges={[calendar]}
                         direction="horizontal"
                         staticRanges={defaultStaticRanges}
                         minDate={defineds?.last90Days}
                         maxDate={defineds?.endOfToday}
                    />
                    <div className="text-right position-relative rdr-buttons-position d-flex">
                         <div className='date-picker__footer-container'>
                              <div className='date-picker__footer-left-container'></div>
                              <div className='date-picker__footer-rigth-container'>
                                   <div className="date-picker__input-container">
                                        <input value={formateDate(calendar.startDate)} className="date-picker__input" readOnly={true} /> <span>-</span> <input value={formateDate(calendar.endDate)} className="date-picker__input" readOnly={true} />
                                   </div>
                                   <div className="date-picker__button-container" >
                                        <button
                                             className="date-picker__btn-secondary"
                                             onClick={() => handleOnClose()}
                                        >
                                             Cancel
                                        </button>
                                        <button
                                             className="date-picker__btn-primary"
                                             onClick={handleApply}
                                        >
                                             Apply
                                        </button>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </React.Fragment>
     );
};

WellPerormanceTrendDateRangeSelector.defaultProps = {
     ranges: defaultStaticRanges
};

WellPerormanceTrendDateRangeSelector.propTypes = {
     /**
      * On Submit
      */
     onSubmit: PropTypes.func
};

export default WellPerormanceTrendDateRangeSelector;
