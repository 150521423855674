import { http } from '../../../../config/HttpCommon';

export const getHistoryById = async (wellName: string, startDate: string, endDate: string, paramIds: string) => {
  const response = await http.get(`/GetAssetHistorySparklineData?wellName=${wellName}&startDate=${startDate}&endDate=${endDate}&paramIds=${paramIds}`);
  return response.data;
};

export const getAssetTimeseriesChannelsData = async (wellName: string, startDate: string, endDate: string, ChannelIds: string) => {
  const response = await http.get(`/GetAssetTimeseriesChannelsData?AssetName=${wellName}&startDate=${startDate}&endDate=${endDate}&ChannelIds=${ChannelIds}`);
  return response.data;
};

export const getHistoryChartData = async (wellName: string, startDate: string, endDate: string) => {
  const response = await http.get(`/GetAssetHistoryChartData?wellName=${wellName}&startDate=${startDate}&endDate=${endDate}`);
  return response.data;
}

export const getMasterVariablesData = async (wellName: string) => {
  const response = await http.get(`/Mastervariables/${wellName}?channelType=standardparameters`);
  return response.data;
}

export const getHistoryChartFilterData = async () => {
  const response = await http.get(`/GetAssetTrends`);
  return response.data;
}