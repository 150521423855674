import React from 'react';
import './Admin.scss';
import AdminProfile from './AdminProfile';
import TabWithUrl from '../../../components/TabWithUrl';
import AdminNotifications from './AdminNotifications';
import { useAppSelector } from '../../../hooks/storeHooks';
import { AppUser } from '../model/AppUser';
import Loader from '../../common/page-loader/ComponentLoader';
// import PageLoader from '../../common/page-loader/PageLoader';

const Admin: React.FC = () => {
  const loggedInUser = useAppSelector((state) => state?.user?.loggedInUser);
  const loading = useAppSelector((state) => state?.user?.userListLoading);
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser | null = storedUserData ? JSON.parse(storedUserData) : null;
  const initialUserState = loggedInUser || storedUser || undefined;

  const tabs = [
    { label: 'Users', component: AdminProfile },
    { label: 'Alarms & Notifications', component: AdminNotifications },
  ];

  return (
   <>
    {/* {loading && <PageLoader />} */}
    <div className='group-status-container profile-container'>
      {loading ? (
          <div className='chart-loader flex items-center justify-center w-100'>
          <Loader />
        </div>
      ) : (
        <div className='profile-status-header profile-status-header-admin'>
        <div className='group-status-header group-status-header-admin'>
          <div className='group-status-content'>
            <div className='group-status-frame'>
              <div className='text-and-badge'>
                <div className='text'>Admin tools</div>
              </div>
            </div>
          </div>
        </div>
        <div className='divider'></div>
        <TabWithUrl initialTab={{ tab: 'Users' }} tabs={tabs} value={initialUserState?.id} />
      </div>
      )}
    </div>
   </>
  );
};

export default Admin;
