import { useAppDispatch } from '../../../../../hooks/storeHooks';
import ErrorIcon from '../../../../../images/error-icon.png'
import { disablePingCommFailedStatus } from '../../../../asset/AssetControlSlice';

interface PingCommDataModalProps {
    wellName: string | undefined
    ipAddress: string | undefined
}

const PingCommFailedModal: React.FC<PingCommDataModalProps> = ({ wellName, ipAddress }) => {
    const dispatch = useAppDispatch()
    return (
        <>
            <div className='modal-overlay ping-data-modal'>
                <div className='modal'>
                    <div className='flex flex-row flex-grow profile-block'>
                        <div className='notify-card'>
                            <div className='content-div'>
                                <div className='header-div'>
                                    <span className='icon'>
                                        <img src={ErrorIcon} alt='' />
                                    </span>
                                    <span className='heading'>
                                        Failed
                                    </span>
                                    <span className='asset-name'>
                                        {wellName}
                                    </span>
                                    <span className='connection-description'>
                                        TCP connect to {ipAddress ?? ''}
                                    </span>
                                </div>
                                <div className='connection-div'>
                                    <div className='connection'>
                                        <span className='heading'>Connection 1</span><span className='value'>timed out</span>
                                    </div>
                                    <div className='connection'>
                                        <span className='heading'>Connection 2</span><span className='value'>refused by host</span>
                                    </div>
                                    <div className='connection'>
                                        <span className='heading'>Connection 3</span><span className='value'>refused by host</span>
                                    </div>
                                    <div className='connection'>
                                        <span className='heading'>Connection 4</span><span className='value'>timed out</span>
                                    </div>
                                </div>
                                <div className='summary-div'>
                                    <div className='summary-heading'>
                                        Summary
                                    </div>
                                    <div className='data'>
                                        <span className='heading'>Connection Attempts</span> <span className='value'>4</span>
                                    </div>
                                    <div className='data'>
                                        <span className='heading'>Connections Made</span> <span className='value'>0</span>
                                    </div>
                                    <div className='data'>
                                        <span className='heading'>Min Time</span> <span className='value'>- ms</span>
                                    </div>
                                    <div className='data'>
                                        <span className='heading'>Max Time</span> <span className='value'>- ms</span>
                                    </div>
                                    <div className='data'>
                                        <span className='heading'>Avg Time</span> <span className='value'>- ms</span>
                                    </div>
                                </div>
                            </div>
                            <div className='button-div' onClick={() => { dispatch(disablePingCommFailedStatus()) }}>
                                <button>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PingCommFailedModal;
