import axios from 'axios';
import { getAPIBaseURL } from '../../utilities/BaseURLUtility';

export const fetchInitialState = async () => {
  try {
    const response = await axios.get(`${getAPIBaseURL()}/GroupStatus`);

    const columns = response.data.Values.Columns.map((column: any) => ({
      Header: column.Name,
      accessor: column.Name,
      Id: column.Id,
    }));

    // Extract the row values from the data
    const rowValues = response.data.Values.Rows.map((row: any) =>
      row.reduce((acc: any, cell: any) => {
        const columnName = response.data.Values.Columns.find((column: any) => column.Id === cell.ColumnId).Name;
        acc[columnName] = cell.Value;
        return acc;
      }, {}),
    );
    response.data.Values.Columns = columns;
    response.data.Values.Rows = rowValues;
    return response.data;
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return [];
  }
};

export const fetchTableViewInitialState = async () => {
  try {
    const response = await axios.get(`${getAPIBaseURL()}/AvailableTableViews`);
    return response.data;
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return [];
  }
};
