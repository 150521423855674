import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { store } from './store';
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import * as am5 from "@amcharts/amcharts5";
import MobileDeviceWarning from "./features/common/MobileDeviceMessage/MobileDeviceWarning";

const amChartsLicense = process.env.REACT_APP_AM_CHARTS_LICENSE;

function Index() {
  return (
    // <React.StrictMode> </React.StrictMode>
      <Provider store={store}>
        <MobileDeviceWarning/>
        <App />
      </Provider>
  );
}

if (!amChartsLicense) {
  window.alert('Valid amCharts license not provided.');
} else {
  am5.addLicense(amChartsLicense as string);

  const rootElement = document.getElementById('root');

  if (rootElement !== null) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(<Index />);
  }
}

reportWebVitals();
