import './UITip.css';

interface UITipDetailsProps {
  key: number;
  image: any;
  description: string;
}

interface UITipProps {
  uiTipDetails: UITipDetailsProps[];
  title: string;
  image: any;
  nextButtonClicked: (name: string) => void;
  previousButtonClicked: (name: string) => void;
  offButtonClicked: (name: string) => void;
}

function UITip(props: UITipProps) {
  const { uiTipDetails, title, image, nextButtonClicked, previousButtonClicked, offButtonClicked } = props;
  const shouldRenderPreviousButton = title !== 'Asset control board';

  return (
    <div className="ui-tip">
      <div className="ui-tip-header">
        <img src={image} className="ui-tip-title-icon" />
        <p className="ui-tip-title">{title}</p>
      </div>
      <div className="ui-tip-body">
        {uiTipDetails.map((uiTipDetail: UITipDetailsProps) => {
          const { key, image, description } = { ...uiTipDetail };

          return (
            <div key={key} className="ui-tip-body">
              <div className="ui-tip-body-item">
                <img src={image} className="ui-tip-icon" />
                <p className="ui-tip-description">{description}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="ui-tip-button-body">
        <div className="ui-tip-nav-button-body">
          <div className="ui-tip-nav-button-item">
            {shouldRenderPreviousButton && (
              <button
                data-testid="ui-tip-previous-button"
                className="ui-tip-button"
                onClick={() => previousButtonClicked(title)}
              >
                Previous Tip
              </button>
            )}
          </div>
          <div className="ui-tip-nav-button-item">
            <button
              data-testid="ui-tip-next-button"
              className="ui-tip-button ui-tip-button-next"
              onClick={() => nextButtonClicked(title)}
            >
              Next Tip
            </button>
          </div>
        </div>
        <button
          data-testid="ui-tip-off-button"
          className="ui-tip-button ui-tip-button-off"
          onClick={() => offButtonClicked(title)}
        >
          Turn Off Tips
        </button>
      </div>
    </div>
  );
}

export default UITip;
