import './Breadcrumb.scss';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import Dropdown from '../../common/Dropdown/Dropdown';
import { updateSelectedAsset } from '../AssetGroupsSlice';
import { useLocation, useNavigate } from 'react-router-dom';

const Breadcrumb: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentAssetURLPath = location.pathname.split('/')[2];

  const selectedGroupName = useAppSelector((state) => state.assetGroups?.selectedGroup);
  const selectedAssetId = useAppSelector((state) => state.assetGroups?.selectedAssetId);

  const assetGroup: any = useAppSelector(
    (state) => state.assetGroups?.groups?.find((item) => item.assetGroupName === selectedGroupName),
  );

  const handleAssetIdChange = (assetId: string | undefined) => {
    dispatch(updateSelectedAsset({ assetId: assetId, assetGroupName: selectedGroupName, searchString: undefined }));
    navigate(`/asset/${currentAssetURLPath}/${assetId}`);
  };

  const handleGroupNameClick = () => {
    dispatch(updateSelectedAsset({ assetId: undefined, assetGroupName: selectedGroupName, searchString: undefined }));
    navigate(`/group/status`);
  };

  return (
    <div className='breadcrumb'>
      <div className='group'>
        <button
          className='group-btn'
          onClick={() => {
            handleGroupNameClick();
          }}
        >
          {selectedGroupName}\
        </button>
      </div>
      <div className='assets'>
        {assetGroup && (
          <Dropdown
            assets={assetGroup.assetIds}
            showEyeIcon={false}
            defaultOption={selectedAssetId || undefined}
            onChange={handleAssetIdChange}
          />
        )}
      </div>
    </div>
  );
};

export default Breadcrumb;
