import React, { useLayoutEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import { HistoryDetailsProps } from './HistoryDetails';

interface ChartDataProps {
  date: number;
  value: number;
}

interface LineChartProps {
  chartName: string;
  index: number;
  unitOfMeasure?: string;
  chartData: HistoryDetailsProps[] | null;
  colorCombination: any;
  short_UnitOfMeasure: any,
  selectedDate: {
    startDate: Date | null,
    endDate: Date | null
  }
}

const LineChart: React.FC<LineChartProps> = ({ chartName, index, chartData, colorCombination, short_UnitOfMeasure, selectedDate }) => {
  const chartId = `chart-${chartName}-${index}-${Math.random()}`;

  function setToStartOfDay(date: any) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
  }
  function setToEndOfDay(date: any) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
  }
  // Ensure selectedDateRange.startDate is at the start of the day
  const startDate = setToStartOfDay(selectedDate.startDate);
  // Ensure selectedDateRange.endDate is at the end of the day
  const endDate = setToEndOfDay(selectedDate.endDate);

  useLayoutEffect(() => {
    if (!chartData) return;
    const root = am5.Root.new(chartId);

    root.setThemes([
      am5themes_Dark.new(root)
    ]);

    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: true,
      panY: true,
      wheelX: 'none',
      wheelY: 'zoomX',
      pinchZoomX: true,
      maxTooltipDistance: 0,
    }));

    const xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      maxDeviation: 0.2,
      groupData: false,
      extraMax: 0,
      extraMin: 0,
      strictMinMax: true,
      baseInterval: { timeUnit: 'minute', count: 1 },
      renderer: am5xy.AxisRendererX.new(root, {
        minGridDistance: 100
      })
    }));

    if (selectedDate && selectedDate?.startDate)
      xAxis.set('min', new Date(startDate).getTime())
    if (selectedDate && selectedDate?.endDate)
      xAxis.set('max', new Date(endDate).getTime())

    xAxis.get('renderer').labels.template.set('paddingTop', 15);

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0.2,
      extraMax: 0,
      extraMin: 0,
      renderer: am5xy.AxisRendererY.new(root, {})
    }));

    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        behavior: 'none',
      }),
    );
    cursor.lineY.set('visible', false);
    cursor.lineX.setAll({
      visible: true,
      stroke: am5.color('#ADD8E6'),
      strokeWidth: 2,
    });

    cursor?.lineY.setAll({
      strokeDasharray: [2, 2],
    });

    const tooltip = am5.Tooltip.new(root, {
      getFillFromSprite: false,
      autoTextColor: false,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
    });


    tooltip?.get("background")?.setAll({
      fill: am5.color("#001023"),
      strokeOpacity: 0
    });

    chart.plotContainer.set("tooltipPosition", "pointer");
    chart.plotContainer.set("tooltipHTML", "<div style='overflow:visible' >" + "Placeholder" + "</div>");
    chart.plotContainer.set("tooltip", tooltip);


    function createSeries(
      name: string,
      color: string,
      data: ChartDataProps[],
    ) {
      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'value',
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            forceHidden: true,
          }),
          stroke: am5.color(color),
          snapTooltip: true,
        }),
      );

      series.strokes.template.set('strokeWidth', 2);


      series.bullets.push(() => {
        const circle = am5.Circle.new(root, {
          radius: 0,
          fill: am5.color('#0000'),
          stroke: am5.color('#fff'),
          strokeWidth: 5,
          visible: true,
        });

        circle.states.create('hover', {
          radius: 7,
        });

        return am5.Bullet.new(root, {
          locationX: 0.5,
          sprite: circle,
        });
      });

      cursor.events.on('cursormoved', cursorMoved);

      let previousBulletSprites: any = [];
      function cursorMoved() {
        for (let i = 0; i < previousBulletSprites?.length; i++) {
          previousBulletSprites[i]?.unhover();
        }
        previousBulletSprites = [];
        chart.series.each(function (series) {
          const dataItem = series?.get('tooltip')?.dataItem;
          if (dataItem) {
            const bulletSprite = dataItem?.bullets?.length && dataItem?.bullets[0]?.get('sprite');
            bulletSprite && bulletSprite.hover();
            previousBulletSprites.push(bulletSprite);
          }
        });


        let show = true;
        let html = `<div style="width:auto;background:#001023;border:1px solid #4A5463;border-radius:5px;display:flex;flex-direction:column;font-size: 14px;color: #F7F9F9;font-family: 'Mulish';font-weight: 400">`;
        chart.series.each(function (series) {
          const tooltipDataItem = series.get("tooltipDataItem");
          if (tooltipDataItem) {
            if (show) {
              const valueX = tooltipDataItem.get("valueX");
              if (valueX != null) {
              const date: any = new Date(valueX);
              const dateOptions: any = { weekday: 'long', month: 'short', day: 'numeric' };
              const formattedDate = date.toLocaleDateString(undefined, dateOptions);
              const timeOptions: any = { hour: '2-digit', minute: '2-digit', second: '2-digit'};
              const formattedTime = date.toLocaleTimeString(undefined, timeOptions);
              const datetime = `${formattedDate}, ${formattedTime}`;
              html += `<div style="border-bottom: 1px solid #4A5463;padding:10px"> ${datetime}</div>`;
              show = false;
              }
            }

            const valueY = tooltipDataItem.get("valueY");
            const valueYText = valueY == null ? "" : valueY.toFixed(2);
            const seriesName = series.get("name") || "Unnamed Series"; // Fallback for series name
            const seriesColor = series.get("stroke")?.toString() || "#000000";
            html += `<div style="display: flex; align-items: center;flex-direction: row;gap: 20px;justify-content: space-between;padding:10px">
            <div style="height:24px;align-items: center;display: flex">
            <span style="border:1px solid ${seriesColor};height:24px;margin-right: 5px;"></span>
            <span>${seriesName}</span>
            </div>
            <div>${valueYText} ${short_UnitOfMeasure}</div>
            </div>`;
          }

        });

        html += "</div>"; // Ensure the closing div is added
        chart.plotContainer.set("tooltipHTML", html);
      }
      series.data.setAll(data);
    }

    // const seriesArray :any[]=[]
    chartData?.forEach((element) => {
      const data = element?.dataPoints && element?.dataPoints?.map((chart) => {
        return { date: new Date(chart?.date).getTime(), value: Number(chart?.value) }
      });

      const sortedData = data?.sort((a, b) => a.date - b.date)
      const colordata = colorCombination[element.trendName] ? colorCombination[element.trendName] : '#60BFDA';
      createSeries(
        element?.trendName,
        colordata,
        sortedData ?? [],
      );
    });

    return () => {
      root.dispose();
    };
  }, [chartData]);

  return <div id={chartId} style={{ width: "100%", height: "200px" }} className={`sparkline-chart ${chartData ? '' : 'center-content'}`}>
    {!chartData && <p>No data</p>}
  </div>;
};

export default LineChart;