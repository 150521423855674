import React from 'react';
import chevrondown from '../../../images/Carrot.png';
import filterfunnel from '../../../images/filter-funnel-01.png';
import './UIEyeAndFilterButtons.scss';

function UIFilterButton() {
  return (
    <button
      className="toolbar-eye-btn"
      onClick={() => {
        alert('Filter Button Clicked');
      }}
    >
      <img src={filterfunnel} alt="filter funnel icon" />
      <img src={chevrondown} className='chevron-icon' />
    </button>
  );
}

export default UIFilterButton;
