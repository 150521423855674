import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAssetDetail, getUserDetailById } from './AssetDetailService';
import { AssetDetailsResponseProps, UserDetailsProps } from './AssetDetailProps';

interface AssetDetailStateProps {
  loading: boolean;
  assetDetailLoading: boolean;
  message: string | null;
  assetDetail: AssetDetailsResponseProps | null;
  userDetail: UserDetailsProps | null;
}

const initialState: AssetDetailStateProps = {
  loading: false,
  assetDetailLoading: false,
  message: null,
  assetDetail: null,
  userDetail: null
};

export const fetchAssetDetail = createAsyncThunk(
  'events/fetchAssetDetail',
  async ({ userId, wellName }: { userId: string; wellName: string; }) => {
    const response = await getAssetDetail(userId, wellName);
    return response;
  },
);

export const fetchUserDetailById = createAsyncThunk(
  'events/fetchUserDetailById',
  async ({ id }: { id: string; }) => {
    const response = await getUserDetailById(id);
    return response;
  },
);

const assetDetailSlice = createSlice({
  name: 'assetDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle the fetchAssetDetail fulfilled action
    builder.addCase(fetchAssetDetail.pending, (state) => {
      state.loading = true;
      state.assetDetailLoading = true;
    });
    builder.addCase(fetchAssetDetail.fulfilled, (state, action: PayloadAction<AssetDetailsResponseProps>) => {
      state.assetDetail = action.payload;
      state.loading = false;
      state.assetDetailLoading = false;
    });
    builder.addCase(fetchAssetDetail.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
      state.assetDetailLoading = false;
    });

    // Handle the fetchUserDetailById fulfilled action
    builder.addCase(fetchUserDetailById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserDetailById.fulfilled, (state, action: PayloadAction<UserDetailsProps>) => {
      state.userDetail = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchUserDetailById.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
    });
  },
});

export default assetDetailSlice.reducer;