import { useEffect, useState } from 'react';
import { AppUser } from '../../../../user/model/AppUser';
import { fetchWellTabDetails } from '../AnalysisSlice';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks';
// import PageLoader from '../../../../common/page-loader/PageLoader';
import { WellTabDetailsProps } from '../analysisProps';
import '../analysis.scss';
import { updateAssetControlScanStatus } from '../../../../asset/AssetControlSlice';

const WellTab = () => {
  // const { loading }  = useAppSelector((state)=> state.analysisTab);
  const dispatch = useAppDispatch();
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const [wellTabDetails, setWellTabDetails] = useState<WellTabDetailsProps | null>(null);
  const selectedAsset = useAppSelector((state) => state.assetGroups);
  const assetControlData = useAppSelector((state) => state?.assetControl)

  useEffect(() => {
    const wellName = selectedAsset?.selectedAssetName ?? '';
    if (wellName === '' || storedUser?.id === '') return;
    dispatch(fetchWellTabDetails({ userId: storedUser.id, projectId: wellName, analysisType: 'well' }))
      .unwrap()
      .then((response: WellTabDetailsProps) => {
        setWellTabDetails(response);
      })
      .catch((error) => {
        console.error('Failed to fetch well tab details:', error);
      });
    if (assetControlData?.assetControlScanStatus)
      dispatch(updateAssetControlScanStatus(false))

  }, [dispatch, storedUser?.id, selectedAsset?.selectedAssetName, assetControlData?.assetControlScanStatus === true]);

  return (
    <>
      {/* {loading && <PageLoader />} */}
      <div className='analysis-content'>
        <div className='analysis-title'>Surface conditions</div>
        <div className='cus-details'>
          <div>Tubing pressure*</div>
          <div>{wellTabDetails?.tubingPressure ? `${wellTabDetails?.tubingPressure} psi` : ""}</div>
        </div>

        <div className='cus-details'>
          <div>Casing pressure</div>
          <div>{wellTabDetails?.casingPressure ? `${wellTabDetails?.casingPressure} psi` : ""}</div>
        </div>
      </div>

      <div className='analysis-content'>
        <div className='analysis-title'>IPR</div>
        <div className='cus-details'>
          <div>Inflow type</div>
          <div>{wellTabDetails?.inflowType}</div>
        </div>
      </div>

      <div className='analysis-content'>
        <div className='analysis-title'>Pressure</div>
        <div className='cus-details'>
          <div>Static bottom hole pressure*</div>
          <div>{wellTabDetails?.staticBHP ? `${wellTabDetails?.staticBHP} psi` : ""}</div>
        </div>

        <div className='cus-details'>
          <div>Flowing bottom hole pressure*</div>
          <div>{wellTabDetails?.flowingBHP ? `${wellTabDetails?.flowingBHP} psi` : ""}</div>
        </div>

        <div className='cus-details'>
          <div>Test flow rate*</div>
          <div>{wellTabDetails?.testFlowRate ? `${wellTabDetails?.testFlowRate} bpd` : ""}</div>
        </div>
      </div>

      <div className='analysis-content'>
        <div className='analysis-title'>Production</div>
        <div className='cus-details'>
          <div>Desired production rate*</div>
          <div>{wellTabDetails?.desiredProductionRate ? `${wellTabDetails?.desiredProductionRate} bpd` : ""}</div>
        </div>

        <div className='cus-details'>
          <div>Pump setting @ measured depth</div>
          <div>{wellTabDetails?.pumpSettingMeasuredDepth ? `${wellTabDetails?.pumpSettingMeasuredDepth} ft` : ""}</div>
        </div>

        <div className='cus-details'>
          <div>Pump setting @ vertical depth</div>
          <div>{wellTabDetails?.pumpSettingVerticalDepth ? `${wellTabDetails?.pumpSettingVerticalDepth} ft` : ""}</div>
        </div>

        <div className='cus-details'>
          <div>Reservoir DATUM*</div>
          <div>{wellTabDetails?.reservoirDatum ? `${wellTabDetails?.reservoirDatum} ft` : ""}</div>
        </div>

        <div className='cus-details'>
          <div>Flop @ desired production rate</div>
          <div>{wellTabDetails?.flopDesiredProductionRate ? `${wellTabDetails?.flopDesiredProductionRate} ft` : ""}</div>
        </div>
      </div>
    </>
  );
};

export default WellTab;
