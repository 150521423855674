import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import axios from 'axios';
import { getAPIBaseURL } from '../../utilities/BaseURLUtility';



interface State {
  loading: boolean;
  data?: IRodLiftStatusWellModel;
  error?: string;
}

const initialState: State = { loading: false };

export const rodLiftWellStatusSlice = createSlice({
  name: "rodliftwellstatus",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitialStateAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInitialStateAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchInitialStateAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Async thunk to fetch the initial state data
export const fetchInitialStateAsync = createAsyncThunk('assetStatusSlice/fetchInitialState', async () => {
  const data = await fetchInitialState();
  return data;
},
);

export const fetchInitialState = async () => {
  try {
    const apiEndpoint = `${getAPIBaseURL()}/rodliftwellstatus`;

    const response = await axios.get(apiEndpoint);
    return response.data;
  } catch (error) {
    console.error('Error fetching initial state:', error);
    return {};
  }
};


export const selectRodLiftWellStatus = (state: RootState) => state.rodliftwellstatus;

export default rodLiftWellStatusSlice.reducer;