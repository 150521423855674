// AlarmSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { executeWellControlActionAsync, getAssetControl, getPingDetail, getWellControlActionAsync } from './services/AssetDataService';
import { Action } from '../navigation/components/NavigationBar';

interface WellControlAction {
    id: number;
    name: string;
}
interface WellStatus {
    wellName: string | undefined,
    runStatus: string,
    enabled: number,
    ipAddress: string
}
interface PingComm {
    connection1: string,
    connection2: string,
    connection3: string,
    connection4: string,
    summary: {
        connectionAttempts: number,
        connectionsMade: number,
        minTime: number,
        maxTime: number,
        avgTime: number
    }
}
export interface AssetState {
    wellControlActions: WellControlAction[];
    wellStatus: WellStatus | null
    operatingMode: string | undefined
    assetControlLoading: boolean
    assetPingLoading: boolean
    error: string | null
    assetControlScanStatus: boolean
    executeControlActionShowSuccess: boolean;
    executeControlScanActionLoading: boolean;
    executeControlActionShowFailure: boolean;
    executeControlActionErrorMessage: string | undefined;
    executeControlActionMessage: string | undefined;
    executeControlActionOnReturnSuccess: boolean;
    executeControlActionOnReturnSuccessMessage: string | undefined;
    executeControlActionOnReturnError: boolean;
    executeControlActionOnReturnMessage: string | undefined;
    canConfigWell: boolean;
    pingCommData: PingComm | null
    pingCommDataShowStatus: boolean
    pingCommFailedStatus: boolean
    startActionProgressStatus: boolean,
    stopActionProgressStatus: boolean,
    clearAlarmActionProgressStatus: boolean
}

const AssetState: AssetState = {
    wellControlActions: [],
    wellStatus: null,
    operatingMode: undefined,
    assetControlLoading: false,
    assetPingLoading: false,
    executeControlScanActionLoading: false,
    error: null,
    assetControlScanStatus: false,
    executeControlActionShowSuccess: false,
    executeControlActionShowFailure: false,

    executeControlActionErrorMessage: undefined,
    executeControlActionMessage: undefined,

    executeControlActionOnReturnError: false,
    executeControlActionOnReturnMessage: undefined,

    executeControlActionOnReturnSuccess: false,
    executeControlActionOnReturnSuccessMessage: undefined,
    canConfigWell: false,
    pingCommData: null,
    pingCommDataShowStatus: false,
    pingCommFailedStatus: false,
    startActionProgressStatus: false,
    stopActionProgressStatus: false,
    clearAlarmActionProgressStatus: false
};
interface UpdateStatusPayload {
    propertyName: keyof AssetState;
    value: boolean;
}

interface ExecuteWellControlActionArgs {
    assetId: string;
    controlType: string;
    socketId: string;
    controlActionName: string;
    wellName: string | undefined;
}





export const fetchAssetControl = createAsyncThunk('getAssetControl', async (wellName: string) => {
    return await getAssetControl(wellName);
})


export const fetchPingDetails = createAsyncThunk('getPingDetail', async ({ wellName, cancelToken }: { wellName: string, cancelToken: any }) => {
    return await getPingDetail(wellName, cancelToken);
})


export const executeWellControlAction = createAsyncThunk(
    'AssetData/executeWellControlAction',
    async (args: ExecuteWellControlActionArgs) => {
        const { assetId, controlType, socketId, controlActionName, wellName } = args;
        return await executeWellControlActionAsync(assetId, controlType, socketId, controlActionName, wellName);
    },
);

export const fetchWellControlActions = createAsyncThunk(
    'AssetData/getWellControlAction',
    async (assetId: string) => {
        return await getWellControlActionAsync(assetId);
    },
);

const assetControlSlice = createSlice({
    name: 'assetControl',
    initialState: AssetState,
    reducers: {
        setExecuteControlActionOnReturnSuccessful: (state, action) => {
            state.executeControlActionOnReturnSuccess = true;
            state.executeControlActionOnReturnSuccessMessage = action.payload.message;
            state.executeControlActionShowSuccess = false

            const payload = JSON.parse(action.payload.data)
            if (Number(payload?.Value?.ActionType) === Action.ProcessDeviceScan) {
                state.executeControlScanActionLoading = false
            } else if (Number(payload?.Value?.ActionType) === Action.StartWell) {
                state.startActionProgressStatus = false
            } else if (Number(payload?.Value?.ActionType) === Action.StopWell) {
                state.stopActionProgressStatus = false
            } else if (Number(payload?.Value?.ActionType) === Action.ClearAlarms) {
                state.clearAlarmActionProgressStatus = false
            }

        },
        setExecuteControlActionOnReturnError: (state, action) => {
            state.executeControlActionOnReturnError = true;
            state.executeControlActionOnReturnMessage = action.payload.message;
            state.executeControlScanActionLoading = false
            state.executeControlActionShowSuccess = false
            state.startActionProgressStatus = false
            state.stopActionProgressStatus = false
            state.clearAlarmActionProgressStatus = false
        },
        updateAssetControlActionStatus: (state, action: PayloadAction<UpdateStatusPayload>) => {
            const { propertyName, value } = action.payload;
            // Check if the property exists and is of type boolean
            if (propertyName in state && typeof (state[propertyName as keyof AssetState]) === 'boolean') {
                // Use type assertion to safely assign the value
                (state[propertyName as keyof AssetState] as boolean) = value;
            }
        },
        updateAssetControlScanStatus: (state, action) => {
            state.assetControlScanStatus = action.payload
        },
        updateAssetStatus: (state, action) => {
            const assetStatus = { ...state.wellStatus, runStatus: action.payload } as WellStatus
            state.wellStatus = assetStatus
        },
        hidePingCommLoader: (state) => {
            state.assetPingLoading = false
        },
        disablePingCommDataShowStatus: (state) => {
            state.pingCommDataShowStatus = false
        },
        disablePingCommFailedStatus: (state) => {
            state.pingCommFailedStatus = false
        },
    },
    extraReducers: (builder) => {
        // fetchWellControlActions thunk cases
        builder.addCase(fetchAssetControl.pending, (state) => {
            state.assetControlLoading = true;
            state.wellStatus = null
            state.operatingMode = undefined
            state.wellControlActions = [];
            state.canConfigWell = false;
        })
        builder.addCase(fetchAssetControl.fulfilled, (state, action) => {
            state.assetControlLoading = false;
            state.wellStatus = action.payload.assetStatus
            state.operatingMode = action.payload.operatingMode?.value
            state.wellControlActions = action.payload.controlActions.wellControlActions;
            state.canConfigWell = action.payload.controlActions.canConfigWell;
        })
        builder.addCase(fetchAssetControl.rejected, (state, action) => {
            state.assetControlLoading = false;
            state.error = action.error.message ?? '';
            state.wellStatus = null
            state.operatingMode = undefined
            state.wellControlActions = [];
            state.canConfigWell = false;
        })


        // execute well control action thunk cases

        builder.addCase(executeWellControlAction.pending, (state, action) => {
            const { controlActionName } = action.meta.arg;
            state.executeControlActionShowSuccess = false;
            state.executeControlActionShowFailure = false;
            state.executeControlActionOnReturnSuccess = false;
            state.executeControlActionOnReturnError = false;

            state.executeControlScanActionLoading = false
            state.assetControlScanStatus = false
            if (controlActionName === 'Scan')
                state.executeControlScanActionLoading = true
            else if (controlActionName === 'Start systems')
                state.startActionProgressStatus = true
            else if (controlActionName === 'Stop systems')
                state.stopActionProgressStatus = true
            else if (controlActionName === 'Clear alarms')
                state.clearAlarmActionProgressStatus = true

        })
        builder.addCase(executeWellControlAction.fulfilled, (state, action) => {
            const { controlActionName } = action.meta.arg;
            if (controlActionName !== 'Scan') {
                state.executeControlActionShowSuccess = true;
                state.executeControlActionMessage = action?.payload;
            }
        })
        builder.addCase(executeWellControlAction.rejected, (state, action) => {
            state.executeControlActionShowFailure = true;
            state.executeControlActionErrorMessage = action.error.message;
            const { controlActionName } = action.meta.arg;
            if (controlActionName === 'Scan')
                state.executeControlScanActionLoading = false
            else if (controlActionName === 'Start systems')
                state.startActionProgressStatus = false
            else if (controlActionName === 'Stop systems')
                state.stopActionProgressStatus = false
            else if (controlActionName === 'Clear alarms')
                state.clearAlarmActionProgressStatus = false
        })

        builder.addCase(fetchPingDetails.pending, (state) => {
            state.assetPingLoading = true;
            state.pingCommDataShowStatus = false
            state.pingCommFailedStatus = false

        })
        builder.addCase(fetchPingDetails.fulfilled, (state, action) => {
            state.assetPingLoading = false;
            state.pingCommData = action.payload
            state.pingCommDataShowStatus = true
            state.pingCommFailedStatus = false

        })
        builder.addCase(fetchPingDetails.rejected, (state, action) => {
            state.assetPingLoading = false;
            state.error = action.error.message ?? '';
            if (action.error.message !== "Ping is canceled")
                state.pingCommFailedStatus = true

        })

    }
});

export const { setExecuteControlActionOnReturnSuccessful, setExecuteControlActionOnReturnError, updateAssetControlActionStatus, updateAssetControlScanStatus, updateAssetStatus, hidePingCommLoader, disablePingCommDataShowStatus, disablePingCommFailedStatus } = assetControlSlice.actions;
export default assetControlSlice.reducer;