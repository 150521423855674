import React from 'react';
import ErrorIcon from '../../../../../images/error-icon.png'
import StartAlert from '../../../../../images/asset-start-alert.svg'
import ClearAlarmAlert from '../../../../../images/clear-alarm-alert.svg'

interface ConfirmationModalProps {
    assetControlProps: {
        action: string,
        heading: string,
        description: string,
        controlType: string
    }
    isModalClose: () => void;
    isModalOk: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ assetControlProps, isModalClose, isModalOk }) => {
    return (
        <>
            {/* {isValidAction && ( */}
            <div className='modal-overlay asset-confirmation-modal'>
                <div className='modal'>
                    <div className='flex flex-row flex-grow profile-block'>
                        <div className='notify-card'>
                            <div className='content-div'>
                                <div className={`img ${assetControlProps?.controlType}`}>
                                    <img src={assetControlProps?.action === 'Start' ? StartAlert : assetControlProps?.action === 'Clear' ? ClearAlarmAlert : ErrorIcon} alt='' />
                                </div>
                                <div className='heading'>
                                    {assetControlProps?.heading}
                                </div>
                                <div className='description'>
                                    {assetControlProps?.description}
                                </div>
                            </div>
                            <div className='button-div'>
                                <button onClick={isModalClose} className='btn cancel'>
                                    Cancel
                                </button>
                                <button onClick={isModalOk} className={`btn ${assetControlProps?.controlType}`}>
                                    {assetControlProps?.action}
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* )} */}
        </>
    );
};

export default ConfirmationModal;
