import './CardDateItem.scss';
import brush from '../../../images/brush-03.png';

interface CardDateItemProps {
  date: string;
  name: string;
  isSelected: boolean;
  onClickAction: (id: string) => void;
  showIcon: boolean;
}

export const CardDateItem = (props: CardDateItemProps) => {
  const styles = {
    background: '#005F7C',
  };

  return (
    <div className="card-date-item-container" onClick={() => props.onClickAction(props.date)}>
      <div style={props.isSelected ? styles : {}} className="card-date-item-chart-color" />
      <div
        className={
          props.isSelected
            ? 'card-date-item-item-container card-date-item-item-container-active'
            : 'card-date-item-item-container'
        }
      >
        <div className="card-date-item-text-container">
          <label className="card-date-item-text card-date-item-card-type-text"> {props.name ?? '-'} </label>
          <label className="card-date-item-text card-date-item-card-date-text"> {props.date ?? '-'} </label>
        </div>
        {props.showIcon && props.isSelected ? (
          <div className="brush-icon-container">
            <img className="brush-icon" src={brush} />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CardDateItem;
