import React from 'react';
import chevrondown from '../../../images/Carrot.png';
import settings from '../../../images/gearIcon.png';
import './UIEyeAndFilterButtons.scss';

function UISettingsButton() {
  return (
    <button
      className="toolbar-eye-btn"
      onClick={() => {
        alert('Settings Button Clicked');
      }}
    >
      <img src={settings} alt="Settings icon" />
      <img src={chevrondown} className="chevron-icon" />
    </button>
  );
}

export default UISettingsButton;
