import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  downloadDashboardCSV,
  getAssetListById,
  getJobSchedule,
  getPerformanceSnapshot,
  getWellProductionTrends,
  getWellPerformanceTrends,
  getPerformanceSnapshotByName,
  getTicketsById,
  getUrls,
} from './DashboardServices';
import { TicketDetails } from './model/TicketDetails';
import { EventSchedule } from './model/EventSchedule';
import { WellByStatus } from './model/WellByStatus';
import { WellPerformanceTrend } from './model/WellPerformanceTrend';
import { WellProductionTrend } from './model/WellProductionTrend';
import { AssetEventSchedule } from './model/AssetEventSchedule';
import { JobSchedule } from './model/JobSchedule';
import { UptimeDowntime } from './model/UptimeDowntime';
import { PerformanceSnapshot } from './model/PerformanceSnapshot';
import { ShutdownByReason } from './model/ShutdownByReason';
import { AppUser } from '../user/model/AppUser';
import { ShutdownByReasonDetails } from './model/ShutdownByReasonDetails';
import { WellByStatusDetails } from './model/WellByStatusDetails';
import { UptimeDowntimeDetails } from './model/UptimeDowntimeDetails';

interface UserState {
  loading: boolean;
  jobScheduleLoading: boolean;
  ticketDetails: TicketDetails[] | null;
  ticketDetailsLoading: boolean;
  eventSchedule: EventSchedule[] | null;
  jobScheduleData: JobSchedule[] | null;
  wellByStatus: WellByStatus[] | null;
  wellByStatusDetails: WellByStatusDetails[] | null;
  wellByStatusLoading: boolean;
  shutdownByReason: ShutdownByReason[] | null;
  shutdownByReasonDetails: ShutdownByReasonDetails[] | null;
  shutdownByReasonLoading: boolean;
  uptimeDowntime: UptimeDowntime[] | null;
  uptimeDowntimeDetails: UptimeDowntimeDetails[] | null;
  uptimeDowntimeLoading: boolean;
  wellPerformanceTrend: WellPerformanceTrend[] | null;
  wellProductionTrend: WellProductionTrend[] | null;
  assetEventSchedule: AssetEventSchedule[] | null;
  assetListLoading: boolean;
  message: string | null;
  filterMyTicketDetails: string[];
  filterNewTicketDetails: TicketDetails[] | null;
  filteredJobScheduleData: JobSchedule[] | null;
  filterTicketsCount: number;
  newTicketsCount: number;
  jobsCount: number;
  PerformanceSnapshot: PerformanceSnapshot | null;
  performanceLoading: boolean;
  downloadedPDFData: Blob | null;
  downloadedCSVData: Text | null;
  error: string | null;
  wellByPerformanceDataLoading: boolean;
  wellByProductionDataLoading: boolean;
  urls: [];
  assestEventTotalCount: any;
  assetListErrorMessage: string | null;
}

const initialState: UserState = {
  loading: false,
  jobScheduleLoading: false,
  ticketDetails: null,
  ticketDetailsLoading: false,
  eventSchedule: null,
  jobScheduleData: null,
  wellByStatus: null,
  shutdownByReason: null,
  wellPerformanceTrend: null,
  wellProductionTrend: null,
  assetEventSchedule: null,
  assetListLoading: false,
  message: null,
  filterMyTicketDetails: [],
  filterNewTicketDetails: null,
  filteredJobScheduleData: null,
  uptimeDowntime: null,
  filterTicketsCount: 0,
  newTicketsCount: 0,
  jobsCount: 0,
  PerformanceSnapshot: null,
  performanceLoading: false,
  downloadedPDFData: null,
  downloadedCSVData: null,
  shutdownByReasonDetails: null,
  shutdownByReasonLoading: false,
  uptimeDowntimeDetails: null,
  uptimeDowntimeLoading: false,
  wellByStatusDetails: null,
  wellByStatusLoading: false,
  error: null,
  wellByPerformanceDataLoading: false,
  wellByProductionDataLoading: false,
  urls: [],
  assestEventTotalCount: 0,
  assetListErrorMessage: null,
};

const storedUserData = localStorage.getItem('loggedInUser');
const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;

// Define your async thunk for fetching a single user
export const fetchJobSchedule = createAsyncThunk('user/GetJobEventSchedule', async (userId: string) => {
  const response = await getJobSchedule(userId);
  return response;
});

export const fetchAssetListById = createAsyncThunk('user/GetAssetEventSchedule',
  async ({
    userId,
    groupName,
    startIndex,
    endIndex,
    searchQuery,
    cancelToken }: {
      userId: string,
      groupName: string,
      startIndex: number,
      endIndex: number,
      searchQuery: string,
      cancelToken?: any
    }) => {
    const response = await getAssetListById(userId, groupName, startIndex, endIndex, searchQuery, cancelToken);
    return response;
  });

export const fetchTicketsScheduleById = createAsyncThunk('user/GetTicketChart', async (userId: string) => {
  const response = await getTicketsById(userId);
  return response;
});

export const fetchGetPerformanceSnapshot = createAsyncThunk(
  'user/fetchGetPerformanceSnapshot',
  async ({ userId, groupName }: { userId: string; groupName: string }) => {
    const response = await getPerformanceSnapshot(userId, groupName);
    return response;
  },
);

export const fetchGetWellProductionTrends = createAsyncThunk(
  'user/fetchGetWellProductionTrends',
  async ({
    userId,
    startDate,
    endDate,
    groupName,
  }: {
    userId: string;
    startDate: string;
    endDate: string;
    groupName: string;
  }) => {
    const response = await getWellProductionTrends(userId, startDate, endDate, groupName);
    return response;
  },
);

export const fetchGetWellPerformanceTrends = createAsyncThunk(
  'user/fetchGetWellPerformanceTrends',
  async ({
    userId,
    filter,
    startDate,
    endDate,
    groupName,
    cancelToken
  }: {
    userId: string;
    filter: string;
    startDate: string;
    endDate: string;
    groupName: string;
    cancelToken: any;
  }) => {
    const response = await getWellPerformanceTrends(userId, filter, startDate, endDate, groupName, cancelToken);
    return response;
  },
);

export const fetchWellByStatusDetails = createAsyncThunk(
  'user/fetchWellByStatusDetails',
  async ({ userId, name, groupName }: { userId: string; name: string; groupName: string }) => {
    const response = await getPerformanceSnapshotByName(userId, name, groupName);
    return response;
  },
);

export const fetchUptimeDowntimeDetails = createAsyncThunk(
  'user/fetchUptimeDowntimeDetails',
  async ({ userId, name, groupName }: { userId: string; name: string; groupName: string }) => {
    const response = await getPerformanceSnapshotByName(userId, name, groupName);
    return response;
  },
);

export const fetchShutDownByReasonDetails = createAsyncThunk(
  'user/fetchShutDownByReasonDetails',
  async ({ userId, name, groupName }: { userId: string; name: string; groupName: string }) => {
    const response = await getPerformanceSnapshotByName(userId, name, groupName);
    return response;
  },
);

// export const fetchDashboardPDF = createAsyncThunk(
//   'user/downloadDashboardPDF',
//   async (pdf: { userId: string; widgetName: string, groupName: string }) => {
//     const response = await downloadDashboardPDF(pdf);
//     return response;
//   },
// );

export const fetchDownloadCSV = createAsyncThunk(
  'user/downloadDashboardCSV',
  async (csv: { userId: string; widgetName: string; groupName: string }) => {
    const response = await downloadDashboardCSV(csv);
    return response;
  },
);

export const fetchUrls = createAsyncThunk('user/UrlsLookup', async (groupId: string) => {
  const response = await getUrls(groupId);
  const data = response && response?.[0]?.group;
  return data;
});

export const filterMyTicketDetails = createSlice({
  name: 'filterTicketDetails',
  initialState: [] as string[], // Change the type to an array of strings
  reducers: {
    setFilterStatus: (state, action) => action.payload,
    setFilterTicketsCount: (state, action) => action.payload,
  },
});

export const { setFilterStatus } = filterMyTicketDetails.actions;

export const { setFilterTicketsCount } = filterMyTicketDetails.actions;

export const filterNewTicketDetails = createSlice({
  name: 'filterNewTicketDetails',
  initialState: { filterNewTicketDetails: null, newTicketsCount: 0 },
  reducers: {
    setFilterNewStatus: (state, action) => {
      state.filterNewTicketDetails = action.payload;
    },
    setNewTicketsCount: (state, action) => {
      state.newTicketsCount = action.payload;
    },
  },
});

export const { setFilterNewStatus, setNewTicketsCount } = filterNewTicketDetails.actions;

export const filterJobSchedule = createSlice({
  name: 'filterJobSchedule',
  initialState: { filterJobSchedule: null, newJobsCount: 0 },
  reducers: {
    setJobScheduleFilter: (state, action) => {
      state.filterJobSchedule = action.payload;
    },
    setJobsCount: (state, action) => {
      state.newJobsCount = action.payload;
    },
  },
});
export const { setJobScheduleFilter, setJobsCount } = filterJobSchedule.actions;

const userSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    clearAssetEventSchedule: (state) => {
      state.assetEventSchedule = null;
    },
    clearAssetListErrorMessage: (state) => {
      state.assetListErrorMessage = null;
    },
  },
  extraReducers: (builder) => {
    // Handle the fetchScheduleById fulfilled action
    builder.addCase(fetchTicketsScheduleById.pending, (state) => {
      state.loading = true;
      state.ticketDetailsLoading = true;
    });
    builder.addCase(fetchTicketsScheduleById.fulfilled, (state, action) => {
      state.ticketDetails = action.payload;
      const storedUserName = `${storedUser?.firstName} ${storedUser?.lastName}`
      const filteredTickets = action.payload.filter(
        (ticket: TicketDetails) => ticket.assignedTo?.replace(',', '').toLowerCase() === storedUserName.toLowerCase() && ['pending', 'solved', 'open', 'deleted'].includes(ticket.status?.toLowerCase()),
      );
      state.filterTicketsCount = filteredTickets?.length; // Update filterTicketsCount
      const newTickets = action.payload.filter((ticket: TicketDetails) => ticket.status?.toLowerCase() === 'new');
      state.newTicketsCount = newTickets?.length; // Update newTicketsCount
      state.filterNewTicketDetails = newTickets //update new ticket
      state.loading = false;
      state.ticketDetailsLoading = false;
    });
    builder.addCase(fetchTicketsScheduleById.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
      state.ticketDetailsLoading = false;
    });

    // Handle the fetchUsers fulfilled action
    builder.addCase(fetchJobSchedule.pending, (state) => {
      state.loading = true;
      state.jobScheduleLoading = true;
    });
    builder.addCase(fetchJobSchedule.fulfilled, (state, action) => {
      state.jobScheduleData = action.payload;
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      state.filteredJobScheduleData =
        action.payload?.filter((job: JobSchedule) => {
          if (!job.date) return false;
          const jobDate = new Date(job.date);
          jobDate.setHours(0, 0, 0, 0);
          return jobDate.getTime() === today.getTime(); // Compare job date with today's date
        }) || null;

      state.jobsCount = state.filteredJobScheduleData ? state.filteredJobScheduleData?.length : 0;
      state.loading = false;
      state.jobScheduleLoading = false;
    });

    builder.addCase(fetchJobSchedule.rejected, (state, action) => {
      state.loading = false;
      state.jobScheduleLoading = false;
      state.error = action.error.message || 'Something went wrong';
    });

    //Handle the fetchPerformanceSnapshot fullfilled action
    builder.addCase(fetchGetPerformanceSnapshot.pending, (state) => {
      state.loading = true;
      state.performanceLoading = true;
    });
    builder.addCase(fetchGetPerformanceSnapshot.fulfilled, (state, action) => {
      state.PerformanceSnapshot = action.payload;
      state.loading = false;
      state.performanceLoading = false;
    });
    builder.addCase(fetchGetPerformanceSnapshot.rejected, (state) => {
      state.loading = false;
      state.PerformanceSnapshot = null;
      state.performanceLoading = false;
    });

    builder.addCase(fetchUptimeDowntimeDetails.pending, (state) => {
      state.loading = true;
      state.uptimeDowntimeLoading = true;
    });
    builder.addCase(fetchUptimeDowntimeDetails.fulfilled, (state, action) => {
      state.uptimeDowntimeDetails = action.payload;
      state.loading = false;
      state.uptimeDowntimeLoading = false;
    });
    builder.addCase(fetchUptimeDowntimeDetails.rejected, (state) => {
      state.loading = false;
      state.uptimeDowntimeDetails = null;
      state.uptimeDowntimeLoading = false;
    });

    builder.addCase(fetchShutDownByReasonDetails.pending, (state) => {
      state.loading = true;
      state.shutdownByReasonLoading = true;
    });
    builder.addCase(fetchShutDownByReasonDetails.fulfilled, (state, action) => {
      state.shutdownByReasonDetails = action.payload;
      state.loading = false;
      state.shutdownByReasonLoading = false;
    });
    builder.addCase(fetchShutDownByReasonDetails.rejected, (state) => {
      state.loading = false;
      state.shutdownByReasonLoading = false;
      state.shutdownByReasonDetails = null;
    });

    builder.addCase(fetchWellByStatusDetails.pending, (state) => {
      state.loading = true;
      state.wellByStatusLoading = true;
    });
    builder.addCase(fetchWellByStatusDetails.fulfilled, (state, action) => {
      state.wellByStatusDetails = action.payload;
      state.loading = false;
      state.wellByStatusLoading = false;
    });
    builder.addCase(fetchWellByStatusDetails.rejected, (state) => {
      state.loading = false;
      state.wellByStatusLoading = false;
      state.wellByStatusDetails = null;
    });

    //
    // builder.addCase(fetchDashboardPDF.fulfilled, (state, action) => {
    //   state.downloadedPDFData = action.payload;
    // });

    //
    builder.addCase(fetchDownloadCSV.fulfilled, (state, action) => {
      state.downloadedCSVData = action.payload;
    });

    //Handle the fetchAssetListById fulfilled action
    builder.addCase(fetchAssetListById.pending, (state) => {
      state.loading = true;
      state.assetListLoading = true;
      state.assetListErrorMessage = null;
    });
    builder.addCase(fetchAssetListById.fulfilled, (state, action) => {
      state.assetEventSchedule = state.assetEventSchedule
        ? [...state.assetEventSchedule, ...action.payload.assetLists]
        : [...action.payload.assetLists];
      state.assestEventTotalCount = action.payload.totalRecords;
      state.loading = false;
      state.assetListLoading = false;
      state.assetListErrorMessage = null;
    });
    builder.addCase(fetchAssetListById.rejected, (state, action) => {
      state.message = action.payload as string;
      state.assetListErrorMessage = action.error.message || 'Something went wrong';
      if (action.meta.arg.searchQuery && action.error.message?.includes("404")) {
        state.assetEventSchedule = null;
      }
      state.loading = false;
      state.assetListLoading = false;
    });

    builder.addCase(setFilterStatus, (state, action) => {
      state.filterMyTicketDetails = action.payload;
    });
    builder.addCase(setFilterTicketsCount, (state, action) => {
      state.filterTicketsCount = action.payload;
    });
    // builder.addCase(setFilterNewStatus, (state, action) => {
    //   state.filterNewTicketDetails = action.payload;
    // });
    builder.addCase(setJobScheduleFilter, (state) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // set time to 00:00:00.000

      state.filteredJobScheduleData =
        state.jobScheduleData?.filter((job) => {
          const jobDate = new Date(job.date); // assuming job.date is a date string
          jobDate.setHours(0, 0, 0, 0); // set time to 00:00:00.000

          return job.status === 'JOB SCHEDULED' && jobDate >= today;
        }) || null;
    });

    // Well Performance APIData
    builder.addCase(fetchGetWellPerformanceTrends.pending, (state) => {
      state.wellByPerformanceDataLoading = true;
    });
    builder.addCase(fetchGetWellPerformanceTrends.fulfilled, (state, action) => {
      state.wellByPerformanceDataLoading = false;
      state.wellPerformanceTrend = action.payload;
    });
    builder.addCase(fetchGetWellPerformanceTrends.rejected, (state) => {
      state.wellByPerformanceDataLoading = false;
      state.wellPerformanceTrend = null;
    });

    // Well Production APIData
    builder.addCase(fetchGetWellProductionTrends.pending, (state) => {
      state.wellByProductionDataLoading = true;
    });
    builder.addCase(fetchGetWellProductionTrends.fulfilled, (state) => {
      state.wellByProductionDataLoading = false;
    });
    builder.addCase(fetchGetWellProductionTrends.rejected, (state) => {
      state.wellByProductionDataLoading = false;
    });

    // fetch tickets and Events Urls
    builder.addCase(fetchUrls.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUrls.fulfilled, (state, action) => {
      state.urls = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchUrls.rejected, (state, action) => {
      state.loading = false;
      state.urls = [];
      state.error = action.error.message || 'Something went wrong';
    });
  },
});

export default userSlice.reducer;
export const { clearAssetEventSchedule, clearAssetListErrorMessage } = userSlice.actions;
