import CardDateList from '../CardDateList/CardDateList';
import AnalysisInputOutput from '../../common/AnalysisInputOutput/AnalysisInputOutput';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { useParams } from 'react-router-dom';
import { fetchRodLiftAnalysisAsync } from '../AnalysisSlice';
import './RodLiftAnalysis.scss';
import '../../../styles/Analysis.scss';
import XDAnalysis from '../../common/XDAnalysis/XDAnalysis';
import AnalysisResultHeader from '../../common/AnalysisResultHeader/AnalysisResultHeader';
import Chart from '../../../images/card-viewer-chart-placeholder.png';
import ViewToggle1 from '../../../images/view toggle-1.png';
import ViewToggle2 from '../../../images/view toggle-2.png';
import ChevronDown from '../../../images/chevron-down.png';
import Eye from '../../../images/eye.png';
import FilterFunnel from '../../../images/filter-funnel-01.png';
import Timeline from '../../../images/timeline-placeholder.png';
import Calendar from '../../../images/timeline-calendar-placeholder.png';
import UIIconButtonToolbar from '../../common/UIIconButtonToolbar/UIIconButtonToolbar';
import ViewOptions from '../../common/ViewOptions/ViewOptions';
import CardTypeFilter from '../CardTypeFilter/CardTypeFilter';
import Tooltip from '../../common/tooltip/ToolTip';

const cardViewOptions = [
  {
    id: 1,
    label: 'Surface Card',
  },
  {
    id: 2,
    label: 'Downhole Card',
  },
  {
    id: 3,
    label: 'POC downhole Card',
  },
  {
    id: 4,
    label: 'Predicted Card',
  },
  {
    id: 5,
    label: 'Permissible Card',
  },
  {
    id: 6,
    label: 'Load limits',
  },
  {
    id: 7,
    label: 'Setpoints',
  },
];

interface UrlParams {
  assetId: string;
}

const RodLiftAnalysis = () => {
  const dispatch = useAppDispatch();
  const analysisStore = useAppSelector((state) => state.rodLiftAnalysisAnalysis);
  const cardDateStore = useAppSelector((state) => state.rodLiftAnalysis);

  const { assetId } = useParams<keyof UrlParams>() as UrlParams;

  const uiIconButtons = [
    {
      key: 0,
      name: 'view-toggle-1',
      active: true,
      image: ViewToggle1,
      tooltip: 'Card View',
    },
    {
      key: 1,
      name: 'view-toggle-2',
      active: false,
      image: ViewToggle2,
      tooltip: 'Timeline View',
    },
  ];

  const [uiIconButtonState, setUiIconButtonState] = useState(uiIconButtons);
  const [activeViewToggle, setActiveViewToggle] = useState('view-toggle-1');

  function buttonClicked(id: number) {
    const clickedButton = uiIconButtonState.find((button) => button.key === id);

    if (clickedButton) {
      setActiveViewToggle(clickedButton.name);

      // Toggle the 'active' property in the button data
      const updatedButtons = uiIconButtonState.map((button) => {
        if (button.key === id) {
          return { ...button, active: true };
        } else {
          return { ...button, active: false };
        }
      });

      setUiIconButtonState(updatedButtons);
    }
  }

  useEffect(() => {
    dispatch(fetchRodLiftAnalysisAsync({ assetId: '1', dateTime: '5' }));
  }, []);

  const distinctCardTypeNames = [
    ...new Set(
      cardDateStore.values
        .filter((value) => value.cardTypeName !== null && value.cardTypeName !== '')
        .map((value) => value.cardTypeName),
    ),
  ].sort();

  const createAnalysisResultHeaderDescription = () => {
    const selectedCardDate = cardDateStore.values.find((x) => x.isSelected);

    if (selectedCardDate) {
      return `${selectedCardDate.cardTypeName} ${selectedCardDate.date}`;
    }

    return '';
  };

  const onClickActionHandler = () => {
    alert('Button Clicked.');
  };

  const [isCardDataCollapsed, setIsCardDataCollapsed] = useState(false);
  const toggleBodyCollapse = () => {
    setIsCardDataCollapsed((prevIsCardDataCollapsed) => !prevIsCardDataCollapsed);
  };

  return (
    <div className="card-viewer">
      <div className="card-viewer-header">
        <span className="card-viewer-title">Card Viewer</span>
      </div>
      <div className="card-viewer-body">
        <div className="card-viewer-icons">
          <UIIconButtonToolbar uiIconButtons={uiIconButtonState} buttonClicked={buttonClicked} />
          <div className="card-viewer-button-container">
            <button data-testid="card-viewer-calendar" className="card-viewer-calendar" onClick={onClickActionHandler}>
              {activeViewToggle === 'view-toggle-2' && <img src={Calendar} />}
            </button>
            <Tooltip content="Card View Options" direction="top">
            <ViewOptions
              options={cardViewOptions}
              buttonContent={
                <div className="card-viewer-button">
                  <img src={Eye} />
                  <img src={ChevronDown} />
                </div>
              }
              onOptionChange={(id) => {
                id;
              }}
            />
            </Tooltip>
            <Tooltip content="Card Type Filter" direction="top">
            <CardTypeFilter
              buttonContent={
                <div className="card-viewer-button">
                  <img src={FilterFunnel} />
                  <img src={ChevronDown} />
                </div>
              }
              distinctCardTypeNames={distinctCardTypeNames}
            />
            </Tooltip>
          </div>
        </div>
        <div className="card-viewer-card">
          <div className="flex flex-row flex-grow flex-shrink">
            <img data-testid="card-viewer-chart" className="card-viewer-chart" src={Chart} />
            <div className="card-viewer-date-list">
              <CardDateList assetId={assetId} />
            </div>
          </div>
        </div>
        {activeViewToggle === 'view-toggle-2' && (
          <img data-testid="card-viewer-timeline" className="card-viewer-timeline" src={Timeline} />
        )}
        <div className="rodliftanalysis-container">
          <div className="rodliftanalysis-header">
            <AnalysisResultHeader
              title="Card Data"
              description={createAnalysisResultHeaderDescription()}
              isCollapsed={isCardDataCollapsed}
              toggleCollapse={toggleBodyCollapse}
            />
          </div>
          {!isCardDataCollapsed && (
            <div className="rodliftanalysis-body">
              <AnalysisInputOutput
                headerText="Input"
                values={analysisStore.input.map((x, index) => ({
                  key: index,
                  id: x.Id,
                  title: x.DisplayName,
                  value: x.DisplayValue,
                }))}
              />
              <AnalysisInputOutput
                headerText="Output"
                values={analysisStore.output.map((x, index) => ({
                  key: index,
                  id: x.Id,
                  title: x.DisplayName,
                  value: x.DisplayValue,
                }))}
              />
              <XDAnalysis headerText="Analysis Data" value={analysisStore.xdiagAnalysis.DisplayValue} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RodLiftAnalysis;
