import axios from "axios";
import { getFerrariAPIBaseURL, getWebSocketURL } from "../utilities/BaseURLUtility";

// const userToken = sessionStorage.getItem("access_token");
// const userToken = localStorage.getItem('jwtAccessToken')
const baseUrl = getFerrariAPIBaseURL();
const wellControlUrl = getWebSocketURL();


const http = axios.create({
    baseURL: baseUrl,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true
    }
});
http.interceptors.request.use(
    (config: any) => {
        const token = localStorage.getItem('jwtAccessToken');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);
http.interceptors.response.use(
    (response) => {

        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.clear()
            if (process.env.REACT_APP_LOGOUT_URL)
                window.location.href = process.env.REACT_APP_LOGOUT_URL;
        }
        // Return the error to be handled by the calling function
        return Promise.reject(error);
    },
);

const wellControlInstance = axios.create({
    baseURL: wellControlUrl,
    headers: {
        'Content-Type': 'application/json',
    },
});
wellControlInstance.interceptors.request.use(
    (config: any) => {
        const token = localStorage.getItem('xspocAccessToken');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);


export { http, wellControlInstance };