import React, { useState } from 'react';
import { Menu, Transition, Switch } from '@headlessui/react';
import './ViewOptions.css';
import '../../../styles/SwitchOptions.scss';

interface ViewOptionsProps {
  options: { id: number; label: string; isForm?: boolean }[];
  buttonContent: React.ReactNode;
  onOptionChange: (id: number, isSelected: boolean) => void; // Callback function
}

const ViewOptions: React.FC<ViewOptionsProps> = ({ options, buttonContent, onOptionChange }) => {
  const [enabledStates, setEnabledStates] = useState(options.map(() => false));

  return (
    <Menu as='div' className='toggle-dropdown-container'>
      <div>
        <Menu.Button>{buttonContent}</Menu.Button>
      </div>

      <Transition
        as={React.Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus-outline-none'>
          <div className='dropdown-label-container'>
            <p className='option-label'>View Options</p>
          </div>
          <div className='dropdown-switches'>
            {options.map((option, index) => (
              <div key={index} className='flex items-center'>
                <Switch.Group as='div' className='flex items-center'>
                  <Switch
                    checked={enabledStates[index]}
                    onChange={(value) => {
                      const newStates = [...enabledStates];
                      newStates[index] = value;
                      setEnabledStates(newStates);
                      onOptionChange(option.id, value);
                    }}
                    className={`custom-switch ${enabledStates[index] ? 'checked' : 'unchecked'}`}
                  >
                    <span
                      aria-hidden='true'
                      className={`custom-switch-handle ${enabledStates[index] ? 'checked' : ''}`}
                    />
                  </Switch>
                </Switch.Group>
                <div className='dropdown-list'>
                  <span data-testid='view-option-label' className='font-medium text-gray-900'>
                    {option.label}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ViewOptions;
