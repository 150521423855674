import React from 'react';
import WIP from '../../../images/wip1.svg';

interface TabsProps {
  value?: string | undefined;
}
const MeasurementDisplay: React.FC<TabsProps> = ({ value }) => {
  console.log('userId', value);
  return (
    <>
      <div className='flex flex-row flex-grow profile-block w-100 py-5'>
        <div className='card profile-card m-0'>
          <div className='header'>
            <p className='title'>Measurement display</p>
            <p className='normal-text'>Create an measurement display details here.</p>
          </div>
          <div className='body'>
            <center>
              <img src={WIP} alt='work in progress' />
              <p className='mt-6'>Under construction / Maintanance</p>
            </center>
          </div>
        </div>
      </div>
    </>
  );
};

export default MeasurementDisplay;
