import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AlarmsState {
  showAlarms: boolean;
  showWeather: boolean;
  filterTerm: string;  // unified filter for Asset Name and Customer Name
}

const initialState: AlarmsState = {
  showAlarms: false,
  showWeather: false,
  filterTerm: ''
};

export const alarmSlice = createSlice({
  name: 'alarms',
  initialState,
  reducers: {
    toggleShowAlarms: (state) => {
      state.showAlarms = !state.showAlarms;
    },
    toggleShowWeather: (state) => {
      state.showWeather = !state.showWeather;
    },
    setFilterTerm: (state, action: PayloadAction<string>) => {
      state.filterTerm = action.payload;
    }
  },
});

export const { toggleShowAlarms, toggleShowWeather, setFilterTerm } = alarmSlice.actions;
export default alarmSlice.reducer;
