// Modal.tsx
import React from 'react';
import Modal, { Styles } from 'react-modal'; // Import Styles from 'react-modal'

Modal.setAppElement('#root');

interface CustomModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  children: React.ReactNode; // Add children property to the interface
  width?: string;
  classname?: string;
  openModalForPDF?: boolean;
}

const CustomModal: React.FC<CustomModalProps> = ({ isOpen, onRequestClose, children, width, classname, openModalForPDF }) => {
  const customStyles: Styles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: openModalForPDF ? -9999999999 : 9999999999,
    },
    content: {
      /* top: '20%',
      left: '50%', */
      position: 'absolute',
      inset: '20% 40px 40px 50%',
      transform: 'translate(-50%, -12%)',
      width: width && width,
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      className={`${classname}`}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
