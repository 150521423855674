import React, { useEffect, useState } from 'react';
import { Setpoint } from '../model/ApiResponse';
// import { useAppDispatch } from '../../../../../hooks/storeHooks';
// import { setChangedBackupValues } from '../SetPointsSlice';
import chevrondown from '../../../../../images/chevron-down.svg';
import { setChangedBackupValues } from '../SetPointsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks';

interface BackUpLookupValue {
  text: string;
  value: number;
}

interface SetpointProps {
  wellId: string;
  setPointItem: Setpoint;
}

const BackupValues: React.FC<SetpointProps> = ({ setPointItem }) => {
  const dispatch = useAppDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [selectedOption, setSelectedOption] = useState<BackUpLookupValue | null>(null)
  const [inputValue, setInputValue] = useState<string | number>("")
  const setPointData = useAppSelector((state) => state?.setPoint)
  useEffect(() => {
    let matchingOne = null
    if (setPointItem.backUpLookUpValues && setPointItem.backUpLookUpValues?.length > 0) {
      const matchingOption = setPointItem.backUpLookUpValues.find(
        (option) => {
          return option.value === parseInt(setPointItem.backupValue)
        }
      );
      matchingOne = matchingOption || setPointItem.backUpLookUpValues[0];
    }
    setSelectedOption(matchingOne)
    setInputValue(setPointItem.backupValue)
  }, [setPointItem])


  useEffect(() => {
    if (setPointData?.deviceAction === 'Read') {
      setInputValue(setPointItem.backupValue)
      let matchingOne = null
      if (setPointItem.backUpLookUpValues && setPointItem.backUpLookUpValues?.length > 0) {
        const matchingOption = setPointItem.backUpLookUpValues.find(
          (option) => {
            return option.value === parseInt(setPointItem.backupValue)
          }
        );
        matchingOne = matchingOption || setPointItem.backUpLookUpValues[0];
      }
      setSelectedOption(matchingOne)
    }
  }, [setPointData?.readDeviceLoading === true])
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  const handleInputBlur = () => {
    const setpointValues = {
      [setPointItem.parameter]: inputValue,
    };
    dispatch(setChangedBackupValues(setpointValues))
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (option: BackUpLookupValue, setPointItem: Setpoint) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
    const setpointValues = {
      [setPointItem.parameter]: option.value,
    };
    dispatch(setChangedBackupValues(setpointValues))
  };

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (event.target instanceof HTMLElement && event.target.closest('.select-name')) return;
      setIsDropdownOpen(false);
    };
    document.body.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.body.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  return (
    <div className='select-name'>
      {setPointItem.backUpLookUpValues?.length ? (
        <div className={`custom-dropdown ${isDropdownOpen ? 'open' : ''}`} onClick={toggleDropdown}>
          <div className='selected-option flex justify-between'>
            {selectedOption?.text}
            <img src={chevrondown} alt='chevron-down' className={`${isDropdownOpen ? 'rotate-180' : ''}`} />
          </div>
          {isDropdownOpen && (
            <ul className='dropdown-list'>
              {setPointItem?.backUpLookUpValues?.map((option, index) => (
                <li key={index} onClick={() => handleOptionClick(option, setPointItem)}>
                  {option.text}
                </li>
              ))}
            </ul>
          )}
        </div>
      ) : (
        <input
          type='text'
          className='backup-input pl-2'
          value={inputValue as string}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
        />
      )}
    </div>
  );
};

export default BackupValues;
