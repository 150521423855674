import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import AssetMap from '../../../dashboard/components/asset-location/AssetMap';
import AssetTabs from '../../../dashboard/components/asset-location/AssetTabs';
import Trends from '../trends/Trends';
import Events from '../events/Events';
import { wellOptions } from '../../data/WellOptions';
import { fetchAssetDetail } from '../details-table/AssetDetailSlice';
import { AssetDetailsResponseProps } from '../details-table/AssetDetailProps';
import { AppUser } from '../../../user/model/AppUser';
import AlarmSubscriptions from '../details-table/AssetDetailComponents/AlarmSubscriptions';
import Attributes from '../details-table/AssetDetailComponents/Attributes';
import ControlStatus from '../details-table/AssetDetailComponents/ControlsStatus';
import plus from '../../../../images/plus.png';
import minus from '../../../../images/minus.png';
import './details.scss';
import Loader from '../../../common/page-loader/ComponentLoader';
import { updateAssetControlScanStatus } from '../../../asset/AssetControlSlice';
import { updateAssetId } from '../../../navigation/AssetGroupsSlice';

interface AssetProps {
  assetName: string | undefined;
  assetId: string | undefined;
  industryApplicationId: number | undefined;
}

function Details() {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state?.assetDetail);
  const [assetDetails, setAssetDetails] = useState<AssetDetailsResponseProps | null>(null);
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const selectedAsset = useAppSelector((state) => state.assetGroups);
  const assetControlData = useAppSelector((state) => state?.assetControl)

  const [expand, setExpand] = useState(true);
  const [currentAsset, setCurrentState] = useState<AssetProps>({
    assetName: '',
    assetId: '',
    industryApplicationId: 0,
  });

  const tabs = [
    { label: 'Trends', component: Trends },
    { label: 'Map', component: AssetMap },
    { label: 'Events', component: Events },
  ];

  //to reload the data, when the scan is completed
  useEffect(() => {
    if (assetControlData?.assetControlScanStatus) {
      const wellName = selectedAsset?.selectedAssetName ?? '';
      if (wellName === '' || storedUser.id === '') return;
      dispatch(fetchAssetDetail({ userId: storedUser?.id, wellName: wellName }))

      dispatch(updateAssetControlScanStatus(false))
    }
  }, [assetControlData?.assetControlScanStatus])

  useEffect(() => {
    if (selectedAsset.selectedAssetName) {
      const data = {
        assetId: selectedAsset.selectedAssetId,
        assetName: selectedAsset?.selectedAssetName,
        industryApplicationId: 0
      }

      setCurrentState(data);
    }
  }, [selectedAsset]);


  useEffect(() => {
    const wellName = selectedAsset?.selectedAssetName ?? '';
    if (wellName === '' || storedUser?.id === '') return;
    dispatch(fetchAssetDetail({ userId: storedUser?.id, wellName: wellName }))
      .unwrap()
      .then((response: AssetDetailsResponseProps) => {
        sessionStorage.setItem('assetCreationDate', response.assetDetails?.creationDateTime ?? '');
        setAssetDetails(response);
        const selectedWellNameString = sessionStorage.getItem('selectedWellName');
        if (selectedWellNameString && JSON.parse(selectedWellNameString)?.assetId !== response?.assetDetails?.assetId) {
          const asset = { ...JSON.parse(selectedWellNameString), assetId: response?.assetDetails?.assetId }
          dispatch(updateAssetId(asset))
          sessionStorage.setItem('selectedWellName', JSON.stringify(asset))
        }
      })
      .catch((error) => {
        console.error('Failed to fetch AssetDetails  details:', error);
      });

  }, [selectedAsset?.selectedAssetName]);

  return (
    <>
      {/* {loading && <PageLoader />} */}
      <div className='details-block'>
        <div className='card details-card one'>
          <div className='asset-container' onClick={() => setExpand(!expand)}>
            <button className='asset-expand-btn'><img src={expand ? minus : plus} alt='expand' /></button><h1>{currentAsset?.assetName ? currentAsset?.assetName : wellOptions[0]?.wellName}</h1>
          </div>

          {
            expand &&
            <>
              <hr />
              <div className='detail-table-container'>
                {loading ? (
                  <div className='flex items-center justify-center w-100'>
                    <Loader />
                  </div>
                ) : (
                  <>
                    <Attributes assetDetails={assetDetails?.assetDetails} />
                    <ControlStatus assetDetails={assetDetails?.assetDetails} />
                    <AlarmSubscriptions alarmSubscriptions={assetDetails?.alarmSubscriptions} />
                  </>
                )
                }
              </div>
            </>
          }
        </div>

        <div className='card details-card two'>
          <div className='asset-container'>
            <h1>Asset telemetry</h1>
          </div>
          <hr />
          <div className='second-header'>
            <AssetTabs tabs={tabs} assetDetails={true} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Details;
