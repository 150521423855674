import { useEffect, useState } from 'react';
import './Dropdown.scss';
import { useLocation } from 'react-router-dom';

interface DropdownProps {
  assets: Array<string> | undefined | null;
  showEyeIcon: boolean | undefined | null;
  defaultOption?: string | undefined | null;
  onChange?: (asset: string) => void;
}

const Dropdown: React.FC<DropdownProps> = (props: DropdownProps) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState(props.defaultOption || 'Choose one');

  const eyeSpanClass = props.showEyeIcon ? 'fas fa-eye' : '';
  const textSpanClass = props.showEyeIcon ? 'fa-text' : '';

  useEffect(() => {
    setIsSelected(props.defaultOption || 'Choose one');
  }, [props.assets, location.pathname]);

  const handleAssetChange = (asset: string) => {
    setIsSelected(asset);
    setIsActive(!isActive);
    if (props.onChange) {
      props.onChange(asset);
    }
  };

  return (
    <div className='dropdown'>
      <div
        onClick={() => {
          setIsActive(!isActive);
        }}
        className='dropdown-btn'
      >
        <span className={eyeSpanClass}>
          <span data-testid={`dropdown-${selected}`} className={textSpanClass}>
            {selected}
          </span>
        </span>
        <span className={isActive ? 'fas fa-caret-up' : 'fas fa-caret-down'} />
      </div>
      <div className='dropdown-content' style={{ display: isActive ? 'block' : 'none' }}>
        {props.assets?.map((asset, index) => {
          return (
            <div data-testid={asset} key={index} onClick={() => handleAssetChange(asset)} className='item'>
              {asset}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Dropdown;
